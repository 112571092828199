<template>
  <div class="fill-height pt-16">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card 
            flat
            color="#f9f9f9">
            <v-card-text 
              class="py-0">
              <v-row>
                <v-col
                  v-for="(item, index) in menu" 
                  :key="index"
                  cols="6">
                  <v-btn
                    small
                    rounded
                    block
                    elevation="0"
                    @click="selected.menu = item.value"
                    class="body-2 text-capitalize"
                    :color="selected.menu === item.value ? '#ff64ad' : '#00000014'"
                    :class="selected.menu === item.value ? 'white--text font-weight-bold' : 'grey--text'">
                    {{ item.title }}
                  </v-btn>
    
                  <div v-if="selected.menu === item.value"
                    class="text-center">
                    <v-icon
                      color="#ff64ad"
                      size="10">
                      mdi-circle
                    </v-icon>
                  </div>
                </v-col>
              </v-row>

              <div v-if="selected.menu === 1">
                <v-text-field
                  v-model="search"
                  v-uppercase
                  @click:clear="search = ''; fetch();"
                  v-on:keyup.enter="fetch()"
                  filled
                  rounded
                  dense 
                  single-line
                  hide-details
                  color="#ff64ad"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Cari . . ."
                  clearable
                  style="border-radius: 10px;">
                </v-text-field>

                <v-btn
                  @click="sheet.form = true"
                  block
                  elevation="0"
                  color="#ff64ad"
                  height="40"
                  class="text-capitalize white--text d-flex align-center mt-4"
                  style="border-radius: 10px; 
                        box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;">
                  <v-icon size="18">mdi-file-document-edit-outline</v-icon>
                  
                  
                  <span
                    class="ml-1">
                    Tambah Buku Pokok Pemakaman
                  </span>
                </v-btn>
              </div>

              <div v-if="selected.menu === 2">
                <v-text-field
                  v-model="search"
                  v-uppercase
                  @click:clear="search = ''; fetchMakam();"
                  v-on:keyup.enter="fetchMakam()"
                  filled
                  rounded
                  dense 
                  single-line
                  hide-details
                  color="#ff64ad"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Cari . . ."
                  clearable
                  style="border-radius: 10px;">
                </v-text-field>

                <v-btn
                  @click="sheet.form_makam = true"
                  block
                  elevation="0"
                  color="#ff64ad"
                  height="40"
                  class="text-capitalize white--text d-flex align-center mt-4"
                  style="border-radius: 10px; 
                        box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;">
                  <v-icon size="18">mdi-file-document-edit-outline</v-icon>
                  
                  
                  <span
                    class="ml-1">
                    Tambah Tempat Pemakaman
                  </span>
                </v-btn>
              </div>
            </v-card-text>

            <v-card-text>
              <v-list v-if="selected.menu === 1"
                flat 
                three-line
                color="transparent">
                <v-list-item-group>
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 7 : list"
                    :key="index"
                    :loading="process.load"
                    type="list-item-three-line">
                    <template>
                      <div>
                        <v-list-item v-if="!process.load && list.length > 0"
                          :class="index < list.length - 1 ? 'mb-4' : ''"
                          @click="sheet.detail = true; bpp_detail = item;"
                          class="pl-3"
                          style="box-shadow: rgba(0, 0, 0, 0.01) 0px 3px 5px; 
                                border-radius: 5px;
                                border-left: 4px solid #ff64ad;
                                background: #fff;">
                          <v-list-item-content>
                            <v-list-item-title
                              class="body-2 font-weight-bold mb-1">
                              #{{ item.bpp_nik }}
                            </v-list-item-title>

                            <v-list-item-subtitle
                              class="body-2 black--text line-text-second">
                              {{ item.bpp_nama }}
                            </v-list-item-subtitle>

                            <div
                              class="caption font-weight-light">
                              {{ item.bpp_alamat }}, RT{{ item.bpp_rt }} / RW{{ item.bpp_rw }}, Kel. {{ item.bpp_desa_name }},  Kec. {{ item.bpp_kecamatan_name }}
                            </div>

                            <v-divider
                              class="mt-1 mb-2" />

                            <div
                              class="d-flex align-center line-text-second"
                              style="font-size: 10px; opacity: 40%;">
                              <div>
                                Meninggal di {{ item.bpp_tempat_meninggal }}
                              </div>

                              <v-spacer />

                              <div>
                                Tanggal {{ item.bpp_tanggal_meninggal | date }}
                              </div>
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-list-item-group>
              </v-list>

              <v-list v-if="selected.menu === 2"
                flat 
                two-line
                color="transparent">
                <v-list-item-group>
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 7 : list"
                    :key="index"
                    :loading="process.load"
                    type="list-item-three-line">
                    <template>
                      <div>
                        <v-list-item v-if="!process.load && list.length > 0"
                          @click="sheet.form_makam = true; reset_makam(item);"
                          :class="index < list.length - 1 ? 'mb-4' : ''"
                          class="pl-3 pr-2"
                          style="box-shadow: rgba(0, 0, 0, 0.01) 0px 3px 5px; 
                                border-radius: 5px;
                                border-left: 4px solid #ff64ad;
                                background: #fff;">
                          <v-list-item-content>
                            <v-list-item-title
                              class="body-2 font-weight-bold mb-1 line-text-second">
                              {{ item.bpptp_nama }}
                            </v-list-item-title>

                            <v-list-item-subtitle
                              class="caption font-weight-light black--text">
                              <span
                                class="one-line">
                                {{ item.bpptp_alamat }}
                              </span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-list-item-group>
              </v-list>

              <Empty v-if="!process.load && pagination.total_page < 1"
                margin="my-16"
                size="125"
                message="Buku Pokok Pemakaman" />
            </v-card-text>
          </v-card>

          <v-bottom-sheet
            v-model="sheet.form"
            hide-overlay
            persistent
            fullscreen
            scrollable>
            <v-card
              flat
              color="#f9f9f9"
              style="border-radius: 0px;">
              <v-card-title 
                class="cursor-pointer pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 10;
                      background: #ff64ad;">
                <v-icon 
                  @click="sheet.form = false"
                  color="white">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  class="body-1 font-weight-bold text-capitalize white--text ml-1">
                  Tambah Buku Pokok Pemakaman
                </span>
              </v-card-title>

              <v-card-text
                class="pt-16 mt-4">
                <ValidationObserver 
                  ref="form">
                  <v-form>
                    <ValidationProvider 
                      name="nik" 
                      rules="required|min:16|max:16" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        NIK Jenazah
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        type="number"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        min="0"
                        placeholder="Contoh. 33100062XXXXXXX"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        :counter="16"
                        v-model="form.nik"
                        v-uppercase
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="nama" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Nama Jenazah
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. Murtoyo"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form.nama"
                        v-uppercase
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="tempat_lahir" 
                      rules="required" 
                      v-slot="{ valid }">
                      <div class="body-2 mb-2">
                        Tempat Lahir
                        <span>
                          (bisa dikosongi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. Klaten"
                        :append-icon="form.tempat_lahir && valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form.tempat_lahir"
                        v-uppercase
                        :success="form.tempat_lahir && valid"
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider>

                    <div>
                      <div class="body-2 mb-2">
                        Tanggal Lahir 
                        <span>
                          (bisa dikosongi)
                        </span>
                      </div>

                      <v-menu
                        ref="birth"
                        min-width="290px"
                        max-width="290px"
                        transition="scale-transition"
                        offset-y
                        :nudge-right="40"
                        :close-on-content-click="false"
                        v-model="picker.birth">
                        <template v-slot:activator="{ on }">
                          <ValidationProvider 
                            name="tanggal_lahir" 
                            v-slot="{ valid }">
                            <v-text-field
                              v-on:keyup.enter="save"
                              readonly
                              filled
                              rounded
                              dense
                              color="#ff64ad"
                              placeholder="Contoh. 1978-02-03"
                              :append-icon="form.tanggal_lahir && valid ? 'mdi-check-circle-outline' : ''"
                              v-model="form.tanggal_lahir"
                              v-uppercase
                              :success="form.tanggal_lahir && valid"
                              style="border-radius: 10px;"
                              clearable
                              @click:clear="form.tanggal_lahir = ''"
                              v-on="on">
                            </v-text-field>
                          </ValidationProvider>
                        </template>
                        
                        <v-date-picker 
                          color="#ff64ad"
                          v-model="form.tanggal_lahir"
                          @input="picker.birth = false">
                        </v-date-picker>
                      </v-menu>
                    </div>

                    <ValidationProvider 
                      name="tempat_meninggal" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Tempat Meninggal
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. Klaten"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form.tempat_meninggal"
                        v-uppercase
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider>

                    <div>
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Tanggal Meninggal
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-menu
                        ref="dead"
                        min-width="290px"
                        max-width="290px"
                        transition="scale-transition"
                        offset-y
                        :nudge-right="40"
                        :close-on-content-click="false"
                        v-model="picker.dead">
                        <template v-slot:activator="{ on }">
                          <ValidationProvider 
                            name="tanggal_meninggal" 
                            rules="required" 
                            v-slot="{ errors, valid }">
                            <v-text-field
                              v-on:keyup.enter="save"
                              readonly
                              filled
                              rounded
                              dense
                              color="#ff64ad"
                              placeholder="Contoh. 1978-02-03"
                              :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                              v-model="form.tanggal_meninggal"
                              v-uppercase
                              :success="valid"
                              :error-messages="errors"
                              required
                              style="border-radius: 10px;"
                              clearable
                              @click:clear="form.tanggal_meninggal = ''"
                              v-on="on">
                            </v-text-field>
                          </ValidationProvider>
                        </template>
                        
                        <v-date-picker 
                          color="#ff64ad"
                          v-model="form.tanggal_meninggal"
                          @input="picker.dead = false">
                        </v-date-picker>
                      </v-menu>
                    </div>

                    <ValidationProvider 
                      name="alamat" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Dukuh / Dusun
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. Kemiri"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form.alamat"
                        v-uppercase
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider>

                    <v-row>
                      <v-col
                        cols="6"
                        class="py-0">
                        <ValidationProvider 
                          name="rt" 
                          rules="required" 
                          v-slot="{ errors, valid }">
                          <div class="body-2 mb-2">
                            <span class="red--text">*</span>
                            RT
                            <span class="error--text">
                              (wajib diisi)
                            </span>
                          </div>
                          
                          <v-text-field
                            v-on:keyup.enter="save"
                            type="number"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            min="0"
                            filled
                            rounded
                            dense
                            color="#ff64ad"
                            placeholder="Contoh. 22"
                            :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                            v-model="form.rt"
                            v-uppercase
                            :success="valid"
                            :error-messages="errors"
                            required
                            style="border-radius: 10px;">
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>

                      <v-col
                        cols="6"
                        class="py-0">
                        <ValidationProvider 
                          name="rw" 
                          rules="required" 
                          v-slot="{ errors, valid }">
                          <div class="body-2 mb-2">
                            <span class="red--text">*</span>
                            RW
                            <span class="error--text">
                              (wajib diisi)
                            </span>
                          </div>
                          
                          <v-text-field
                            v-on:keyup.enter="save"
                            type="number"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            min="0"
                            filled
                            rounded
                            dense
                            color="#ff64ad"
                            placeholder="Contoh. 10"
                            :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                            v-model="form.rw"
                            v-uppercase
                            :success="valid"
                            :error-messages="errors"
                            required
                            style="border-radius: 10px;">
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>

                    <!-- <ValidationProvider 
                      name="kecamatan_code" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Kecamatan
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-autocomplete
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. Trucuk"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        :search-input.sync="autocomplete.subdistrict"
                        :loading="process.subdistrict"
                        
                        :items="config.subdistrict"
                        item-value="kecamatan_code"
                        item-text="kecamatan_name"
                        item-color="#ff64ad"

                        v-model="form.kecamatan_code"
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-autocomplete>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="desa_code" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Kelurahan
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-autocomplete
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. Kradenan"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        :search-input.sync="autocomplete.village"
                        :loading="process.village"
                        
                        :items="config.village"
                        item-value="desa_code"
                        item-text="desa_name"
                        item-color="#ff64ad"

                        v-model="form.desa_code"
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-autocomplete>
                    </ValidationProvider> -->

                    <ValidationProvider 
                      name="kecamatan_name" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Kecamatan
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        :disabled="user.role_id > 0"
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. Trucuk"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form.kecamatan_name"
                        v-uppercase
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="desa_name" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Kelurahan
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        :disabled="user.role_id > 0"
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. Kradenan"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form.desa_name"
                        v-uppercase
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider>

                    <div
                      class="body-1 font-weight-bold black--text">
                      Tanggal / Tempat Pemakaman
                    </div>

                    <v-divider
                      class="mt-2 mb-4"
                      style="border-top: 5px solid #eaeaea; border-radius: 50px;" />

                    <div>
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Tanggal Pemakaman
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-menu
                        ref="rip"
                        min-width="290px"
                        max-width="290px"
                        transition="scale-transition"
                        offset-y
                        :nudge-right="40"
                        :close-on-content-click="false"
                        v-model="picker.rip">
                        <template v-slot:activator="{ on }">
                          <ValidationProvider 
                            name="tanggal_pemakaman" 
                            rules="required" 
                            v-slot="{ errors, valid }">
                            <v-text-field
                              v-on:keyup.enter="save"
                              readonly
                              filled
                              rounded
                              dense
                              color="#ff64ad"
                              placeholder="Contoh. 1978-02-03"
                              :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                              v-model="form.tanggal_pemakaman"
                              v-uppercase
                              :success="valid"
                              :error-messages="errors"
                              required
                              style="border-radius: 10px;"
                              clearable
                              @click:clear="form.tanggal_pemakaman = ''"
                              v-on="on">
                            </v-text-field>
                          </ValidationProvider>
                        </template>
                        
                        <v-date-picker 
                          color="#ff64ad"
                          v-model="form.tanggal_pemakaman"
                          @input="picker.rip = false">
                        </v-date-picker>
                      </v-menu>
                    </div>

                    <ValidationProvider 
                      name="makam_kecamatan_code" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Kecamatan
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-autocomplete
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. Trucuk"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        :search-input.sync="autocomplete.subdistrict"
                        :loading="process.subdistrict"
                        
                        :items="config.subdistrict"
                        item-value="kecamatan_code"
                        item-text="kecamatan_name"
                        item-color="#ff64ad"

                        v-model="form.makam_kecamatan_code"
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-autocomplete>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="makam_desa_code" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Kelurahan
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-autocomplete
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. Kradenan"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        :search-input.sync="autocomplete.village"
                        :loading="process.village"
                        
                        :items="config.village"
                        item-value="desa_code"
                        item-text="desa_name"
                        item-color="#ff64ad"

                        v-model="form.makam_desa_code"
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-autocomplete>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="makam_kode" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Nama Tempat Pemakaman
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-autocomplete
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. Kradenan"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        :search-input.sync="autocomplete.makam"
                        :loading="process.makam"
                        
                        :items="config.makam"
                        item-value="bpptp_id"
                        item-text="bpptp_nama"
                        item-color="#ff64ad"

                        v-model="form.makam_kode"
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-autocomplete>
                    </ValidationProvider>

                    <div
                      class="body-2 font-italic line-text-second mb-4">
                      <span
                        style="opacity: 60%;">
                        Jika tempat pemakaman tidak ada, silahkan dengan
                      </span>

                      <strong
                        @click="sheet.form_makam = true; reset_makam();"
                        class="cursor-pointer blue--text">
                        klik disini.
                      </strong>
                    </div>

                    <!-- <ValidationProvider 
                      name="makam_kecamatan_name" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Kecamatan Makam
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        readonly
                        disabled
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. Trucuk"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form.makam_kecamatan_name"
                        v-uppercase
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider> -->

                    <!-- <ValidationProvider 
                      name="makam_desa_name" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Kelurahan Makam
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        readonly
                        disabled
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. Kradenan"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form.makam_desa_name"
                        v-uppercase
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider> -->

                    <div
                      class="body-1 font-weight-bold black--text">
                      Penanggung Jawab
                    </div>

                    <v-divider
                      class="mt-2 mb-4"
                      style="border-top: 5px solid #eaeaea; border-radius: 50px;" />

                    <ValidationProvider 
                      name="keluarga_telp_nama" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Nama Keluarga
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. Murtoyo"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form.keluarga_telp_nama"
                        v-uppercase
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="keluarga_telp_no" 
                      rules="min:8|max:13"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        Nomor Telepon Keluarga
                        <span>
                          (bisa dikosongi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        type="number"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        min="0"
                        placeholder="Contoh. 081322XXXXXX"
                        :append-icon="form.keluarga_telp_no !== '' && valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form.keluarga_telp_no"
                        v-uppercase
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider>

                    <div>
                      <div class="body-2 mb-2">
                        Keterangan
                        <span>
                          (bisa dikosongi)
                        </span>
                      </div>

                      <v-textarea
                        v-on:keyup.enter="save"
                        filled
                        rounded
                        dense
                        rows="3"
                        color="#ff64ad"
                        placeholder="Contoh. Perubahan nama dan foto"
                        :append-icon="form.note.length < 1 ? '' : 'mdi-check-circle-outline'"

                        v-model="form.note"
                        v-uppercase
                        :success="form.note.length > 0"
                        style="border-radius: 10px;">
                      </v-textarea>
                    </div>
                  </v-form>
                </ValidationObserver>

                <div v-if="message.error.length > 0"
                  class="text-center my-3">
                  <v-alert
                    type="error" 
                    text 
                    dense 
                    prominent 
                    v-show="message.error.length > 0">
                    {{ message.error }}
                  </v-alert>
                </div>

                <v-row>
                  <v-col 
                    cols="6" 
                    class="py-0">
                    <v-btn
                      :disabled="process.form"
                      :loading="process.form"
                      v-on:keyup.enter="save"
                      @click="save"
                      block
                      color="green"
                      class="text-capitalize white--text pl-1 pr-2"
                      style="border-radius: 10px; box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;">
                      <v-avatar 
                        size="25"
                        style="position: absolute; left: 2px;">
                        <v-icon small>mdi-check-bold</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Simpan
                      </span>
                    </v-btn>
                  </v-col>

                  <v-col 
                    cols="6" 
                    class="py-0">
                    <v-btn 
                      @click="sheet.form = false"
                      :disabled="process.form"
                      block
                      color="red"
                      class="text-capitalize white--text pl-1 pr-2"
                      style="border-radius: 10px; box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;">
                      <v-avatar 
                        size="25"
                        style="position: absolute; left: 2px;">
                        <v-icon small>mdi-close-thick</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Batal
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-bottom-sheet>

          <v-bottom-sheet
            v-model="sheet.detail"
            hide-overlay
            persistent
            fullscreen
            scrollable>
            <v-card v-if="Object.keys(bpp_detail).length > 0"
              flat
              color="#f9f9f9"
              style="border-radius: 0px;">
              <v-card-title 
                class="cursor-pointer pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 10;
                      background: #ff64ad;">
                <v-icon 
                  @click="sheet.detail = false"
                  color="white">
                  mdi-arrow-left-thick
                </v-icon>

                <span 
                  class="body-1 font-weight-bold text-capitalize white--text ml-1">
                  BPP Detail dari {{ bpp_detail.bpp_nama }}
                </span>
              </v-card-title>

              <v-card-text 
                class="body-2 font-weight-light text-first line-text-second mt-16 pt-2">
                <div
                  class="font-weight-bold black--text">
                  Nomor Registrasi:
                </div>
                
                <div
                  class="mb-2">
                  {{ bpp_detail.bpp_no_reg }}
                </div>

                <div
                  class="font-weight-bold black--text">
                  NIK Jenazah:
                </div>
                
                <div
                  class="mb-2">
                  {{ bpp_detail.bpp_nik }}
                </div>

                <div
                  class="font-weight-bold black--text">
                  Nama Jenazah:
                </div>
                
                <div
                  class="mb-2">
                  {{ bpp_detail.bpp_nama }}
                </div>

                <div
                  class="font-weight-bold black--text">
                  Tempat / Tanggal Lahir:
                </div>
                
                <div
                  class="mb-2">
                  {{ bpp_detail.bpp_tempat_lahir === '' ? '-' : bpp_detail.bpp_tempat_lahir }} /

                  <span v-if="bpp_detail.bpp_tanggal_lahir === ''">
                    -
                  </span>

                  <span v-else>
                    {{ bpp_detail.bpp_tanggal_lahir | date }}
                  </span>
                </div>

                <div
                  class="font-weight-bold black--text">
                  Alamat Jenazah:
                </div>
                
                <div
                  class="mb-2">
                  {{ bpp_detail.bpp_alamat }}, RT{{ bpp_detail.bpp_rt }} / RW{{ bpp_detail.bpp_rw }}
                </div>

                <div
                  class="font-weight-bold black--text">
                  Kecamatan:
                </div>
                
                <div
                  class="mb-2">
                  {{ bpp_detail.bpp_kecamatan_name }}
                </div>

                <div
                  class="font-weight-bold black--text">
                  Kelurahan:
                </div>
                
                <div
                  class="mb-2">
                  {{ bpp_detail.bpp_desa_name }}
                </div>

                <v-divider
                  class="my-4"
                  style="border-top: 5px solid #eaeaea; border-radius: 50px;" />

                <div
                  class="font-weight-bold black--text">
                  Tempat / Tanggal Meninggal:
                </div>
                
                <div
                  class="mb-2">
                  {{ bpp_detail.bpp_tempat_meninggal }} / {{ bpp_detail.bpp_tanggal_meninggal | date }}
                </div>

                <div
                  class="font-weight-bold black--text">
                  Tanggal Pemakaman:
                </div>
                
                <div
                  class="mb-2">
                  {{ bpp_detail.bpp_tanggal_pemakaman | date }}
                </div>

                <div
                  class="font-weight-bold black--text">
                  Kecamatan Pemakaman:
                </div>
                
                <div
                  class="mb-2">
                  {{ bpp_detail.bpp_makam_kecamatan_name }}
                </div>

                <div
                  class="font-weight-bold black--text">
                  Kelurahan Pemakaman:
                </div>
                
                <div
                  class="mb-2">
                  {{ bpp_detail.bpp_makam_desa_name }}
                </div>

                <div
                  class="font-weight-bold black--text">
                  Nama Tempat Pemakaman:
                </div>
                
                <div
                  class="mb-2">
                  {{ bpp_detail.bpp_makam_nama }}
                </div>

                <v-divider
                  class="my-4"
                  style="border-top: 5px solid #eaeaea; border-radius: 50px;" />

                <div
                  class="font-weight-bold black--text">
                  Nama Keluarga:
                </div>
                
                <div
                  class="mb-2">
                  {{ bpp_detail.bpp_keluarga_telp_nama }}
                </div>

                <div
                  class="font-weight-bold black--text">
                  Nomor Telepon Keluarga:
                </div>
                
                <div
                  class="mb-2">
                  {{ bpp_detail.bpp_keluarga_telp_no === '' ? '-' : bpp_detail.bpp_keluarga_telp_no }}
                </div>

                <div
                  class="font-weight-bold black--text">
                  Keterangan:
                </div>
                
                <div
                  class="mb-2">
                  {{ bpp_detail.bpp_note === '' ? '-' : bpp_detail.bpp_note }}
                </div>

                <v-divider
                  class="my-4"
                  style="border-top: 5px solid #eaeaea; border-radius: 50px;" />

                <div
                  class="font-weight-bold black--text">
                  Tanggal Input Data:
                </div>
                
                <div
                  class="mb-2">
                  {{ bpp_detail.bpp_create_datetime | datetime }}
                </div>
              </v-card-text>
            </v-card>
          </v-bottom-sheet>

          <!-- PEMAKAMAN -->
          <v-bottom-sheet
            v-model="sheet.form_makam"
            hide-overlay
            persistent
            fullscreen
            scrollable>
            <v-card
              flat
              color="#f9f9f9"
              style="border-radius: 0px;">
              <v-card-title 
                class="cursor-pointer pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 10;
                      background: #ff64ad;">
                <v-icon 
                  @click="sheet.form_makam = false"
                  color="white">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  class="body-1 font-weight-bold text-capitalize white--text ml-1">
                  Tambah Tempat Pemakaman
                </span>
              </v-card-title>

              <v-card-text
                class="pt-16 mt-4">
                <ValidationObserver 
                  ref="form_makam">
                  <v-form>
                    <ValidationProvider
                      name="jenis" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Jenis Pemakaman
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-select
                        v-on:keyup.enter="save_makam"
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. Tempat Pemakaman Umum"
                        :append-icon="!valid ? '' : 'mdi-check-circle-outline'"
                        
                        :items="config.jenis_makam"
                        item-text="bppj_title"
                        item-value="bppj_title"
                        item-color="#ff64ad"

                        v-model="form_makam.jenis"
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-select>
                    </ValidationProvider>
                    
                    <ValidationProvider 
                      name="nama" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Nama Pemakaman
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save_makam"
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. Makam Trucuk"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form_makam.nama"
                        v-uppercase
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="alamat" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Alamat Tempat Pemakaman
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-textarea
                        v-on:keyup.enter="save_makam"
                        filled
                        rounded
                        dense
                        rows="3"
                        color="#ff64ad"
                        placeholder="Contoh. Jl. Pemuda No. 7"
                        :append-icon="form_makam.alamat !== '' && valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form_makam.alamat"
                        v-uppercase
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-textarea>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="kecamatan_name" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Kecamatan
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        :disabled="user.role_id > 0"
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. Trucuk"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form_makam.kecamatan_name"
                        v-uppercase
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="desa_name" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Kelurahan
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        :disabled="user.role_id > 0"
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. Kradenan"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form_makam.desa_name"
                        v-uppercase
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="petugas_nama" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Nama Petugas Pemakaman
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save_makam"
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. Riyanto"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form_makam.petugas_nama"
                        v-uppercase
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider>
                  </v-form>
                </ValidationObserver>

                <div v-if="message.error.length > 0"
                  class="text-center my-3">
                  <v-alert
                    type="error" 
                    text 
                    dense 
                    prominent 
                    v-show="message.error.length > 0">
                    {{ message.error }}
                  </v-alert>
                </div>

                <v-row>
                  <v-col 
                    cols="6" 
                    class="py-0">
                    <v-btn
                      :disabled="process.form"
                      :loading="process.form"
                      v-on:keyup.enter="save_makam"
                      @click="save_makam"
                      block
                      color="green"
                      class="text-capitalize white--text pl-1 pr-2"
                      style="border-radius: 10px; box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;">
                      <v-avatar 
                        size="25"
                        style="position: absolute; left: 2px;">
                        <v-icon small>mdi-check-bold</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Simpan
                      </span>
                    </v-btn>
                  </v-col>

                  <v-col 
                    cols="6" 
                    class="py-0">
                    <v-btn 
                      @click="sheet.form_makam = false"
                      :disabled="process.form"
                      block
                      color="red"
                      class="text-capitalize white--text pl-1 pr-2"
                      style="border-radius: 10px; box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;">
                      <v-avatar 
                        size="25"
                        style="position: absolute; left: 2px;">
                        <v-icon small>mdi-close-thick</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Batal
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-bottom-sheet>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        sheet: {
          form: false,
          detail: false,
          form_makam: false
        },
        search: '',
        selected: {
          menu: 1
        },
        menu: [
          {
            title: 'BPP',
            value: 1
          },
          {
            title: 'Pemakaman',
            value: 2
          }
        ],
        config: {
          // ADDRESS
          subdistrict: [],
          village: [],

          // MAKAM
          makam: [],
          jenis_makam: []
        },
        bpp_detail: {},
        list: [],
        pagination: {
          total_page: 0
        },
        picker: {
          birth: false,
          dead: false,
          rip: false
        },
        autocomplete: {
          // ADDRESS
          subdistrict: '',
          village: '',
          
          // MAKAM
          makam: ''
        },
        form: {
          id: '',
          nik: '',
          nama: '',
          tempat_lahir: '',
          tanggal_lahir: '',
          tempat_meninggal: '',
          tanggal_meninggal: '',
          alamat: '',
          rt: '',
          rw: '',
          kecamatan_code: '',
          kecamatan_name: '',
          desa_code: '',
          desa_name: '',
          tanggal_pemakaman: '',
          makam_kecamatan_code: '',
          makam_kecamatan_name: '',
          makam_desa_code: '',
          makam_desa_name: '',
          makam_nama: '',
          makam_kode: '',
          keluarga_telp_nama: '',
          keluarga_telp_no: '',
          note: ''
        },
        form_makam: {
          id: '',
          jenis: '',
          nama: '',
          alamat: '',
          petugas_nama: '',

          kecamatan_code: '',
          kecamatan_name: '',
          desa_code: '',
          desa_name: ''
        },
        process: {
          load: false,
          form: false,

          // ADDRESS
          subdistrict: false,
          village: false,

          // MAKAM
          makam: false,
          jenis_makam: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      // 'form.kecamatan_code': function() {
      //   let t = this
      //   let kec = this.config.subdistrict.filter(function(v) {
      //     return t.form.kecamatan_code == v.kecamatan_code
      //   })

      //   if (kec.length > 0) {
      //     this.form.kecamatan_name = kec[0].kecamatan_name

      //     this.form.desa_code = ''
      //     this.form.desa_name = ''
      //   }

      //   this.static().village()
      // },
      // 'form.desa_code': function() {
      //   let t = this
      //   let kel = this.config.village.filter(function(v) {
      //     return t.form.desa_code == v.desa_code
      //   })

      //   if (kel.length > 0) {
      //     this.form.desa_name = kel[0].desa_name
      //   }
      // },
      'form.makam_kecamatan_code': function() {
        let t = this
        let kec = this.config.subdistrict.filter(function(v) {
          return t.form.makam_kecamatan_code == v.kecamatan_code
        })

        if (kec.length > 0) {
          this.form.makam_kecamatan_name = kec[0].kecamatan_name

          this.form.makam_desa_code = ''
          this.form.makam_desa_name = ''
        }

        this.static().village()
      },
      'form.makam_desa_code': function() {
        let t = this
        let kel = this.config.village.filter(function(v) {
          return t.form.makam_desa_code == v.desa_code
        })

        if (kel.length > 0) {
          this.form.makam_desa_name = kel[0].desa_name
        }
      },
      'form.makam_kode': function() {
        let t = this
        let mak = this.config.makam.filter(function(v) {
          return t.form.makam_kode == v.bpptp_id
        })

        if (mak.length > 0) {
          this.form.makam_nama = mak[0].bpptp_nama
          // this.form.makam_kecamatan_code = mak[0].bpptp_kecamatan_code
          // this.form.makam_kecamatan_name = mak[0].bpptp_kecamatan_name
          // this.form.makam_desa_code = mak[0].bpptp_desa_code
          // this.form.makam_desa_name = mak[0].bpptp_desa_name
        }
      },
      'autocomplete.subdistrict': function() {
        this.static().subdistrict()
      },
      'autocomplete.village': function() {
        this.static().village()
        
        this.static().makam()
      },
      'selected.menu': function(val) {
        this.search = ''

        if (val === 1) {
          this.fetch()
        } else {
          this.fetchMakam()
        }
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      user() {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        let params = {
          search: this.search
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/bpp/history`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false

            this.list = response.data
            this.pagination = response.pagination

            this.reset()
          } 
        })
      },
      static () {
        let t = this
        return {
          async subdistrict () {
            t.process.subdistrict = true

            let params = {
              search: t.autocomplete.subdistrict,
              sort: 'DESC'
            }

            await t.$axios.$get(`${process.env.PREFIX_MASTER}/location/kecamatan`, { params })
            .then(response => {
              if (response.code == 200) {
                t.process.subdistrict = false

                t.config.subdistrict = response.data
              }
            })
          },
          async village () {
            t.process.village = true

            let params = {
              // desa_kecamatan_code: t.form.kecamatan_code,
              desa_kecamatan_code: t.form.makam_kecamatan_code,
              search: t.autocomplete.village,
              sort: 'DESC'
            }

            await t.$axios.$get(`${process.env.PREFIX_MASTER}/location/desa`, { params })
            .then(response => {
              if (response.code == 200) {
                t.process.village = false

                t.config.village = response.data
              }
            })
          },
          async makam () {
            t.process.makam = true

            let params = {
              search: t.autocomplete.makam,
              sort: 'DESC',
              limit: 10000,
              bpptp_desa_code: t.form.makam_desa_code
            }

            await t.$axios.$get(`${process.env.PREFIX_PROXY}/tempat-pemakaman`, { params })
            .then(response => {
              if (response.code == 200) {
                t.process.makam = false

                t.config.makam = response.data
              }
            })
          },
          async jenis_makam () {
            t.process.jenis_makam = true

            let params = {
              sort: 'DESC',
              limit: 10000
            }

            await t.$axios.$get(`${process.env.PREFIX_PROXY}/tempat-pemakaman/jenis`, { params })
            .then(response => {
              if (response.code == 200) {
                t.process.jenis_makam = false

                t.config.jenis_makam = response.data
              }
            })
          }
        }
      },
      reset (item) {
        if (this.$refs.form) this.$refs.form.reset()
        
        this.form = {
          id: item !== undefined ? item.id : '',
          nik: item !== undefined ? item.nik : '',
          nama: item !== undefined ? item.nama : '',
          tempat_lahir: item !== undefined ? item.tempat_lahir : '',
          tanggal_lahir: item !== undefined ? item.tanggal_lahir : '',
          tempat_meninggal: item !== undefined ? item.tempat_meninggal : '',
          tanggal_meninggal: item !== undefined ? item.tanggal_meninggal : '',
          alamat: item !== undefined ? item.alamat : '',
          rt: item !== undefined ? item.rt : '',
          rw: item !== undefined ? item.rw : '',
          kecamatan_code: item !== undefined ? item.kecamatan_code : this.detail.role_kecamatan_code,
          kecamatan_name: item !== undefined ? item.kecamatan_name : this.detail.role_kecamatan_name,
          desa_code: item !== undefined ? item.desa_code : this.detail.role_kelurahan_code,
          desa_name: item !== undefined ? item.desa_name : this.detail.role_kelurahan_name,
          tanggal_pemakaman: item !== undefined ? item.tanggal_pemakaman : '',
          makam_kecamatan_code: item !== undefined ? item.makam_kecamatan_code : this.detail.role_kecamatan_code,
          makam_kecamatan_name: item !== undefined ? item.makam_kecamatan_name : this.detail.role_kecamatan_name,
          makam_desa_code: item !== undefined ? item.makam_desa_code : this.detail.role_kelurahan_code,
          makam_desa_name: item !== undefined ? item.makam_desa_name : this.detail.role_kelurahan_name,
          makam_nama: item !== undefined ? item.makam_nama : '',
          makam_kode: item !== undefined ? item.makam_kode : '',
          keluarga_telp_nama: item !== undefined ? item.keluarga_telp_nama : '',
          keluarga_telp_no: item !== undefined ? item.keluarga_telp_no : '',
          note: item !== undefined ? item.note : ''
        }

        this.process.form = false

        this.message.error = ''
      },
      async save() {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          let url = this.form.id === '' ? 'bpp/create' : 'bpp/update'
          
          // SAVE TO REST API
          await this.$axios.$post(`${process.env.PREFIX_PROXY}/${url}`, this.form)
          .then((response) => {

            this.process.form = false

            if (response.code === 200) {
              this.sheet.form = false

              this.reset()

              this.fetch()
            } else {
              this.message.error = response.message
            }

          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },

      // PEMAKAMAN
      async fetchMakam () {
        this.process.load = true

        let params = {
          search: this.search
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/tempat-pemakaman`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false

            this.list = response.data
            this.pagination = response.pagination

            this.reset_makam()
          } 
        })
      },
      reset_makam (item) {
        if (this.$refs.form_makam) this.$refs.form_makam.reset()
        
        this.form_makam = {
          id: item !== undefined ? item.bpptp_id : '',
          jenis: item !== undefined ? item.bpptp_jenis : '',
          nama: item !== undefined ? item.bpptp_nama : '',
          alamat: item !== undefined ? item.bpptp_alamat : '',
          petugas_nama: item !== undefined ? item.bpptp_petugas_nama : '',

          kecamatan_code: this.detail.role_kecamatan_code,
          kecamatan_name: this.detail.role_kecamatan_name,
          desa_code: this.detail.role_kelurahan_code,
          desa_name: this.detail.role_kelurahan_name
        }

        this.process.form = false

        this.message.error = ''

        this.static().jenis_makam()
      },
      async save_makam() {
        this.message.error = ''

        const isValid = await this.$refs.form_makam.validate()
        if (isValid) {
          this.process.form = true

          let url = this.form_makam.id === '' ? 'tempat-pemakaman/create' : 'tempat-pemakaman/update'
          
          // SAVE TO REST API
          await this.$axios.$post(`${process.env.PREFIX_PROXY}/${url}`, this.form_makam)
          .then((response) => {

            this.process.form = false

            if (response.code === 200) {
              this.sheet.form_makam = false

              this.reset_makam()

              this.fetchMakam()

              this.static().makam()
            } else {
              this.message.error = response.message
            }

          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form_makam.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form_makam.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>