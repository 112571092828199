<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card 
            flat
            color="transparent">
            <v-card-text 
              class="pa-0">
              <v-carousel 
                v-model="selected.banner" 
                cycle
                hide-delimiters 
                :show-arrows="false" 
                height="auto">
                <v-carousel-item
                  v-for="(item, index) in process.banner ? 3 : list.banner"
                  :key="index">
                  <v-skeleton-loader
                    class="ma-auto"
                    :loading="process.banner"
                    type="image">
                    <div>
                      <v-img v-if="!process.banner && list.banner.length > 0"
                        width="100%"
                        height="350"
                        alt="kominfo" 
                        :src="item.image"
                        gradient="to top, rgba(0, 0, 0, .5), rgba(0, 0, 0, .1)"
                        class="ma-auto d-flex align-end">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#ff64ad">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </div>
                  </v-skeleton-loader>
                </v-carousel-item>
              </v-carousel>

              <v-bottom-sheet
                v-model="sheet"
                hide-overlay
                persistent>
                <div class="text-center mb-4">
                  <v-btn 
                    v-for="(item, index) in list.banner" 
                    :key="index"
                    :width="selected.banner === index ? 5 : 8"
                    :height="selected.banner === index ? 10 : 8"
                    elevation="0"
                    x-small
                    :rounded="selected.banner === index"
                    :fab="selected.banner !== index"
                    :color="selected.banner === index ? '#ff64ad' : '#fff'"
                    class="ma-1"
                    @click="selected.banner = index">
                  </v-btn>
                </div>

                <v-card
                  flat
                  color="#f9f9f9"
                  min-height="calc(100vh - 300px)"
                  style="border-radius: 15px 15px 0px 0px;">
                  <v-card-text class="pa-4">
                    <div class="mb-3">
                      <div class="title black--text">
                        Kontak Kami
                      </div>

                      <div class="body-2 font-weight-light black--text" style="opacity: 60%;">
                        Anda dapat menghubungi kami di:
                      </div>
                    </div>

                    <div style="overflow-y: auto; height: calc((100vh - 300px) - 100px);">
                      <v-expansion-panels 
                        flat 
                        v-model="selected.panel">
                        <v-expansion-panel
                          v-for="(item, index) in process.data ? 5 : list.data"
                          :key="index"
                          class="mb-3"
                          style="border-radius: 10px;"
                          :style="selected.panel === index ? 'box-shadow: rgba(0, 0, 0, 0.01) 0px 3px 5px;' : ''">
                          <v-skeleton-loader
                            class="ma-auto"
                            :loading="process.data"
                            type="list-item-avatar-three-line">
                            <div>
                              <div v-if="!process.data && list.data.length > 0">
                                <v-expansion-panel-header 
                                  class="py-0 px-4" 
                                  style="background: #f0f0f0; border-radius: 10px;">
                                  <v-list 
                                    dense 
                                    color="transparent" 
                                    class="pa-0">
                                    <v-list-item class="pa-0">
                                      <v-list-item-avatar
                                        class="mr-3"
                                        size="25"
                                        tile>
                                        <v-img
                                          contain
                                          alt="kominfo" 
                                          :src="item.image"
                                          class="ma-auto">
                                          <template v-slot:placeholder>
                                            <v-row
                                              class="fill-height"
                                              align="center"
                                              justify="center">
                                              <v-progress-circular 
                                                indeterminate 
                                                color="#ff64ad">
                                              </v-progress-circular>
                                            </v-row>
                                          </template>
                                        </v-img>
                                      </v-list-item-avatar>

                                      <v-list-item-content class="pa-0">
                                        <span class="body-2 font-weight-bold text-capitalize"
                                          :class="selected.panel !== index ? 'one-line' : ''">
                                          {{ item.title }}
                                        </span>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>

                                  <template v-slot:actions>
                                    <v-icon 
                                      color="#000" 
                                      small>
                                      $expand
                                    </v-icon>
                                  </template>
                                </v-expansion-panel-header>

                                <v-expansion-panel-content 
                                  class="pa-0">
                                  <v-list 
                                    flat 
                                    three-line 
                                    dense 
                                    class="py-0" 
                                    color="transparent">
                                    <v-list-item-group>
                                      <v-list-item 
                                        class="px-0">
                                        <v-list-item-content 
                                          class="body-2 font-weight-light black--text px-0"
                                          style="text-align: justify; opacity: 60%;">
                                          <span
                                            class="line-text-second"
                                            v-html="item.content">
                                          </span>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </v-list-item-group>
                                  </v-list>
                                </v-expansion-panel-content>
                              </div>
                            </div>
                          </v-skeleton-loader>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </div>
                  </v-card-text>
                </v-card>
              </v-bottom-sheet>
            </v-card-text>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        sheet: true,
        selected: {
          banner: 0,
          panel: 0
        },
        list: {
          banner: [],
          data: []
        },
        pagination: {
          banner: {},
          data: {}
        },
        process: {
          banner: false,
          data: false
        }
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      fetch () {
        this.fetchBanner()
        this.fetchData()
      },
      async fetchBanner () {
        this.process.banner = true

        let params = {
          type: 'banner'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/site`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.banner = false
            
            this.list.banner = response.data
            this.pagination.banner = response.pagination
          } 
        })
      },
      async fetchData () {
        this.process.data = true

        let params = {
          type: 'contactus',
          sort: 'ASC'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/site`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.data = false
            
            this.list.data = response.data
            this.pagination.data = response.pagination
          } 
        })
      }
    }
  }
</script>

<style>
  .v-expansion-panel-content__wrap {
    padding: 10px 16px !important;
  }
</style>