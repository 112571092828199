<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.success"
          persistent>
          <v-card 
            class="border-radius pa-2">
            <v-card-title class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar size="35">
                <v-img
                  contain
                  width="30"
                  height="30"
                  alt="kominfo"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        color="#ff64ad">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span class="body-1 font-weight-bold mt-1 ml-1">
                Ubah Password Berhasil
              </span>
            </v-card-title>
            
            <v-divider class="mx-4 mt-3 mb-2"/>

            <v-card-text class="body-2 font-weight-light text-first line-text-second mt-3">
              Terima kasih, Anda telah mengubah password untuk keamanan data pribadi Anda di SAKURA.
            </v-card-text>

            <v-divider class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn @click="dialog.success = false"
                color="red"
                elevation="0"
                class="text-capitalize white--text"
                style="border-radius: 15px; box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;">
                <v-icon small class="mr-1">mdi-close-thick</v-icon>
                Tutup
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <v-card 
            flat
            min-height="100vh"
            color="#f9f9f9">
            <v-card-text 
              class="py-8"
              style="margin-bottom: 120px;">
              <div>
                <ValidationObserver ref="form">
                  <v-form class="text-left mt-12">
                    <ValidationProvider 
                      name="old_password" 
                      rules="required|min:5|max:20"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Password Lama
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-text-field
                        v-on:keyup.enter="save"
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. ******"
                        v-model="form.old_password"
                        :type="show.old_password ? 'text' : 'password'"
                        :append-icon="show.old_password ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show.old_password = !show.old_password"
                        :counter="20"
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="new_password" 
                      rules="required|min:5|max:20"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Password Baru
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-text-field
                        v-on:keyup.enter="save"
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. ******"
                        v-model="form.new_password"
                        :type="show.new_password ? 'text' : 'password'"
                        :append-icon="show.new_password ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show.new_password = !show.new_password"
                        :counter="20"
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider>
                  </v-form>
                </ValidationObserver>

                <div v-if="message.error.length > 0"
                  class="text-center my-3">
                  <v-alert
                    type="error" 
                    text 
                    dense 
                    prominent 
                    v-show="message.error.length > 0">
                    {{ message.error }}
                  </v-alert>
                </div>
              </div>
            </v-card-text>

            <v-card
              flat
              color="#fff"
              min-height="150"
              style="border-radius: 15px 15px 0px 0px;
                    position: fixed;
                    bottom: 0;
                    width: 460px;
                    z-index: 1;">
              <v-card-text
                class="pa-4">
                <v-row 
                  align="center">
                  <v-col 
                    cols="3" 
                    class="py-0">
                    <v-img 
                      contain
                      alt="kominfo" 
                      :src="require('@/assets/image/profile/password.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="#ff64ad">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>

                  <v-col 
                    cols="9" 
                    class="black--text py-0">
                    <div>
                      <div 
                        class="body-2 line-text-second black--text" 
                        style="opacity: 60%;">
                        Masukkan password lama dan password baru Anda, kemudian klik <b class="font-italic">Simpan</b>.
                      </div>
                    </div>
                  </v-col>
                </v-row>
                
                <v-row 
                  class="mt-4">
                  <v-col 
                    cols="6" 
                    class="py-0">
                    <v-btn
                      :disabled="process.form"
                      :loading="process.form"
                      v-on:keyup.enter="save"
                      @click="save"
                      block
                      color="green"
                      class="text-capitalize white--text pl-1 pr-2"
                      style="border-radius: 10px; 
                            box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon small>mdi-check-bold</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Simpan
                      </span>
                    </v-btn>
                  </v-col>

                  <v-col 
                    cols="6" 
                    class="py-0">
                    <v-btn 
                      @click="$emit('close', false)"
                      :disabled="process.form"
                      block
                      color="red"
                      class="text-capitalize white--text pl-1 pr-2"
                      style="border-radius: 10px; 
                            box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon small>mdi-close-thick</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Batal
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        dialog: {
          success: false
        },
        show: {
          old_password: false,
          new_password: false
        },
        form: {
          old_password: '',
          new_password: ''
        },
        process: {
          form: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      
    },
    watch: {
      
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      
    },
    methods: {
      async save() {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.PREFIX_PROXY}/profile/update_password`, this.form)
          .then((response) => {

            if (response.code === 200) {
              this.process.form = false
              
              this.$emit('close', false)

              this.dialog.success = true
            } else {
              this.process.form = false
              this.message.error = response.message
            }

          })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>