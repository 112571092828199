<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex 
        style="min-height: 100vh;">
        <section>
          <v-card 
            flat 
            color="#ff64ad"
            min-height="100vh"
            style="border-radius: 0px;">
            <v-card-text
              class="text-center">
              <div
                class="pt-8 mt-16 mb-10">
                <v-img 
                  contain
                  width="200"
                  height="200"
                  alt="kominfo" 
                  :src="list[selected].icon"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        color="#ff64ad">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>

              <div
                class="pt-12 pb-16">
                <h1
                  class="text-h5 font-weight-bold white--text mb-4">
                  {{ list[selected].title }}
                </h1>

                <h2
                  class="body-1 font-weight-light line-text-second white--text mb-6">
                  {{ list[selected].desc }}
                </h2>

                <div 
                  class="text-center mb-16"> 
                  <v-btn 
                    v-for="(item, index) in list" 
                    :key="index"
                    :width="selected === index ? 5 : 8"
                    :height="selected === index ? 10 : 8"
                    elevation="0"
                    x-small
                    :rounded="selected === index"
                    :fab="selected !== index"
                    :color="selected === index ? '#fff' : '#d8d8d8'"
                    class="mx-1"
                    @click="selected = index">
                  </v-btn>
                </div>
              </div>
            </v-card-text>

            <v-card
              flat
              class="pa-4"
              color="transparent"
              style="position: fixed;
                  bottom: 0px;
                  width: 460px;">
              <div
                class="text-center cursor-pointer">
                <div @click="introductions()">
                  <span
                    class="body-1 font-weight-light white--text">
                    Lewati
                  </span>
                </div>
              </div>

              <div
                class="mt-6">
                <v-btn v-if="selected < 3"
                  @click="selected = selected += 1"
                  color="white"
                  elevation="0"
                  block
                  large
                  class="body-1 font-weight-bold color-default text-capitalize d-flex align-center"
                  style="border-radius: 10px;">
                  <span>
                    {{ list[selected].title }}
                  </span>

                  <v-spacer />

                  <v-icon class="ml-1">mdi-arrow-right-thick</v-icon>
                </v-btn>

                <v-btn v-if="selected > 2"
                  @click="introductions()"
                  color="white"
                  elevation="0"
                  block
                  large
                  class="body-1 font-weight-bold color-default text-capitalize d-flex align-center"
                  style="border-radius: 10px;">
                  <span>
                    Mulai Sekarang
                  </span>

                  <v-spacer />

                  <v-icon class="ml-1">mdi-check-bold</v-icon>
                </v-btn>
              </div>
            </v-card>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'introduction',
    data () {
      return {
        selected: 0,
        list: [
          {
            title: 'SAKURA',
            desc: 'SAKURA (Sistem Layanan Administrasi Kependudukan Dalam Jaringan) sebagai pusat pelayanan DISDUKCAPIL.',
            icon: require('@/assets/logo/favicon.png')
          },
          {
            title: 'PENGAJUAN',
            desc: 'Ajukan dokumen kependudukan yang Anda inginkan dengan layanan yang telah kami sediakan untuk kemudahan Anda.',
            icon: require('@/assets/image/intro/intro_1.png')
          },
          {
            title: 'VERIFIKASI',
            desc: 'Data yang telah Anda ajukan seperti data utama dan data dukung akan diverifikasi oleh admin kami.',
            icon: require('@/assets/image/intro/intro_2.png')
          },
          {
            title: 'PEMBAHARUAN',
            desc: 'Data yang sudah diverifikasi akan di setujui oleh admin, yang kemudian akan diperbaharui datanya.',
            icon: require('@/assets/image/intro/intro_3.png')
          }
        ],
        // SEO
        content: {
          url: '/',
          title: 'Introduction',
          description: 'Introduction Sakura Dukcapil Klaten (Sistem Layanan Administrasi Kependudukan Dalam Jaringan)',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      intro () {
        return this.$store.state.intro.intro
      }
    },
    mounted () {
      
    },
    methods: {
      introductions () {
        Cookie.set('intro', true)

        window.location = '/auth/login'
      }
    }
  }
</script>