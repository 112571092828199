<template>
  <div>
    <div @click="launchFile()">
      <slot name="activator"></slot>
    </div>

    <input type="file"
      accept="image/png, image/gif, image/jpeg"
      ref="file"
      :name="filename"
      v-on:change="changeImage($event.target.files)"
      style="display:none">

    <v-dialog 
      v-model="dialog"
      width="500">
      <v-card
        class="pa-0 ma-0"
        flat>
        <v-card
          color="red accent-2">
          <v-list-item>
            <v-list-item-avatar color="red accent-2" size="30">
              <v-icon color="white" large>mdi-alert</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-card-text class="caption white--text pa-0">
                {{ message.error }}
              </v-card-text>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'upload-folder',
    data: () => ({
      dialog: false,
      filename: 'file',
      message: {
        success: '',
        error: ''
      },
      process: {
        form: false
      }
    }),
    methods: {
      launchFile () {
        this.$refs.file.click()
      },
      changeImage (file) {
        let image = file[0]
        if (file.length > 0) {
          if (image.type != 'image/jpg' && image.type != 'image/jpeg' && image.type != 'image/png') {
            this.dialog = true
            this.message.error = 'Silahkan pilih gambar dengan format .jpg / .jpeg / .png'
          } 
          else if (image.size > 5242880) {
            this.dialog = true
            this.message.error = 'Maksimal gambar 5MB'
          } 
          else {
            let formData = new FormData()
                formData.append('api-key', '4b5485011c6ef93f143e12400fb2344b')
                formData.append('client-key', 'skab2dd76ae5199f20791c87bfdc1453dc')
                formData.append('files[]', image)
                formData.append('user_id', this.$store.state.member.auth.user.id)

            this.process.form = true
            this.$emit('input', {
              process: this.process.form
            })

            fetch(`${process.env.PREFIX_CDN}/image`, {
              method: 'POST',
              body: formData
            })
            .then(res => res.json())
            .then(response => {
              this.process.form = false

              this.$emit('input', {
                id: response.data[0].id,
                type: response.data[0].type,
                data: response.data[0].data,
                process: this.process.form
              })
            })
          }
        }
      }
    }
  }
</script>