<template>
  <div class="fill-height pb-12">
    <v-layout wrap>
      <v-flex>
        <section  
          style="min-height: 100vh;">
          <v-card 
            flat 
            color="transparent">
            <div v-if="process.load && Object.keys(detail).length < 1">
              <v-skeleton-loader
                :loading="process.load"
                type="image, article, list-item-avatar-three-line, article, list-item-three-line, list-item-three-line">
              </v-skeleton-loader>
            </div>

            <div v-if="!process.load && Object.keys(detail).length > 0">
              <v-carousel 
                v-model="selected.banner" 
                cycle
                hide-delimiters
                :show-arrows="false" 
                height="auto">
                <v-carousel-item
                  v-for="(item, index) in detail.post_image"
                  :key="index">
                  <v-img
                    width="100%"
                    height="250"
                    alt="kominfo" 
                    gradient="to top, rgba(0, 0, 0, .5), rgba(0, 0, 0, .1)"
                    :src="item.url"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          color="#ff64ad">
                        </v-progress-circular>
                      </v-row>
                    </template>

                    <div class="pa-4">
                      <v-btn
                        @click="$router.go(-1)"
                        color="#00000066"
                        elevation="0"
                        class="px-0"
                        min-width="36">
                        <v-icon color="white">mdi-arrow-left-thick</v-icon>
                      </v-btn>
                    </div>

                    <div class="text-center"
                      style="position: absolute; bottom: 40px; left: 0px; right: 10px;">
                      <v-btn 
                        v-for="(item, index) in detail.post_image"
                        :key="index"
                        :width="selected.banner === index ? 5 : 8"
                        :height="selected.banner === index ? 10 : 8"
                        elevation="0"
                        x-small
                        :rounded="selected.banner === index"
                        :fab="selected.banner !== index"
                        :color="selected.banner === index ? '#ff64ad' : '#fff'"
                        class="mx-1"
                        @click="selected.banner = index">
                      </v-btn>
                    </div>
                  </v-img>
                </v-carousel-item>
              </v-carousel>

              <div style="position: relative;">
                <v-avatar @click="sheet.comment = true"
                  style="box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
                        position: absolute;
                        right: 0;
                        bottom: -12px;
                        z-index: 1;"
                    color="#ff64ad"
                    size="50"
                    class="mr-3 my-3">
                    <v-icon color="white">mdi-chat-processing-outline</v-icon>
                </v-avatar>

                <div
                  style="height: 25px;
                        background: #f9f9f9;
                        border-radius: 30px 30px 0px 0px;
                        position: absolute;
                        bottom: 0;
                        width: 100%;">
                </div>
              </div>

              <v-card
                flat
                color="#f9f9f9">
                <v-card-text class="py-0">
                  <div class="d-flex align-center mb-1">
                    <v-icon small>mdi-tag-multiple</v-icon>
                
                    <span class="caption ml-1">
                      {{ detail.post_cat_title }}
                    </span>
                  </div>

                   <div class="title font-weight-bold black--text">
                    {{ detail.post_title }}
                  </div>
                </v-card-text>

                <v-card-text class="pt-1 pb-0">
                  <div class="d-flex align-center">
                    <div>
                      <v-list flat color="transparent">
                        <v-list-item-group>
                          <template>
                            <v-list-item class="px-0">
                              <v-list-item-avatar 
                                size="42"
                                class="mr-3">
                                <v-img 
                                  style="border-radius: 5px;"
                                  alt="kominfo" 
                                  :src="detail.post_extra.createdby.image"
                                  class="ma-auto">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular 
                                        indeterminate 
                                        color="#ff64ad">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title class="body-2 font-weight-bold">
                                  {{ detail.post_extra.createdby.name }}
                                </v-list-item-title>

                                <v-list-item-subtitle class="caption font-weight-light black--text my-1" style="opacity: 60%;">
                                  {{ detail.post_create_datetime | date }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-list-item-group>
                      </v-list>
                    </div>

                    <v-spacer />

                    <div @click="sheet.share = true">
                      <v-icon size="25">mdi-share-variant-outline</v-icon>
                    </div>
                  </div>
                </v-card-text>

                <v-divider 
                  class="mx-4" 
                  style="border: 1px solid #eaeaea;" />

                <v-card-text 
                  class="body-2 font-weight-light line-text-second black--text" 
                  style="opacity: 60%;">
                  <span v-html="detail.post_content"></span>
                </v-card-text>
              </v-card>
            </div>
          </v-card>

          <v-bottom-sheet
            v-model="sheet.share"
            hide-overlay>
            <v-card
              flat
              color="#fff"
              class="d-flex align-center"
              style="border-radius: 30px 30px 0px 0px;">
              <v-card-text
                class="pa-4">
                <div class="mb-3">
                  <v-divider 
                    class="mb-1"
                    style="border: 3px solid #ff64ad; border-radius: 50px; width: 50px;" />

                  <div class="title black--text">
                    Share Link
                  </div>

                  <div class="body-2 font-weight-light black--text" style="opacity: 60%;">
                    Untuk share link kejadian, klik icon di bawah ini :
                  </div>
                </div>

                <v-row justify="center" align="center">
                  <v-col cols="3" class="text-center"
                    v-for="(item, index) in sosmed"
                    :key="index">
                    <v-avatar v-if="Object.keys(detail).length > 0"
                      size="45">
                      <ShareNetwork
                        :network="item.text"
                        :url="`${host}${$route.path}`"
                        :title="detail.post_title"
                        :description="detail.post_content.replace(/(<([^>]+)>)/ig, '')"
                        :quote="detail.post_content.replace(/(<([^>]+)>)/ig, '')"
                        hashtags="SAKURA,Kominfo">
                        <v-img
                          contain
                          width="40"
                          height="40"
                          alt="kominfo"
                          :src="item.icon">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#ff64ad">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </ShareNetwork>
                    </v-avatar>

                    <div 
                      class="caption one-line text-capitalize mt-1" 
                      style="opacity: 60%;">
                      {{ item.text }}
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-bottom-sheet>

          <v-bottom-sheet
            v-model="sheet.comment"
            hide-overlay
            persistent
            fullscreen
            scrollable>
            <v-card
              flat
              style="border-radius: 0px;">
              <v-card-title 
                @click="sheet.comment = false"
                class="cursor-pointer pa-4"
                style="border-radius: 0px 0px 30px 30px;
                      position: absolute;
                      width: 100%;
                      z-index: 1;
                      background: #ff64ad;">
                <v-icon color="white">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  class="body-1 font-weight-bold text-capitalize white--text ml-1">
                  Komentar
                </span>
              </v-card-title>

              <v-card-text
                class="d-flex align-center justify-center"
                style="min-height: 95vh;">
                <div class="text-center">
                  <v-img
                    class="ma-auto"
                    contain
                    width="200"
                    height="200"
                    alt="kominfo"
                    :src="require('@/assets/logo/favicon.png')">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          color="#ff64ad">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  
                  <div class="text-h5 font-weight-bold black--text mt-6 mb-1" style="opacity: 60%;">
                    COMING SON !!!
                  </div>

                  <div style="opacity: 60%;">
                    Sakura Dukcapil Klaten (Sistem Layanan Administrasi Kependudukan Dalam Jaringan)
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-bottom-sheet>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        sheet: {
          comment: false,
          share: false
        },
        selected: {
          banner: 0
        },
        sosmed: [
          {
            text: 'facebook', 
            icon: require('@/assets/sosmed/ic_share_fb.svg') 
          },
          {
            text: 'twitter', 
            icon: require('@/assets/sosmed/ic_share_tw.svg') 
          },
          {
            text: 'telegram', 
            icon: require('@/assets/sosmed/ic_share_tel.svg') 
          },
          {
            text: 'whatsapp', 
            icon: require('@/assets/sosmed/ic_share_wa.svg') 
          }
        ],
        detail: {},
        process: {
          load: false
        },
        // SEO
        content: {
          url: '/news/:id',
          title: 'Detail Berita',
          description: 'Detail Berita Sakura Dukcapil Klaten (Sistem Layanan Administrasi Kependudukan Dalam Jaringan)',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {
      
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      host() {
        return process.env.HOST_NAME
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        let params = {
          id: this.$route.params.id
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/post/detail`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false
            
            this.detail = response.data
          } 
        })
      }
    }
  }
</script>