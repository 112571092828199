<template>
  <div class="fill-height pb-16">
    <v-layout wrap>
      <v-flex 
        style="min-height: 100vh;">
        <section>
          <v-card 
            flat 
            color="#ff64ad"
            height="200"
            style="border-radius: 0px 0px 100px 100px;">
            <v-card-text>
              <no-ssr>
                <carousel-3d 
                  class="mt-12 mb-0"
                  ref="carousel3dBanner"
                  :autoplay="true"
                  :autoplay-timeout="5000"
                  :perspective="0"
                  :border="0"
                  :space="0"
                  :height="230"
                  :count="list.banner.length"
                  :display="pagination.banner.total_display"
                  @after-slide-change="onAfterSlideChange">
                  <slide 
                    v-for="(item, index) in process.banner ? 3 : list.banner"
                    :key="index"
                    :index="index"
                    style="background: none; padding-top: 20px;">
                    <v-skeleton-loader
                      class="ma-auto"
                      :loading="process.banner"
                      type="image">
                      <div>
                        <v-img v-if="!process.banner && list.banner.length > 0"
                          width="100%"
                          height="185"
                          alt="kominfo" 
                          :src="item.image"
                          gradient="to top, rgba(0, 0, 0, .7), rgba(0, 0, 0, 0)"
                          class="ma-auto"
                          style="border-radius: 20px; position: relative;">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#ff64ad">
                              </v-progress-circular>
                            </v-row>
                          </template>

                          <div 
                            class="white--text ma-4"
                            style="position: absolute; bottom: 5px;"> 
                            <div
                              class="body-1 font-weight-bold">
                              {{ item.title }}
                            </div>

                            <div
                              class="body-2 font-weight-light">
                              <span
                                v-html="item.content">
                              </span>
                            </div>
                          </div>

                          <div 
                            class="text-center"
                            style="position: absolute; bottom: 10px; left: 0; right: 0;"> 
                            <v-btn 
                              v-for="(item, index) in list.banner" 
                              :key="index"
                              :width="selected.banner === index ? 5 : 8"
                              :height="selected.banner === index ? 10 : 8"
                              elevation="0"
                              x-small
                              :rounded="selected.banner === index"
                              :fab="selected.banner !== index"
                              :color="selected.banner === index ? '#ff64ad' : '#fff'"
                              class="mx-1"
                              @click="goToSlide(index)">
                            </v-btn>
                          </div>
                        </v-img>
                      </div>
                    </v-skeleton-loader>
                  </slide>
                </carousel-3d>
              </no-ssr>
            </v-card-text>

            <div
              class="my-2 mb-3">
              <v-img 
                contain
                width="230"
                height="auto"
                alt="kominfo" 
                :src="require('@/assets/image/logo_berakhlak.png')"
                class="ma-auto">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      indeterminate 
                      color="#ff64ad">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </div>

            <v-card-title
              class="d-flex justify-center title pt-2 black--text line-text-second">
              Layanan Kami
            </v-card-title>
            
            <v-card-subtitle 
              class="body-2 font-weight-light text-center pb-0">
              Sistem Administrasi Kependudukan
            </v-card-subtitle>

            <v-card-text
              class="py-0">
              <v-row 
                justify="center">
                <v-col 
                  cols="2">
                  <v-divider style="border: 5px solid #ff64ad; border-radius: 50px;"/>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text
              class="pt-2">
              <v-row 
                justify="center">
                <v-col 
                  cols="4"
                  v-for="(item, index) in process.service ? 6 : list.service"
                  :key="index"
                  v-show="index < 6">
                  <v-skeleton-loader
                    :loading="process.service"
                    type="avatar"
                    tile>
                    <div>
                      <v-card v-if="!process.service && list.service.length > 0"
                        :to="`/submission?id=${index}`"
                        flat
                        color="white"
                        class="pa-2"
                        style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
                              border-radius: 10px;">
                        <v-img 
                          width="40"
                          height="auto"
                          alt="kominfo" 
                          :src="item.layanan_image"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#ff64ad">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>

                        <div 
                          class="text-center font-weight-bold mt-3"
                          style="font-size: 10px; 
                                opacity: 60%;
                                line-height: 1.2;">
                          {{ item.layanan_kode }}
                        </div>

                        <div 
                          class="text-center one-line"
                          style="font-size: 8px; opacity: 40%;">
                          ({{ item.layanan_nama }})
                        </div>
                      </v-card>
                    </div>
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <nuxt-link
                to="/service">
                <span
                  class="caption d-flex align-center justify-end black--text mt-2">
                  <span
                    style="opacity: 40%;">
                    Lihat semua
                  </span>

                  <v-icon 
                    small 
                    class="ml-1"
                    style="opacity: 60%;">
                    mdi-chevron-double-right
                  </v-icon>
                </span>
              </nuxt-link>
            </v-card-text>

            <v-card-text
              class="pt-2 mb-4">
              <v-card v-if="detail.role_id > 0"
                flat
                color="#ff64ad"
                class="bg_group"
                style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px !important;
                      border-radius: 15px;">
                <v-card-text
                  class="py-2">
                  <v-list 
                    dense 
                    three-line
                    color="transparent" 
                    class="py-0">
                    <v-list-item 
                      class="px-0">
                      <v-list-item-avatar 
                        tile
                        size="75">
                        <v-img 
                          alt="kominfo" 
                          :src="require(`@/assets/image/team.png`)"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="white">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <div
                          class="white--text">
                          <div
                            class="body-1 font-weight-bold">
                            {{ detail.role_level }}
                          </div>

                          <div
                            class="caption mb-2">
                            {{ detail.update_datetime | date }}
                          </div>

                          <div
                            class="line-text-second mb-4">
                            <span v-if="detail.role_level === 'Kabupaten / Kota'">
                              Anda telah terdaftar sebagai petugas registrasi {{ detail.role_name }} di wilayah {{ detail.role_level }} {{ detail.role_kabupaten_name }}
                            </span>

                            <span v-if="detail.role_level === 'Kecamatan'">
                              Anda telah terdaftar sebagai petugas registrasi {{ detail.role_name }} di wilayah {{ detail.role_level }} {{ detail.role_kecamatan_name }}
                            </span>

                            <span v-if="detail.role_level === 'Desa / Kelurahan'">
                              Anda telah terdaftar sebagai petugas registrasi {{ detail.role_name }} di wilayah {{ detail.role_level }} {{ detail.role_kelurahan_name }}, Kecamatan {{ detail.role_kecamatan_name }}
                            </span>
                          </div>

                          <div
                            class="text-right mb-1">
                            <v-btn
                              to="/profile?menu=group"
                              small
                              elevation="0"
                              class="caption font-weight-bold color-default text-capitalize"
                              style="border-radius: 15px;">
                              Lihat Group

                              <v-icon small class="ml-1">mdi-arrow-right-thick</v-icon>
                            </v-btn>
                          </div>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>

                </v-card-text>
              </v-card>

              <v-card v-else
                flat
                color="#ff64ad"
                style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px !important;
                      border-radius: 15px;">
                <v-card-text
                  class="py-2">
                  <v-list 
                    dense 
                    three-line
                    color="transparent" 
                    class="py-0">
                    <v-list-item 
                      class="px-0">
                      <v-list-item-avatar 
                        tile
                        size="75">
                        <v-img 
                          alt="kominfo" 
                          :src="require(`@/assets/image/submission.png`)"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="white">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <div 
                          class="title text-capitalize font-weight-bold white--text one-line mb-2">
                          Hai, {{ user.fullname }}
                        </div>
                        
                        <div 
                          class="body-2 line-text-second white--text mb-4">
                          Silakan ajukan dokumen kependudukan yang Anda inginkan.
                        </div>

                        <div
                          class="text-right mb-1">
                          <v-btn
                            to="/submission"
                            small
                            elevation="0"
                            class="caption font-weight-bold color-default text-capitalize"
                            style="border-radius: 15px;">
                            Ajukan

                            <v-icon small class="ml-1">mdi-arrow-right-thick</v-icon>
                          </v-btn>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-card-text>

            <v-card-text v-if="process.produk || list.produk.length > 0"
              class="d-flex align-start pl-0 py-0">
              <div>
                <v-card-title
                  class="title black--text pt-0">
                  Produk Siap Diambil
                </v-card-title>

                <v-card-subtitle
                  class="body-2 font-weight-light pb-2">
                  Produk KTP/KIA Anda sudah bisa diambil.
                </v-card-subtitle>
              </div>
            </v-card-text>

            <v-card-text v-if="process.produk || list.produk.length > 0"
              class="pt-1 mb-2">
              <v-list 
                flat 
                three-line
                color="transparent">
                <v-list-item-group>
                  <v-skeleton-loader
                    v-for="(item, index) in process.produk ? 3 : list.produk"
                    :key="index"
                    :loading="process.produk"
                    type="list-item-avatar-three-line">
                    <template>
                      <div>
                        <v-list-item v-if="!process.produk && list.produk.length > 0"
                          :to="`/progress/${item.ajuan_layanan_kode}/${item.prod_ajuan_id}?code=${item.ajuan_layanan_kode}&reporter=${item.ajuan_is_mandiri}`"
                          :class="index < list.produk.length - 1 ? 'mb-4' : ''"
                          class="pl-3"
                          style="box-shadow: rgba(0, 0, 0, 0.01) 0px 3px 5px; 
                                border-radius: 5px;
                                border-left: 4px solid #ff64ad;
                                background: #fff;">
                          <v-list-item-avatar 
                            tile 
                            size="40"
                            class="mr-3">
                            <v-img 
                              style="border-radius: 5px;"
                              alt="kominfo" 
                              :src="require(`@/assets/image/service/${item.prod_layanan_kode}.png`)"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#ff64ad">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <div
                              class="d-flex align-center mb-2">
                              <v-chip
                                class="white--text px-2"
                                :color="item.ajuan_is_mandiri ? 'green' : 'blue'"
                                x-small>
                                {{ item.ajuan_pelapor_role_name }}
                              </v-chip>

                              <v-icon v-if="item.ajuan_status === 'DITOLAK'"
                                color="red"
                                size="20"
                                style="position: absolute; right: 15px;">
                                mdi-cancel
                              </v-icon>
                            </div>

                            <v-list-item-title 
                              class="body-2 font-weight-bold">
                             {{ item.prod_layanan_kode }} - {{ item.prod_nomor }}
                            </v-list-item-title>

                            <v-list-item-subtitle 
                              class="caption font-weight-light black--text mt-1 mb-2" 
                              style="opacity: 60%;">
                              <span
                                class="one-line">
                                {{ item.prod_status }}
                              </span>
                            </v-list-item-subtitle>

                            <div
                              class="mb-3">
                              <v-progress-linear
                                style="border-radius: 50px;"
                                :value="item.ajuan_status === 'BELUM DIVERIFIKASI' ? 10 :
                                        item.ajuan_status === 'PROSES VERIFIKASI' || item.ajuan_status === 'PROSES INPUT NIK' ? 25 :
                                        item.ajuan_status === 'DISETUJUI' ? 50 :
                                        item.ajuan_status === 'DISETUJUI TANPA NIK' ? 50 :
                                        item.ajuan_status === 'DISETUJUI TERBIT NIK' ? 50 :
                                        item.ajuan_status === 'DIKOREKSI' ? 75 :
                                        item.ajuan_status === 'SELESAI DIPROSES' ? 100 : 0"
                                        
                                :color="item.ajuan_status === 'BELUM DIVERIFIKASI' ? 'orange' :
                                        item.ajuan_status === 'PROSES VERIFIKASI' || item.ajuan_status === 'PROSES INPUT NIK' ? 'blue' :
                                        item.ajuan_status === 'DISETUJUI' ? 'cyan' :
                                        item.ajuan_status === 'DISETUJUI TANPA NIK' ? 'indigo' :
                                        item.ajuan_status === 'DISETUJUI TERBIT NIK' ? 'lime' :
                                        item.ajuan_status === 'DIKOREKSI' ? 'purple' :
                                        item.ajuan_status === 'SELESAI DIPROSES' ? 'green' : 'red'"
                                height="8">
                              </v-progress-linear>
                            </div>

                            <div
                              class="d-flex align-center"
                              style="font-size: 10px; opacity: 40%;">
                              <div>
                                {{ item.ajuan_create_datetime | date }}
                              </div>

                              <v-spacer />

                              <div>
                                {{ item.ajuan_create_datetime | time }}
                              </div>
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-list-item-group>
              </v-list>
            </v-card-text>

            <v-card-text v-if="process.history || list.history.length > 0"
              class="d-flex align-start pl-0 py-0">
              <div>
                <v-card-title
                  class="title black--text pt-0">
                  Riwayat Ajuan
                </v-card-title>

                <v-card-subtitle
                  class="body-2 font-weight-light pb-2">
                  Berikut adalah riwayat ajuan Anda.
                </v-card-subtitle>
              </div>

              <v-spacer />

              <nuxt-link v-if="pagination.history.total_data > 3"
                to="/profile?menu=submission">
                <span
                  class="caption d-flex align-center justify-end black--text">
                  <span
                    style="opacity: 40%;">
                    Lihat semua
                  </span>

                  <v-icon 
                    small 
                    class="ml-1"
                    style="opacity: 60%;">
                    mdi-chevron-double-right
                  </v-icon>
                </span>
              </nuxt-link>
            </v-card-text>

            <v-card-text v-if="process.history || list.history.length > 0"
              class="pt-1 mb-2">
              <v-list 
                flat 
                three-line
                color="transparent">
                <v-list-item-group>
                  <v-skeleton-loader
                    v-for="(item, index) in process.history ? 3 : list.history"
                    :key="index"
                    :loading="process.history"
                    type="list-item-avatar-three-line">
                    <template>
                      <div>
                        <v-list-item v-if="!process.history && list.history.length > 0"
                          :to="`/progress/${item.ajuan_layanan_kode}/${item.id}?code=${item.ajuan_layanan_kode}&reporter=${item.ajuan_is_mandiri}`"
                          :class="index < list.history.length - 1 ? 'mb-4' : ''"
                          class="pl-3"
                          style="box-shadow: rgba(0, 0, 0, 0.01) 0px 3px 5px; 
                                border-radius: 5px;
                                border-left: 4px solid #ff64ad;
                                background: #fff;">
                          <v-list-item-avatar 
                            tile 
                            size="40"
                            class="mr-3">
                            <v-img 
                              style="border-radius: 5px;"
                              alt="kominfo" 
                              :src="require(`@/assets/image/service/${item.ajuan_layanan_kode}.png`)"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#ff64ad">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <div
                              class="d-flex align-center mb-2">
                              <v-chip
                                class="white--text px-2"
                                :color="item.ajuan_is_mandiri ? 'green' : 'blue'"
                                x-small>
                                <!-- {{ item.ajuan_is_mandiri ? 'Mandiri' : 'Group' }} -->
                                {{ item.ajuan_pelapor_role_name }}
                              </v-chip>

                              <v-icon v-if="item.ajuan_status === 'DITOLAK'"
                                color="red"
                                size="20"
                                style="position: absolute; right: 15px;">
                                mdi-cancel
                              </v-icon>
                            </div>

                            <v-list-item-title 
                              class="body-2 font-weight-bold">
                              {{ item.ajuan_no_reg }}

                              <!-- <v-icon v-if="item.ajuan_status === 'DITOLAK'"
                                color="red"
                                size="20"
                                style="position: absolute; right: 15px;">
                                mdi-cancel
                              </v-icon> -->
                            </v-list-item-title>

                            <v-list-item-subtitle 
                              class="caption font-weight-light black--text mt-1 mb-2" 
                              style="opacity: 60%;">
                              <span
                                class="one-line">
                                {{ item.ajuan_keterangan === '' ? '-' : item.ajuan_keterangan }}
                              </span>
                            </v-list-item-subtitle>

                            <div
                              class="mb-3">
                              <v-progress-linear
                                style="border-radius: 50px;"
                                :value="item.ajuan_status === 'BELUM DIVERIFIKASI' ? 10 :
                                        item.ajuan_status === 'PROSES VERIFIKASI' || item.ajuan_status === 'PROSES INPUT NIK' ? 25 :
                                        item.ajuan_status === 'DISETUJUI' ? 50 :
                                        item.ajuan_status === 'DISETUJUI TANPA NIK' ? 50 :
                                        item.ajuan_status === 'DISETUJUI TERBIT NIK' ? 50 :
                                        item.ajuan_status === 'DIKOREKSI' ? 75 :
                                        item.ajuan_status === 'SELESAI DIPROSES' ? 100 : 0"
                                        
                                :color="item.ajuan_status === 'BELUM DIVERIFIKASI' ? 'orange' :
                                        item.ajuan_status === 'PROSES VERIFIKASI' || item.ajuan_status === 'PROSES INPUT NIK' ? 'blue' :
                                        item.ajuan_status === 'DISETUJUI' ? 'cyan' :
                                        item.ajuan_status === 'DISETUJUI TANPA NIK' ? 'indigo' :
                                        item.ajuan_status === 'DISETUJUI TERBIT NIK' ? 'lime' :
                                        item.ajuan_status === 'DIKOREKSI' ? 'purple' :
                                        item.ajuan_status === 'SELESAI DIPROSES' ? 'green' : 'red'"
                                height="8">
                              </v-progress-linear>
                            </div>

                            <div
                              class="d-flex align-center"
                              style="font-size: 10px; opacity: 40%;">
                              <div>
                                {{ item.ajuan_create_datetime | date }}
                              </div>

                              <v-spacer />

                              <div>
                                {{ item.ajuan_create_datetime | time }}
                              </div>
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-list-item-group>
              </v-list>
            </v-card-text>

            <v-card-text v-if="process.news || list.news.length > 0"
              class="d-flex align-start pl-0 py-0">
              <div>
                <v-card-title
                  class="title black--text pt-0">
                  Berita Terkini
                </v-card-title>

                <v-card-subtitle
                  class="body-2 font-weight-light pb-2">
                  Berita terkini untuk Anda.
                </v-card-subtitle>
              </div>

              <v-spacer />

              <nuxt-link v-if="pagination.news.total_data > 5"
                to="/news">
                <span
                  class="caption d-flex align-center justify-end black--text">
                  <span
                    style="opacity: 40%;">
                    Lihat semua
                  </span>

                  <v-icon 
                    small 
                    class="ml-1"
                    style="opacity: 60%;">
                    mdi-chevron-double-right
                  </v-icon>
                </span>
              </nuxt-link>
            </v-card-text>

            <div class="pb-16">
              <no-ssr>
                <swiper 
                  class="swiper"
                  ref="swiper"
                  :options="swiperOption">
                  <swiper-slide 
                    v-for="(item, index) in process.news ? 5 : list.news" :key="index" 
                    class="py-3" 
                    :class="index < 1 ? 'px-4' : index < list.news.length ? 'pr-4' : 'px-4'"
                    style="width: auto;">
                    <v-skeleton-loader
                      class="ma-auto"
                      width="175"
                      :loading="process.news"
                      type="card, article">
                      <div>
                        <v-card v-if="!process.news && list.news.length > 0"
                          :to="`/news/${item.id}`"
                          class="border-radius"
                          style="box-shadow: rgba(0, 0, 0, 0.01) 0px 3px 5px;"
                          width="175">
                          <v-img
                            width="100%"
                            height="130"
                            alt="kominfo" 
                            :src="item.post_image[0].url"
                            class="ma-auto">
                            <v-card flat
                              color="transparent"
                              class="d-flex align-center pa-3"
                              style="border-radius: 50px;">
                              <v-avatar size="20">
                                <v-img 
                                  alt="kominfo" 
                                  :src="item.post_extra.createdby.image"
                                  class="ma-auto">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular 
                                        indeterminate 
                                        color="#ff64ad"
                                        size="15">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-avatar>

                              <div 
                                class="caption text-capitalize font-weight-bold one-line white--text pl-2">
                                {{ item.post_extra.createdby.name }}
                              </div>
                            </v-card>

                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#ff64ad">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>

                          <div class="pa-3">
                            <div class="body-2 font-weight-bold one-line">
                              {{ item.post_title }}
                            </div>

                            <div class="caption font-weight-light two-line my-2" style="opacity: 60%;">
                              <span v-html="item.post_content.replace(/(<([^>]+)>)/ig, '')"></span>
                            </div>

                            <div class="d-flex align-center">
                            <v-icon small color="#212121">mdi-tag-multiple</v-icon>

                            <span class="caption one-line ml-1">
                              {{ item.post_cat_title }}
                            </span>
                          </div>

                            <v-divider class="my-2" style="border: 2px solid #f9f9f9;" />

                            <div class="caption d-flex align-center" style="opacity: 60%;">
                              <div>
                                {{ item.post_create_datetime | date }}
                              </div>

                              <v-spacer />

                              <div>
                                {{ item.post_create_datetime | time }}
                              </div>
                            </div>
                          </div>
                        </v-card>
                      </div>
                    </v-skeleton-loader>
                  </swiper-slide>
                </swiper>
              </no-ssr>
            </div>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        swiperOption: {
          slidesPerView: 'auto'
        },
        selected: {
          banner: 0,
          news: 0
        },

        total_produk: 0,

        detail: {},

        list: {
          banner: [],
          service: [],
          news: [],
          history: [],

          produk: []
        },
        pagination: {
          banner: {},
          news: {},
          history: {}
        },
        process: {
          banner: false,
          service: false,
          profile: false,
          history: false,
          news: false,

          produk: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/home',
          title: 'Beranda',
          description: 'Beranda Sakura Dukcapil Klaten (Sistem Layanan Administrasi Kependudukan Dalam Jaringan)',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      fetch () {
        this.fetchBanner()
        this.fetchService()
        this.fetchProfile()
        this.fetchHistory()
        this.fetchNews()

        this.fetchProduk()
      },
      async fetchBanner () {
        this.process.banner = true

        let params = {
          type: 'banner'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/site`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.banner = false
            
            this.list.banner = response.data
            this.pagination.banner = response.pagination
          } 
        })
      },
      async fetchService () {
        this.process.service = true

        let params = {
          sort: 'DESC'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/layanan`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.service = false
            
            this.list.service = response.data
          } 
        })
      },
      async fetchProfile () {
        this.process.profile = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/profile`)
        .then((response) => {
          if (response.code === 200) {
            this.process.profile = false
            
            this.detail = response.data
          } 
        })
      },
      async fetchHistory () {
        this.process.history = true

        let params = {
          limit: 3
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/ajuan/user_history`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.history = false
            
            this.list.history = response.data
            this.pagination.history = response.pagination
          } 
        })
      },
      async fetchNews () {
        this.process.news = true

        let params = {
          post_type: 'news',
          post_status: 'publish',
          limit: 5
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/post`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.news = false
            
            this.list.news = response.data
            this.pagination.news = response.pagination
          } 
        })
      },
      onAfterSlideChange(index) {
        this.selected.banner = index
      },
      goToSlide(index) {
        this.selected.banner = index
        this.$refs.carousel3dBanner.goSlide(index)
      },
      goToSlidePrev(index) {
        this.selected.banner = index === 0 ? this.list.banner.length - 1 : this.selected.banner - 1
        this.$refs.carousel3dBanner.goSlide(this.selected.banner)
      },
      goToSlideNext(index) {
        this.selected.banner = index === this.list.banner.length - 1 ? 0 : this.selected.banner + 1
        this.$refs.carousel3dBanner.goSlide(this.selected.banner)
      },

      async fetchProduk () {
        this.process.produk = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/produk/list_perantara_limit`)
        .then((response) => {
          if (response.code === 200) {
            this.process.produk = false

            this.list.produk = response.data.result

            this.total_produk = response.data.total_data
          } 
        })
      }
    }
  }
</script>

<style>
  .v-skeleton-loader__avatar {
    margin: auto;
  }
</style>