<template>
  <v-card 
    flat 
    class="overflow-hidden"
    style="border-radius: 0px;">
    <v-app-bar 
      v-if="!spinner &&
            $route.path !== '/' &&
            $route.path !== '/auth/login' && 
            $route.path !== '/auth/forgot' &&
            $route.path !== '/auth/register' &&
            $route.path !== '/auth/check' &&
            $route.path !== '/auth/checkforgot' &&
            $route.path !== '/auth/success' &&
            $route.path !== '/auth/successforgot' &&
            $route.name !== 'auth-verification-slug' &&
            $route.name !== 'auth-reset-slug' &&
            $route.name !== 'news-id'"
      app
      absolute
      color="#ff64ad"
      elevation="0"
      hide-on-scroll
      :prominent="false"
      scroll-target="#scrolling-techniques-4"
      style="border-radius: 0px 0px 15px 15px; z-index: 9;">
      <div 
        v-if="$route.path === '/home' ||
              $route.path === '/service' || 
              $route.path === '/submission' ||
              $route.path === '/announcement' ||
              $route.path === '/profile' ||
              $route.path === '/news' ||
              ($route.name.split('-')[0] !== 'progress' &&
              $route.name.split('-')[0] !== 'product')">
        <div class="text-capitalize white--text font-weight-bold">
          <span v-if="$route.path === '/404' || $route.path === '/500'">
            Error
          </span>

          <span v-else>
            {{ 
              $route.name === 'home' ? 'Beranda' : 
              $route.name === 'service' ? 'Layanan' :
              $route.name === 'announcement' ? 'Pengumuman' :
              $route.name === 'profile' ? 'Profil' :
              $route.name === 'news' ? 'Berita' : 'Pengajuan'
            }}
          </span>

        </div>

        <div 
          class="white--text"
          style="font-size: 10px;">
          <!-- Sakura Dukcapil Klaten (Sistem Layanan Administrasi Kependudukan Dalam Jaringan) -->
          Sakura Dukcapil Klaten
        </div>
      </div>
      
      <div v-else>
        <v-icon 
          class="cursor-pointer" 
          color="white" 
          @click="$route.name.split('-')[0] === 'progress' ? $router.push(`/profile?menu=submission&code=${$route.path.split('/')[2]}`) : $router.go(-1)">
          mdi-arrow-left-thick
        </v-icon>

        <span v-if="$route.name.split('-')[0] === 'progress'"
          class="text-capitalize white--text font-weight-bold">
          Riwayat Ajuan
        </span>

        <span v-if="$route.name.split('-')[0] === 'product'"
          class="text-capitalize white--text font-weight-bold">
          Riwayat Produk
        </span>
      </div>

      <v-spacer />

      <v-btn v-if="$route.name.split('-')[0] !== 'progress' && $route.name.split('-')[0] !== 'product'"
        icon 
        @click="sheet = true">
        <v-icon 
          color="#fff">
          mdi-bell-badge
        </v-icon>
      </v-btn>
    </v-app-bar>

    <v-sheet 
      id="scrolling-techniques-4"
      class="overflow-y-auto"
      max-height="100vh"
      @scroll="onScroll"
      style="background: #f9f9f9;">
      <div
        style="min-height: 100vh;">
        <Spinner v-if="spinner" />
        
        <nuxt />
      </div>
    </v-sheet>

    <v-bottom-sheet
      v-model="sheet"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card
        flat
        style="border-radius: 0px;">
        <v-card-title 
          @click="sheet = false"
          class="cursor-pointer pa-4"
          style="border-radius: 0px 0px 15px 15px;
                position: absolute;
                width: 100%;
                z-index: 1;
                background: #ff64ad;">
          <v-icon color="white">
            mdi-arrow-left-thick
          </v-icon>

          <span
            class="body-1 font-weight-bold text-capitalize white--text ml-1">
            Pemberitahuan
          </span>
        </v-card-title>

        <v-card-text
          class="d-flex align-center justify-center"
          style="min-height: 95vh;">
          <div class="text-center">
            <v-img
              class="ma-auto"
              contain
              width="200"
              height="200"
              alt="kominfo"
              :src="require('@/assets/logo/favicon.png')">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular 
                    indeterminate 
                    color="#ff64ad">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
            
            <div class="text-h5 font-weight-bold black--text mt-6 mb-1" style="opacity: 60%;">
              COMING SON !!!
            </div>

            <div style="opacity: 60%;">
              Sakura Dukcapil Klaten (Sistem Layanan Administrasi Kependudukan Dalam Jaringan)
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </v-card>
</template>

<script>
  import Spinner from "~/components/Spinner.vue"
  export default {
    middleware: 'notAuthenticated',
    data () {
      return {
        spinner: true,
        sheet: false,
        scroll: true
      }
    },
    components: {
      Spinner
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      token() {
        return this.$store.state.member.auth.token
      }
    },
    mounted () {
      const debounce = (fn) => {
        let frame;
        return (...params) => {
          if (frame) { 
            cancelAnimationFrame(frame)
          }
          frame = requestAnimationFrame(() => {
            fn(...params)
          })
        } 
      }

      const storeScroll = () => {
        document.documentElement.dataset.scroll = window.scrollY
      }

      document.addEventListener('scroll', debounce(storeScroll), { passive: true })

      storeScroll()

      this.spinner = false
    },
    beforeCreate() {
      this.spinner = true
    },
    created() {
      
    },
    methods: {
      onScroll () {
        const el = document.getElementById('scrolling-techniques-4')
        
        this.scroll = el.scrollTop < window.innerHeight ? true : false
      }
    }
  }
</script>