<template>
  <div class="fill-height pt-12 pb-14">
    <v-layout wrap>
      <v-flex 
        style="min-height: 100vh;">
        <section>
          <v-card v-if="process.load && Object.keys(detail).length < 1"
            flat 
            color="transparent">
            <div class="d-flex justify-center my-6">
              <v-skeleton-loader
                type="avatar">
              </v-skeleton-loader>
            </div>

            <div class="d-flex justify-center mb-6">
              <v-skeleton-loader
                width="200"
                type="text, text">
              </v-skeleton-loader>
            </div>

            <v-skeleton-loader
              type="article, list-item-three-line, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line">
            </v-skeleton-loader>
          </v-card>

          <v-card v-if="!process.load && Object.keys(detail).length > 0"
            flat 
            color="transparent"
            class="pt-6">
            <div v-if="detail.is_request_update > 0"
              class="text-center line-text-second d-flex align-center pa-4"
              style="z-index: 2; 
                    height: 80vh;
                    position: fixed;">
              <div>
                <v-img 
                  contain
                  width="150"
                  height="150"
                  alt="kominfo" 
                  :src="require('@/assets/logo/favicon.png')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        color="#ff64ad">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                
                <div
                  class="text-h5 font-weight-bold mt-6 mb-3">
                  Perubahan Elemen Data
                </div>

                <div
                  class="body-1 font-weight-light mb-7">
                  Pengajuan perubahan elemen data Anda sedang diperiksa oleh Admin kami, mohon untuk menunggu sampai pengajuan Anda selesai diperiksa dan disetujui.
                </div>

                <v-btn
                  rounded
                  color="#ff64ad"
                  class="text-capitalize white--text pl-1 pr-2"
                  style="box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;"
                  to="/home">
                  <v-avatar 
                    color="#ff64ad" 
                    size="25"
                    style="position: absolute; left: 2px;">
                    <v-icon>mdi-arrow-left-thick</v-icon>
                  </v-avatar>

                  <span class="ml-9">
                    kembali ke beranda
                  </span>
                </v-btn>
              </div>
            </div>

            <div :class="detail.is_request_update > 0 ? 'screen_disabled' : ''">
              <v-card-text 
                class="pl-12 pb-0">
                <v-row
                  align="center">
                  <v-col
                    cols="6"
                    class="py-0">
                    <v-avatar 
                      size="130"
                      style="display: inline;">
                      <v-img 
                        style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; 
                              border: 5px solid #fff;"
                        alt="kominfo" 
                        :src="detail.image.length > 0 ? detail.image : require('@/assets/image/member-default.jpg')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#ff64ad">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>
                  </v-col>

                  <v-col
                    cols="6">
                    <div>
                      <div
                        class="body-1 font-weight-bold one-line black--text line-text-second">
                        {{ detail.fullname }}
                      </div>

                      <div
                        class="caption one-line black--text line-text-second"
                        style="opacity: 60%;">
                        {{ detail.email }}
                      </div>

                      <div
                        class="caption one-line black--text line-text-second"
                        style="opacity: 60%;">
                        {{ detail.phone }}
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-img 
                alt="kominfo" 
                :src="require('@/assets/background/bg_center.png')"
                class="ma-auto"
                style="width: 100%; position: relative; top: -25px;">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      indeterminate 
                      color="#ff64ad">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <v-card-text v-if="detail.role_id > 0"
                :class="detail.extra.request_update_status !== '' && detail.extra.request_update_status !== 'DISETUJUI' && ditolak ? 'pb-3' : ''">
                <v-card
                  flat
                  class="bg_group"
                  color="#ff64ad">
                  <v-card-text
                    class="pt-0 pb-2">
                    <v-list 
                      dense 
                      three-line
                      color="transparent" 
                      class="py-0">
                      <v-list-item 
                        class="px-0">
                        <v-list-item-content>
                          <div
                            class="white--text">
                            <div
                              class="body-1 font-weight-bold d-flex align-center">
                              <div>
                                {{ detail.role_level }}
                              </div>

                              <v-spacer />

                              <div
                                style="position: relative; right: -7px;">
                                <v-menu
                                  bottom
                                  left>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      dark
                                      icon
                                      v-bind="attrs"
                                      v-on="on">
                                      <v-icon>mdi-dots-horizontal</v-icon>
                                    </v-btn>
                                  </template>

                                  <v-list
                                    dense
                                    class="py-0">
                                    <v-list-item
                                      @click="configMenu('group', 2)">
                                      <v-list-item-title>
                                        Lihat Group
                                      </v-list-item-title>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </div>
                            </div>

                            <div
                              class="caption mb-2">
                              {{ detail.update_datetime | date }}
                            </div>

                            <div
                              class="line-text-second">
                              <span v-if="detail.role_level === 'Kabupaten / Kota'">
                                Anda telah terdaftar sebagai petugas registrasi {{ detail.role_name }} di wilayah {{ detail.role_level }} {{ detail.role_kabupaten_name }}
                              </span>

                              <span v-if="detail.role_level === 'Kecamatan'">
                                Anda telah terdaftar sebagai petugas registrasi {{ detail.role_name }} di wilayah {{ detail.role_level }} {{ detail.role_kecamatan_name }}
                              </span>

                              <span v-if="detail.role_level === 'Desa / Kelurahan'">
                                Anda telah terdaftar sebagai petugas registrasi {{ detail.role_name }} di wilayah {{ detail.role_level }} {{ detail.role_kelurahan_name }}, Kecamatan {{ detail.role_kecamatan_name }}
                              </span>
                            </div>
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-card-text>

              <v-card-text v-if="detail.extra.request_update_status !== '' && detail.extra.request_update_status !== 'DISETUJUI' && ditolak">
                <v-card
                  flat
                  color="#ffa5001a"
                  style="border: 1px solid orange; border-radius: 10px;">
                  <v-card-text>
                    <div
                      class="body-2 font-weight-bold black--text mb-1 d-flex align-start">
                      <span>
                        Catatan Perubahan Elemen Data:
                      </span>

                      <v-spacer />

                      <v-icon 
                        @click="ditolak = false"
                        color="orange"
                        size="18"
                        class="cursor-pointer"
                        style="position: absolute; right: 5px; top: 5px;">
                        mdi-close-circle-outline
                      </v-icon>
                    </div>

                    <div>
                      <span v-if="detail.extra.request_update_note.length > 0"
                        class="body-2 font-weight-light"
                        v-html="detail.extra.request_update_note.replace(/(<([^>]+)>)/ig, '')">
                      </span>

                      <span v-else
                        class="body-2 font-weight-light">
                        DITOLAK, tidak ada catatan khusus.
                      </span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-card-text>

              <v-card-text
                class="pt-0 mb-2">
                <v-row>
                  <v-col cols="3"
                    v-for="(item, index) in config.menu"
                    :key="index">
                    <v-card  
                      @click="configMenu(item.type, index)"
                      flat
                      class="d-flex align-center justify-center pa-2"
                      height="60"
                      style="border-radius: 10px; box-shadow: rgba(0, 0, 0, 0.01) 0px 3px 5px;">
                      <div>
                        <div class="text-center">
                          <v-img 
                            width="22"
                            height="auto"
                            alt="kominfo" 
                            :src="item.icon"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#ff64ad">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </div>

                        <div 
                          style="font-size: 10px; 
                                opacity: 60%;">
                          {{ item.text }}
                        </div>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>

              <div
                class="px-1"
                style="background: #ffff;
                    border-radius: 30px 30px 0px 0px">
                <v-card-text>
                  <div class="title color-default">
                    Pengaturan
                  </div>

                  <v-list 
                    flat 
                      color="transparent">
                    <v-list-item-group>
                      <template 
                        v-for="(item, index) in config.setting">
                        <v-list-item 
                          @click="configSetting('setting', index)"
                          class="px-0"
                          :key="index"
                          :color="selected.setting === index ? item.color : ''">
                          <v-list-item-icon 
                            class="mr-3">
                            <v-icon 
                              :color="item.color">
                              {{ item.icon }}
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title
                              style="opacity: 60%;">
                              {{ item.text }}
                            </v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-icon 
                              color="#ff64ad">
                              mdi-chevron-right
                            </v-icon>
                          </v-list-item-action>
                        </v-list-item>

                        <v-divider v-if="index < config.setting.length - 1" :key="item.text" />
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-card-text>

                <v-card-text 
                  class="py-0">
                  <div class="title color-default">
                    SAKURA
                  </div>

                  <v-list 
                    flat 
                    color="transparent">
                    <v-list-item-group>
                      <template v-for="(item, index) in config.about">
                        <v-list-item 
                          @click="configSetting('about', index)"
                          :key="index"
                          class="px-0"
                          :color="selected.site === index ? item.color : ''">
                          <v-list-item-icon class="mr-3">
                            <v-icon :color="item.color">
                              {{ item.icon }}
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title
                              style="opacity: 60%;">
                              {{ item.text }}
                            </v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-icon 
                              color="#ff64ad">
                              mdi-chevron-right
                            </v-icon>
                          </v-list-item-action>
                        </v-list-item>

                        <v-divider v-if="index < config.about.length - 1" :key="item.text" />
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-card-text>
              </div>
            </div>
          </v-card>

          <v-bottom-sheet
            v-model="sheet.menu.data"
            hide-overlay
            persistent
            fullscreen
            scrollable>
            <v-card
              flat
              color="#f9f9f9"
              style="border-radius: 0px;">
              <v-card-title 
                @click="configMenu('', null)"
                class="cursor-pointer pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 10;
                      background: #ff64ad;">
                <v-icon 
                  color="white">
                  mdi-arrow-left-thick
                </v-icon>

                <span v-if="sheet.menu.type.length > 0"
                  class="body-1 font-weight-bold text-capitalize white--text ml-1">
                  {{ config.menu[selected.menu].text }}
                </span>
              </v-card-title>

              <v-card-text class="pa-0">
                <Submission v-if="selected.menu === 0"
                  :detail="detail" />

                <Term v-if="selected.menu === 1" />

                <Group v-if="selected.menu === 2"
                  :detail="detail"
                  @close="sheet.config.data = $event; fetch();" />

                <!-- <Inbox v-if="selected.menu === 3" /> -->

                <Bpp v-if="selected.menu === 3"
                  :detail="detail" />
              </v-card-text>
            </v-card>
          </v-bottom-sheet>

          <v-bottom-sheet
            v-model="sheet.config.data"
            hide-overlay
            persistent
            fullscreen
            scrollable>
            <v-card
              flat>
              <v-card-title 
                @click="$route.query.setting === undefined ? configSetting('', null) : toClose()"
                class="cursor-pointer pa-4"
                style="background: #ff64ad; 
                      border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 10;">
                <v-icon color="white">mdi-arrow-left-thick</v-icon>

                <span v-if="sheet.config.type.length > 0"
                  class="body-1 font-weight-bold text-capitalize white--text ml-1">
                  {{ selected.setting !== null ? config.setting[selected.setting].text : config.about[selected.site].text }}
                </span>
              </v-card-title>

              <v-card-text 
                class="pa-0">
                <!-- CONFIG SETTING -->
                <div v-if="sheet.config.type === 'setting'">
                  <General v-if="selected.setting === 0"
                    :detail="detail"
                    @close="sheet.config.data = $event; fetch();" />

                  <Password v-if="selected.setting === 1"
                    @close="sheet.config.data = $event; fetch();" />
                </div>

                <!-- CONFIG ABOUT -->
                <div v-else>
                  <Aboutus v-if="selected.site === 0" />

                  <Contactus v-if="selected.site === 1" />

                  <Privacy v-if="selected.site === 2" />
                  
                  <Faq v-if="selected.site === 3" />
                </div>
              </v-card-text>
            </v-card>
          </v-bottom-sheet>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        ditolak: true,
        sheet: {
          menu: {
            type: '',
            data: false
          },
          config: {
            type: '',
            data: false
          }
        },
        selected: {
          menu: null,
          setting: null,
          site: null
        },
        config: {
          menu: [
            {
              text: 'Ajuan',
              icon: require('@/assets/image/profile/history.png'),
              type: 'submission'
            },
            {
              text: 'Ketentuan',
              icon: require('@/assets/image/profile/shield.png'),
              type: 'term'
            },
            {
              text: 'Group',
              icon: require('@/assets/image/profile/team.png'),
              type: 'group'
            },
            // {
            //   text: 'Pesan',
            //   icon: require('@/assets/image/profile/chat.png'),
            //   type: 'inbox'
            // },
            // {
            //   text: 'BPP',
            //   icon: require('@/assets/image/service/BPP.png'),
            //   type: 'bpp'
            // }
          ],
          setting: [
            {
              text: 'Ubah Profil',
              icon: 'mdi-account-edit-outline',
              color: 'blue'
            },
            {
              text: 'Ubah Password',
              icon: 'mdi-cellphone-key',
              color: 'orange'
            },
            {
              text: 'Keluar',
              icon: 'mdi-logout',
              color: 'red'
            }
          ],
          about: [
            {
              text: 'Tentang Kami',
              icon: 'mdi-information-outline',
              color: 'blue'
            },
            {
              text: 'Kontak Kami',
              icon: 'mdi-phone',
              color: 'green'
            },
            {
              text: 'Kebijakan Privasi',
              icon: 'mdi-shield-key-outline',
              color: 'red'
            },
            {
              text: 'FAQ',
              icon: 'mdi-chat-question-outline',
              color: 'orange'
            }
          ]
        },
        detail: {},
        process: {
          load: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/profile',
          title: 'Profil',
          description: 'Profil Sakura Dukcapil Klaten (Sistem Layanan Administrasi Kependudukan Dalam Jaringan)',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      // MENU
      Submission: () => import(/* webpackPrefetch: true */ '@/components/profile/submission.vue'),
      Term: () => import(/* webpackPrefetch: true */ '@/components/profile/term.vue'),
      Group: () => import(/* webpackPrefetch: true */ '~/components/profile/group.vue'),
      // Inbox: () => import(/* webpackPrefetch: true */ '@/components/profile/inbox.vue'),
      Bpp: () => import(/* webpackPrefetch: true */ '@/components/profile/bpp.vue'),

      // SETTING
      General: () => import(/* webpackPrefetch: true */ '@/components/profile/general.vue'),
      Password: () => import(/* webpackPrefetch: true */ '@/components/profile/password.vue'),

      // SITE
      Aboutus: () => import(/* webpackPrefetch: true */ '@/components/site/aboutus.vue'),
      Contactus: () => import(/* webpackPrefetch: true */ '@/components/site/contactus.vue'),
      Privacy: () => import(/* webpackPrefetch: true */ '@/components/site/privacy.vue'),
      Faq: () => import(/* webpackPrefetch: true */ '@/components/site/faq.vue')
    },
    watch: {

    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/profile`)
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false
            
            this.detail = response.data

            this.selected.menu = this.$route.query.menu === undefined ? null : this.$route.query.menu === 'submission' ? 0 : this.$route.query.menu === 'group' ? 2 : 1
            this.sheet.menu.data = this.$route.query.menu === undefined ? false : true
            this.sheet.menu.type = this.$route.query.menu === undefined ? false : this.$route.query.menu

            if (this.$route.query.setting !== undefined) {
              this.selected.setting = this.$route.query.setting === undefined ? null : this.$route.query.setting === 'setting' ? 0 : null
              this.sheet.config.data = this.$route.query.setting === undefined ? false : true
              this.sheet.config.type = this.$route.query.setting === undefined ? false : this.$route.query.setting
            }

            if (this.detail.level === 'perantara') {
              this.config.menu.push({
                text: 'BPP',
                icon: require('@/assets/image/service/BPP.png'),
                type: 'bpp'
              })
            }
          } 
        })
      },
      configMenu (type, index) {
        this.selected.menu = index

        this.sheet.menu = {
          type: type,
          data: type.length > 0 ? true : false
        }

        this.$router.push({ path: '/profile' })
      },
      configSetting (type, index) {
        if (type === 'setting') {
          this.selected.setting = index

          this.selected.site = null

          if (this.selected.setting === 2) {
            Cookie.remove('token')
            Cookie.remove('user')
            Cookie.remove('fcm')

            Cookie.remove('intro')

            window.location = '/auth/login'
          }
        } else {
          this.selected.setting = null

          this.selected.site = index
        }
        
        if (this.selected.setting !== 2) {
          this.sheet.config = {
            type: type,
            data: type.length > 0 ? true : false
          }
        }

        this.$router.push({ path: '/profile' })
      },
      toClose () {
        this.$router.push({ path: `/submission?id=${this.$route.query.id}` })
      }
    }
  }
</script>