<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex 
        style="height: 100vh;">
        <section>
          <v-img 
            alt="kominfo" 
            :src="require('@/assets/background/bg_top_bottom.png')"
            class="ma-auto"
            style="width: 100%;
                position: absolute;
                top: 0;
                transform: scale(-1, -1);">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  indeterminate 
                  color="#ff64ad">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </section>

        <section>
          <v-row
            align="center"
            style="height: 100vh;">
            <v-col>
              <v-card 
                style="z-index: 1;"
                flat 
                color="transparent">
                <v-card-text>
                  <v-img 
                    width="100"
                    alt="kominfo" 
                    :src="require('@/assets/logo/favicon.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          color="white">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card-text>

                <v-card-text 
                  class="text-h5 font-weight-bold d-flex align-center justify-center black--text mb-4"
                  style="opacity: 60%;">
                  <v-icon color="#ff64ad">mdi-ray-end</v-icon>
                  
                  <span class="mx-2">
                    SAKURA
                  </span>

                  <v-icon color="#ff64ad">mdi-ray-start</v-icon>
                </v-card-text>

                <v-card
                  to="/auth/login"
                  flat
                  color="transparent"
                  class="cursor-pointer d-flex justify-end mb-10">
                  <v-card-text
                    style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px !important;
                          border-radius: 100px 0px 0px 100px;
                          background: #FFFFFF;
                          width: 20%;
                          position: relative;"
                    class="body-1 font-weight-bold color-default text-right pa-3">
                    Login
                  </v-card-text>
                </v-card>

                <v-card-text
                  class="d-flex align-center pa-0">
                  <v-card
                    flat
                    class="py-6 pl-3 pr-16"
                    style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px !important;
                          border-radius: 0px 100px 100px 0px;
                          width: 90%;
                          position: relative;">
                    <ValidationObserver 
                      ref="form">
                      <v-form>
                        <ValidationProvider 
                          name="nik" 
                          rules="required|min:16|max:16" 
                          v-slot="{ errors }">
                          <v-text-field
                            v-on:keyup.enter="save"
                            type="number"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            min="0"
                            dense
                            color="#ff64ad"
                            placeholder="NIK"
                            :counter="16"
                            v-model="form.nik"
                            v-uppercase
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>

                        <ValidationProvider 
                          name="email" 
                          rules="required|email" 
                          v-slot="{ errors }">
                          <v-text-field
                            v-on:keyup.enter="save"
                            dense
                            color="#ff64ad"
                            placeholder="Email"
                            v-model="form.email"
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>

                        <ValidationProvider 
                          name="phone" 
                          rules="required|min:8|max:13"
                          v-slot="{ errors }">
                          <v-text-field
                            v-on:keyup.enter="save"
                            type="number"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            min="0"
                            dense
                            color="#ff64ad"
                            placeholder="Telepon"
                            v-model="form.phone"
                            v-uppercase
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>
                      </v-form>
                    </ValidationObserver>

                    <div v-if="message.error.length > 0"
                      class="text-center my-3">
                      <v-alert
                        type="error" 
                        text 
                        dense 
                        prominent 
                        v-show="message.error.length > 0">
                        {{ message.error }}
                      </v-alert>
                    </div>
                  </v-card>

                  <v-btn
                    fab
                    width="50"
                    height="50"
                    :disabled="process.form"
                    :loading="process.form"
                    v-on:keyup.enter="save"
                    @click="save"
                    elevation="5"
                    class="body-1 font-weight-bold white--text text-capitalize mt-10"
                    style="position: absolute; right: 15px; background: linear-gradient(to right, rgb(255, 100, 173), #ff64ac82);">
                    <v-icon>mdi-check-bold</v-icon>
                  </v-btn>
                </v-card-text>

                <div
                  class="my-9">
                  <recaptcha
                    @error="onError"
                    @success="onSuccess"
                    @expired="onExpired" />
                </div>

                <v-card
                  @click="sheet.info = true"
                  flat
                  class="mt-10"
                  style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px !important;
                        border-radius: 0px 100px 100px 0px;
                        width: 58%;
                        position: relative;">
                  <v-card-text
                    class="body-1 d-flex align-center font-weight-bold color-default pa-3">
                    <v-icon size="18" color="#ff64ad">mdi-information-slab-circle-outline</v-icon>
                    
                    <span
                      class="ml-1">
                      Ketentuan Pendaftaran
                    </span>
                  </v-card-text>
                </v-card>
              </v-card>

              <div
                style="position: absolute;
                      bottom: 20px;
                      right: 0;
                      z-index: 2;">
                <v-btn
                  to="/"
                  color="#FFFFFF60"
                  elevation="0"
                  class="body-1 text-capitalize white--text font-weight-bold"
                  style="border-radius: 50px 0px 0px 50px;">
                  <v-icon>mdi-arrow-left-thick</v-icon>

                  <span class="ml-1">
                    Beranda
                  </span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </section>

        <section>
          <v-img 
            alt="kominfo" 
            :src="require('@/assets/background/bg_top_bottom.png')"
            class="ma-auto"
            style="width: 100%;
                position: absolute;
                bottom: 0;">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  indeterminate 
                  color="#ff64ad">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </section>

        <v-bottom-sheet 
          v-model="sheet.info">
          <v-card
            flat
            style="border-radius: 30px 30px 0px 0px;">
            <v-card-text
              class="pa-4">
              <v-card-text
                class="py-0">
                <v-row 
                  justify="center">
                  <v-col 
                    cols="2"
                    class="pt-2">
                    <v-divider style="border: 5px solid #ff64ad; border-radius: 50px;"/>
                  </v-col>
                </v-row>
              </v-card-text>
              
              <div
                class="body-1 font-weight-bold black--text text-center mb-4">
                Ketentuan
              </div>
              
              <ol
                class="line-text-second black--text"
                style="opacity: 60%;">
                <li
                  v-for="(item, index) in info"
                  :key="index"
                  style="position: relative; left: -10px;">
                  {{ item }}
                </li>
              </ol> 
            </v-card-text>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'notAuthenticated',
    data () {
      return {
        sheet: {
          info: false
        },

        info: [
          'Memiliki KTP Elektronik atau sudah pernah melakukan perekaman biometrik',
          'Satu akun SAKURA hanya bisa digunakan untuk pengajuan dokumen kependudukan bagi dirinya sendiri atau anggota lainnya dalam satu Kartu Keluarga',
          'Swafoto pada saat verifikasi akun dilakukan tanpa menggunakan masker dan kacamata',
          'Elemen data yang dimasukkan saat melakukan registrasi akun harus sesuai denga data yang tertera pada Kartu Keluarga atau KTP Elektronik'
        ],

        form: {
          nik: '',
          email: '',
          phone: ''
        },
        process: {
          form: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/auth/register',
          title: 'Daftar',
          description: 'Daftar Sakura Dukcapil Klaten (Sistem Layanan Administrasi Kependudukan Dalam Jaringan)',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      }
    },
    mounted () {
      
    },
    methods: {
      onError (error) {
        console.log('Error happened:', error)
      },
      onSuccess (token) {
        console.log('Succeeded:', token)

        this.message.error = ''
      },
      onExpired () {
        console.log('Expired')
      },

      async save () {
        this.message.error = ''
        
        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          try {
            const token_recaptcha = await this.$recaptcha.getResponse()

            // SAVE TO REST API
            await this.$axios.$post(`${process.env.PREFIX_PROXY}/auth/register`, this.form)
            .then((response) => {

              if (response.code === 200) {

                this.$router.push({ path: '/auth/check' })

                this.$recaptcha.reset()
                
              } else {
                this.process.form = false
                this.message.error = response.message
              }

            })

            // await this.$recaptcha.reset()
          } catch (error) {
            console.log('Login error:', error)

            this.process.form = false
            this.message.error = 'Masukan captcha google'
          }
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>