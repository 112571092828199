<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card v-if="actions.add"
            @click="actions.info = !actions.info ? true : false"
            flat
            class="d-flex align-center pa-4 mb-4"
            color="white"
            style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; 
                  border-radius: 10px;">
            <div
              class="d-flex align-center font-weight-bold">
              <v-icon 
                size="18"
                :color="actions.info ? 'red' : 'blue'">
                {{ actions.info ? 'mdi-close-circle-outline' : 'mdi-information-outline' }}
              </v-icon>
              
              <span
                :class="actions.info ? 'red--text' : 'blue--text'"
                class="ml-1">
                {{ actions.info ? 'Tutup' : 'Lihat syarat ?' }}
              </span>
            </div>

            <v-spacer />

            <div>
              <v-icon
                :color="actions.info ? 'red' : 'blue'">
                {{ actions.info ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
              </v-icon>
            </div>
          </v-card>

          <div v-if="!actions.add || actions.info">
            <v-card 
              flat
              color="white"
              style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 10px;">
              <div
                class="body-2 black--text font-italic line-text-second pa-4 pb-0"
                style="opacity: 60%;">
                Silakan pelajari ketentuannya, siapkan persyaratannya, lalu klik tombol <strong>+</strong> untuk membuat ajuan baru.
              </div>
              
              <div
                class="body-1 font-weight-bold pa-4 pb-2">
                Persyaratan Pengajuan Kedatangan
              </div>

              <v-divider 
                class="mx-4"
                style="border: 2px solid #f9f9f9; border-radius: 50px;" />

              <v-card-text
                class="pt-3 px-2">
                <span 
                  class="black--text line-text-second"
                  style="opacity: 60%;"
                  v-html="detail.layanan_desc">
                </span>
              </v-card-text>
            </v-card>

            <v-card 
              flat
              color="#ff64ad1a"
              class="mt-4"
              style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 10px;">
              <v-card-title
                class="body-1 font-weight-bold font-italic color-default pb-2">
                #Catatan Penting !!!
              </v-card-title>

              <v-divider 
                class="mx-4"
                style="border: 2px solid #ff64ad; border-radius: 50px;" />

              <v-card-text
                class="pt-3 px-2">
                <span 
                  class="color-default line-text-second"
                  style="opacity: 60%;"
                  v-html="detail.layanan_extra.note">
                </span>
              </v-card-text>
            </v-card>
          </div>

          <div v-if="actions.add && !actions.info">
            <ValidationObserver 
              ref="form">
              <v-form>
                <ValidationProvider 
                  name="pelapor_kk" 
                  rules="required|min:16|max:16" 
                  v-slot="{ errors, valid }">
                  <div class="body-2 font-weight-bold black--text mb-2">
                    KK (Kartu Keluarga)
                  </div>
                  
                  <v-text-field
                    v-on:keyup.enter="save"
                    :disabled="$route.path === '/profile' ? false : true"
                    filled
                    rounded
                    dense
                    color="#ff64ad"
                    type="number"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                    min="0"
                    placeholder="Contoh. 33100062XXXXXXX"
                    :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                    :counter="16"
                    v-model="form.pelapor_kk"
                    v-uppercase
                    :success="valid"
                    :error-messages="errors"
                    required
                    style="border-radius: 10px;">
                  </v-text-field>
                </ValidationProvider>

                <v-btn v-if="$route.path !== '/profile'"
                  to="/profile?setting=setting&id=6"
                  text
                  x-small
                  class="text-capitalize blue--text px-0">
                  Ubah No. KK
                </v-btn>

                <v-divider 
                  class="mt-2 mb-4"
                  style="border: 2px solid #eaeaea; border-radius: 50px;" />

                <ValidationProvider v-if="config.service.layanan_jenis_ajuan.length > 0"
                  name="type" 
                  rules="required" 
                  v-slot="{ errors, valid }">
                  <div class="body-2 mb-2">
                    <span class="red--text">*</span>
                    Jenis Pengajuan
                    <span class="error--text">
                      (wajib diisi)
                    </span>
                  </div>
                  
                  <v-select
                    v-on:keyup.enter="save"
                    filled
                    rounded
                    dense
                    color="#ff64ad"
                    placeholder="Contoh. Perubahan Data"
                    :append-icon="!valid ? '' : 'mdi-check-circle-outline'"
                    
                    :items="config.service.layanan_jenis_ajuan"
                    item-text="ja_judul"
                    item-value="ja_id"
                    item-color="#ff64ad"

                    v-model="form.jenis_ajuan_id"
                    :success="valid"
                    :error-messages="errors"
                    required
                    style="border-radius: 10px;">
                  </v-select>
                </ValidationProvider>

                <ValidationProvider 
                  name="nik" 
                  rules="required|min:16|max:16" 
                  v-slot="{ errors, valid }">
                  <div class="body-2 mb-2">
                    <span class="red--text">*</span>
                    NIK Pemohon
                    <span class="error--text">
                      (wajib diisi)
                    </span>
                  </div>
                  
                  <v-text-field
                    v-on:keyup.enter="save"
                    filled
                    rounded
                    dense
                    color="#ff64ad"
                    type="number"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                    min="0"
                    placeholder="Contoh. 33100062XXXXXXX"
                    :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                    :counter="16"
                    v-model="form.nik"
                    v-uppercase
                    :success="valid"
                    :error-messages="errors"
                    required
                    style="border-radius: 10px;">
                  </v-text-field>
                </ValidationProvider>

                <ValidationProvider 
                  name="no_pindah" 
                  rules="required" 
                  v-slot="{ errors, valid }">
                  <div class="body-2 mb-2">
                    <span class="red--text">*</span>
                    Nomor Surat Ket Pindah
                    <span class="error--text">
                      (wajib diisi)
                    </span>
                  </div>
                  
                  <v-text-field
                    v-on:keyup.enter="save"
                    filled
                    rounded
                    dense
                    color="#ff64ad"
                    placeholder="Contoh. SKPWNI/1234/XXXXXX/XXX"
                    :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                    v-model="form.no_pindah"
                    v-uppercase
                    :success="valid"
                    :error-messages="errors"
                    required
                    style="border-radius: 10px;">
                  </v-text-field>
                </ValidationProvider>

                <ValidationProvider 
                  name="nama_lengkap" 
                  rules="required" 
                  v-slot="{ errors, valid }">
                  <div class="body-2 mb-2">
                    <span class="red--text">*</span>
                    Nama Lengkap Pemohon
                    <span class="error--text">
                      (wajib diisi)
                    </span>
                  </div>
                  
                  <v-text-field
                    v-on:keyup.enter="save"
                    filled
                    rounded
                    dense
                    color="#ff64ad"
                    placeholder="Contoh. Fabdulrafi"
                    :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                    v-model="form.nama_lengkap"
                    v-uppercase
                    :success="valid"
                    :error-messages="errors"
                    required
                    style="border-radius: 10px;">
                  </v-text-field>
                </ValidationProvider>

                <ValidationProvider 
                  name="kecamatan_code" 
                  rules="required" 
                  v-slot="{ errors, valid }">
                  <div class="body-2 mb-2">
                    <span class="red--text">*</span>
                    Kecamatan
                    <span class="error--text">
                      (wajib diisi)
                    </span>
                  </div>

                  <v-autocomplete
                    filled
                    rounded
                    dense
                    color="#ff64ad"
                    placeholder="Contoh. Trucuk"
                    :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                    :search-input.sync="autocomplete.subdistrict"
                    :loading="process.subdistrict"

                    :disabled="user.role_level === 'Kecamatan' || user.role_level === 'Desa / Kelurahan'"
                    
                    :items="config.subdistrict"
                    item-value="kecamatan_code"
                    item-text="kecamatan_name"
                    item-color="#ff64ad"

                    v-model="form.kecamatan_code"
                    :success="valid"
                    :error-messages="errors"
                    required
                    style="border-radius: 10px;">
                  </v-autocomplete>
                </ValidationProvider>

                <ValidationProvider 
                  name="kelurahan_code" 
                  rules="required" 
                  v-slot="{ errors, valid }">
                  <div class="body-2 mb-2">
                    <span class="red--text">*</span>
                    Kelurahan
                    <span class="error--text">
                      (wajib diisi)
                    </span>
                  </div>

                  <v-autocomplete
                    filled
                    rounded
                    dense
                    color="#ff64ad"
                    placeholder="Contoh. Kradenan"
                    :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                    :search-input.sync="autocomplete.village"
                    :loading="process.village"

                    :disabled="user.role_level === 'Desa / Kelurahan'"
                    
                    :items="config.village"
                    item-value="desa_code"
                    item-text="desa_name"
                    item-color="#ff64ad"

                    v-model="form.kelurahan_code"
                    :success="valid"
                    :error-messages="errors"
                    required
                    style="border-radius: 10px;">
                  </v-autocomplete>
                </ValidationProvider>

                <div>
                  <div class="body-2 mb-2">
                    Alasan
                    <span>
                      (bisa dikosongi)
                    </span>
                  </div>

                  <v-textarea
                    v-on:keyup.enter="save"
                    filled
                    rounded
                    dense
                    rows="3"
                    color="#ff64ad"
                    placeholder="Contoh.Perubahan nama dan foto"
                    :append-icon="form.keterangan.length < 1 ? '' : 'mdi-check-circle-outline'"

                    v-model="form.keterangan"
                    v-uppercase
                    :success="form.keterangan.length > 0"
                    style="border-radius: 10px;">
                  </v-textarea>
                </div>

                <v-card
                  v-for="(item, index) in form.data_ajuan.data_dukung"
                  :key="index"
                  flat
                  color="white"
                  class="mb-6"
                  style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 10px;">
                  <v-card-title
                    class="body-2 pb-2">
                    <span class="red--text" v-if="item.require">*</span>

                    <span style="opacity: 60%;">
                      {{ item.name }} 
                    </span>
                    
                    <span class="error--text ml-1" v-if="item.require">
                      (wajib diisi)
                    </span>
                  </v-card-title>

                  <v-divider 
                    class="mx-4"
                    style="border: 2px solid #f9f9f9; border-radius: 50px;" />

                  <v-card-text
                    class="py-4">
                    <v-row>
                      <v-col 
                        v-for="(item_img, index_img) in item.file"
                        :key="index_img"
                        cols="6" 
                        md="4">
                        <v-card 
                          flat
                          height="136"
                          class="d-flex align-center justify-center"
                          style="border-radius: 15px;
                                border: 2px solid #eaeaea; 
                                border-style: dashed;
                                box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; ">
                          <v-img 
                            alt="kominfo" 
                            width="100%"
                            height="136"
                            :src="item_img.url"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#ff64ad">
                                </v-progress-circular>
                              </v-row>
                            </template>

                            <div @click="item.file.splice(index_img, 1)"
                              class="cursor-pointer"
                              style="position: absolute; top: 5px; right: 5px;">
                              <v-icon size="20" color="red">mdi-close-circle</v-icon>
                            </div>
                          </v-img>
                        </v-card>
                      </v-col>

                      <v-col 
                        cols="6"
                        md="4">
                        <v-card @click="sheet.option = true; index_file = index;"
                          flat
                          height="136"
                          class="d-flex align-center justify-center"
                          style="border-radius: 15px;
                                border: 2px solid #eaeaea; 
                                border-style: dashed;">
                          <v-icon v-if="!process.file || index_file !== index"
                            size="50" 
                            color="grey">
                            mdi-cloud-upload-outline
                          </v-icon>

                          <v-progress-circular v-if="process.file && index_file === index"
                            indeterminate
                            color="#ff64ad">
                          </v-progress-circular>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-form>
            </ValidationObserver>

            <div v-if="message.error.length > 0"
              class="text-center my-3">
              <v-alert
                type="error" 
                text 
                dense 
                prominent 
                v-show="message.error.length > 0">
                {{ message.error }}
              </v-alert>
            </div>

            <v-row>
              <v-col 
                cols="6" 
                class="py-0">
                <v-btn
                  :disabled="process.form"
                  :loading="process.form"
                  v-on:keyup.enter="save"
                  @click="save"
                  block
                  color="green"
                  class="text-capitalize white--text pl-1 pr-2"
                  style="border-radius: 10px; box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;">
                  <v-avatar 
                    size="25"
                    style="position: absolute; left: 2px;">
                    <v-icon small>mdi-check-bold</v-icon>
                  </v-avatar>

                  <span class="ml-6">
                    Simpan
                  </span>
                </v-btn>
              </v-col>

              <v-col 
                cols="6" 
                class="py-0">
                <v-btn 
                  @click="actions.add = false; actions.info = false"
                  :disabled="process.form"
                  block
                  color="red"
                  class="text-capitalize white--text pl-1 pr-2"
                  style="border-radius: 10px; box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;">
                  <v-avatar 
                    size="25"
                    style="position: absolute; left: 2px;">
                    <v-icon small>mdi-close-thick</v-icon>
                  </v-avatar>

                  <span class="ml-6">
                    Batal
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </div>

          <!-- <v-fab-transition v-if="!actions.add">
            <v-btn
              @click="actions.add = true"
              color="#ff64ad"
              elevation="5"
              fixed
              dark
              bottom
              right
              fab>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-fab-transition> -->

          <v-card-text v-if="!actions.add"
            class="d-flex justify-end pa-0">
            <div
              style="position: fixed;"
              :style="$route.path === '/profile' ? 'bottom: 15px;' : 'bottom: 75px;'">
              <v-btn
                @click="fetchCheck()"
                :loading="process.check"
                :disabled="process.check"
                color="#ff64ad"
                elevation="5"
                class="white--text"
                fab>
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-card-text>

          <!-- <v-card-text v-if="!actions.add"
            class="d-flex justify-end pa-0">
            <div
              style="position: fixed;"
              :style="$route.path === '/profile' ? 'bottom: 15px;' : 'bottom: 75px;'">
              <v-btn
                @click="actions.add = true"
                color="#ff64ad"
                elevation="5"
                dark
                fab>
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-card-text> -->

          <v-bottom-sheet
            v-model="sheet.option"
            hide-overlay>
            <v-card
              flat
              color="#fff"
              class="d-flex align-center"
              style="border-radius: 30px 30px 0px 0px;">
              <v-card-text
                class="pa-3">
                <v-row 
                  align="center" 
                  justify="center">
                  <!-- CAMERA -->
                  <v-col cols="4" class="text-center">
                    <div @click="sheet.upload = true; selected.type = 'camera';"
                      class="cursor-pointer">
                      <v-avatar 
                        size="50" 
                        class="cursor-pointer" 
                        v-ripple
                        style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;">
                        <v-img 
                          alt="kominfo" 
                          :src="require('@/assets/image/camera.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#ff64ad">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-avatar>

                      <div class="font-weight-bold black--text mt-2">
                        Kamera
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="4" class="text-center px-0">
                    <div class="d-flex align-center">
                      <v-divider style="border: 2px solid #eaeaea; border-radius: 50px;" />
                      <div class="mx-2">
                        Atau
                      </div>
                      <v-divider style="border: 2px solid #eaeaea; border-radius: 50px;" />
                    </div>
                  </v-col>

                  <!-- FOLDER -->
                  <v-col 
                    cols="4" 
                    class="text-center">
                    <upload-folder 
                      v-model="upload.folder">
                      <div slot="activator">
                        <v-avatar 
                          size="50" 
                          class="cursor-pointer" 
                          v-ripple
                          style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;">
                          <v-img 
                            alt="kominfo" 
                            :src="require('@/assets/image/folder.png')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#ff64ad">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>

                        <div class="font-weight-bold black--text mt-2">
                          Galeri
                        </div>
                      </div>
                    </upload-folder>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-bottom-sheet>

          <v-bottom-sheet
            v-model="sheet.upload"
            hide-overlay
            persistent>
            <upload-camera 
              v-if="selected.type === 'camera'"
              :sheet="sheet.upload"
              @frame="sheet.upload = $event"
              @input="addCamera($event)"
              @close="sheet.upload = $event; selected.type = '';" />
          </v-bottom-sheet>

          <v-dialog 
            v-model="dialog.check"
            persistent>
            <v-card 
              class="border-radius pa-2">
              <v-card-title class="d-flex align-center py-0 px-3 text-right popup-header">
                <v-avatar size="35">
                  <v-img
                    contain
                    width="30"
                    height="30"
                    alt="kominfo"
                    :src="require('@/assets/logo/favicon.png')">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          color="#ff64ad">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>

                <span class="body-1 font-weight-bold mt-1 ml-1">
                  Cek Ajuan
                </span>
              </v-card-title>

              <v-divider class="mx-4 mt-3 mb-2"/>

              <v-card-text class="body-2 font-weight-light text-first line-text-second text-center mt-3">
                <v-img
                  contain
                  width="100"
                  height="100"
                  alt="kominfo"
                  class="ma-auto"
                  :src="require(`@/assets/image/service/${detail.layanan_kode}.png`)">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        color="#ff64ad">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <div
                  class="body-2 font-weight-bold black--text my-2">
                  {{ detail.layanan_nama }}
                </div>
                
                <div>
                  {{ message.check }}
                </div>
              </v-card-text>

              <v-divider class="mx-4 mb-3 mt-0"/>

              <v-card-actions>
                <v-spacer/>

                <v-btn
                  @click="dialog.check = false"
                  color="red"
                  elevation="0"
                  class="text-capitalize white--text"
                  style="border-radius: 15px; box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;">
                  <v-icon small class="mr-1">mdi-close-thick</v-icon>
                  Tutup
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        dialog: {
          check: false
        },
        users: {},
        selected: {
          type: ''
        },
        sheet: {
          option: false,
          upload: false
        },
        upload: {
          camera: null,
          folder: null
        },
        actions: {
          add: false,
          info: false
        },
        config: {
          service: {},
          support: [],

          // ADDRESS
          subdistrict: [],
          village: []
        },
        index_file: '',
        autocomplete: {
          subdistrict: '',
          village: ''
        },
        form: {
          pelapor_kk: '',
          layanan_kode: '',
          jenis_ajuan_id: '',
          is_mandiri: 1,
          is_online: 1,
          nik: '',
          no_pindah: '',
          nama_lengkap: '',
          keterangan: '',
          data_ajuan: {
            code: '',
            field : {
              nik : '',
              no_pindah: '',
              nama_lengkap : '',

              // ADDRESS
              kecamatan_code: '',
              kecamatan_name: '',
              kelurahan_code: '',
              kelurahan_name: ''
            },
            data_dukung : []
          },

          // ADDRESS
          kecamatan_code: '',
          kecamatan_name: '',
          kelurahan_code: '',
          kelurahan_name: ''
        },
        process: {
          form: false,
          file: false,

          // ADDRESS
          subdistrict: false,
          village: false,

          check: false
        },
        message: {
          success: '',
          error: '',
          check: ''
        }
      }
    },
    components: {
      UploadCamera: () => import(/* webpackPrefetch: true */ '@/components/upload/camera.vue'),
      UploadFolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue')
    },
    watch: {
      'upload.folder': {
        handler: function () {
          if (this.upload.folder) {
            this.process.file = this.upload.folder.process

            if (!this.process.file) {
              // CALLBACK FROM UPLOAD IMAGE
              this.sheet.option = false

              this.form.data_ajuan.data_dukung[this.index_file].file.push({
                url: this.upload.folder.data.full.file_url,
                type: this.upload.folder.type,
                process: this.upload.folder.process,
                user_id: this.user.id,
                file_id: this.upload.folder.id === undefined ? 0 : this.upload.folder.id
              })
            }
          }
        },
        deep: true
      },
      'form.kecamatan_code': function() {
        let t = this
        let kec = this.config.subdistrict.filter(function(v) {
          return t.form.kecamatan_code == v.kecamatan_code
        })

        if (kec.length > 0) {
          this.form.kecamatan_name = kec[0].kecamatan_name

          this.form.kelurahan_code = ''
          this.form.kelurahan_name = ''
        }

        this.static().village()
      },
      'form.kelurahan_code': function() {
        let t = this
        let kel = this.config.village.filter(function(v) {
          return t.form.kelurahan_code == v.desa_code
        })

        if (kel.length > 0) {
          this.form.kelurahan_name = kel[0].desa_name
        }
      },
      'autocomplete.subdistrict': function() {
        this.static().subdistrict()
      },
      'autocomplete.village': function() {
        this.static().village()
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      user() {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      this.$axios.$get(`${process.env.PREFIX_PROXY}/profile`)
      .then((response) => {
        if (response.code === 200) {
          this.users = response.data

          this.fetch()
        } 
      })
    },
    methods: {
      fetch () {
        this.fetchService()
        this.fetchSupport()
      },
      async fetchService () {
        let params = {
          id: this.detail.layanan_id
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/layanan/detail`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.config.service = response.data
          } 
        })
      },
      async fetchSupport () {
        let params = {
          mdadu_layanan_kode: this.detail.layanan_kode,
          order_by: 'DESC',
          sort: 'mdadu_is_required'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/master_data_dukung`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.config.support = response.data

            this.reset()
          } 
        })
      },
      static () {
        let t = this
        return {
          async subdistrict () {
            t.process.subdistrict = true

            let params = {
              search: t.autocomplete.subdistrict,
              sort: 'DESC'
            }

            await t.$axios.$get(`${process.env.PREFIX_MASTER}/location/kecamatan`, { params })
            .then(response => {
              if (response.code == 200) {
                t.process.subdistrict = false

                t.config.subdistrict = response.data
              }
            })
          },
          async village () {
            t.process.village = true

            let params = {
              desa_kecamatan_code: t.form.kecamatan_code,
              search: t.autocomplete.village,
              sort: 'DESC'
            }

            await t.$axios.$get(`${process.env.PREFIX_MASTER}/location/desa`, { params })
            .then(response => {
              if (response.code == 200) {
                t.process.village = false

                t.config.village = response.data
              }
            })
          }
        }
      },
      reset () {
        if (this.$refs.form) this.$refs.form.reset()
        
        this.form = {
          pelapor_kk: this.$route.path === '/profile' ? '' : this.user.kk,
          layanan_kode: this.detail.layanan_kode,
          jenis_ajuan_id: '',
          is_mandiri: this.$route.path === '/profile' ? 0 : 1,
          is_online: 1,
          nik: '',
          no_pindah: '',
          nama_lengkap: '',
          keterangan: '',
          data_ajuan: {
            code: '',
            field : {
              nik : '',
              no_pindah: '',
              nama_lengkap : '',

              // ADDRESS
              // kecamatan_code: this.user.kecamatan_code,
              // kecamatan_name: this.user.kecamatan_name,
              // kelurahan_code: this.user.kelurahan_code,
              // kelurahan_name: this.user.kelurahan_name

              kecamatan_code: this.$route.path === '/profile' && this.users.role_kecamatan_code !== undefined ? this.users.role_kecamatan_code : this.user.kecamatan_code,
              kecamatan_name: this.$route.path === '/profile' && this.users.role_kecamatan_name !== undefined ? this.users.role_kecamatan_name : this.user.kecamatan_name,
              kelurahan_code: this.$route.path === '/profile' && this.users.role_kelurahan_code !== undefined ? this.users.role_kelurahan_code : this.user.kelurahan_code,
              kelurahan_name: this.$route.path === '/profile' && this.users.role_kelurahan_name !== undefined ? this.users.role_kelurahan_name : this.user.kelurahan_name
            },
            data_dukung : []
          },

          // ADDRESS
          // kecamatan_code: this.user.kecamatan_code,
          // kecamatan_name: this.user.kecamatan_name,
          // kelurahan_code: this.user.kelurahan_code,
          // kelurahan_name: this.user.kelurahan_name

          kecamatan_code: this.$route.path === '/profile' && this.users.role_kecamatan_code !== undefined ? this.users.role_kecamatan_code : this.user.kecamatan_code,
          kecamatan_name: this.$route.path === '/profile' && this.users.role_kecamatan_name !== undefined ? this.users.role_kecamatan_name : this.user.kecamatan_name,
          kelurahan_code: this.$route.path === '/profile' && this.users.role_kelurahan_code !== undefined ? this.users.role_kelurahan_code : this.user.kelurahan_code,
          kelurahan_name: this.$route.path === '/profile' && this.users.role_kelurahan_name !== undefined ? this.users.role_kelurahan_name : this.user.kelurahan_name
        }

        if (this.config.support.length > 0) {
          this.config.support.map(obj => {
            this.form.data_ajuan.data_dukung.push({
              id: obj.mdadu_id,
              name: obj.mdadu_judul,
              require: obj.mdadu_is_required,
              file: []
            })
          })
        }
        
        this.process.form = false

        this.message.error = ''
      },
      addCamera (e) {
        this.sheet.option = false

        this.process.file = e.process

        if (!e.process) {
          this.form.data_ajuan.data_dukung[this.index_file].file.push({
            url: e.data.full.file_url,
            type: e.data.type,
            process: e.data.process,
            user_id: this.user.id,
            file_id: e.data.id === undefined ? 0 : e.data.id
          })

          this.selected.type = ''
        } 
      },
      async save() {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          this.form.data_ajuan.code = this.form.layanan_kode
          this.form.data_ajuan.field.nik = this.form.nik
          this.form.data_ajuan.field.no_pindah = this.form.no_pindah
          this.form.data_ajuan.field.nama_lengkap = this.form.nama_lengkap
          this.form.data_ajuan.field.kecamatan_code = this.form.kecamatan_code
          this.form.data_ajuan.field.kecamatan_name = this.form.kecamatan_name
          this.form.data_ajuan.field.kelurahan_code = this.form.kelurahan_code
          this.form.data_ajuan.field.kelurahan_name = this.form.kelurahan_name

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.PREFIX_PROXY}/ajuan/${this.form.layanan_kode}/create`, this.form)
          .then((response) => {

            if (response.code === 200) {
              if (this.$route.path === '/profile') {
                window.location = `/profile?menu=submission&code=${this.form.layanan_kode}`
              } else {
                this.$router.push({ path: `/profile?menu=submission&code=${this.form.layanan_kode}` })
              }
            } else {
              this.process.form = false
              this.message.error = response.message
            }

          })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },

      async fetchCheck () {
        this.process.check = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/ajuan/${this.detail.layanan_kode}/create_check?is_mandiri=0`)
        .then((response) => {
          this.process.check = false

          if (response.code === 200) {
            if (response.message_code === 'SUCCESS') {
              this.actions.add = true
            } else {
              this.dialog.check = true
            }

            this.message.check = response.message
          }
        })
      }
    }
  }
</script>

<style scoped>
  /* .v-btn--absolute.v-btn--bottom, .v-btn--fixed.v-btn--bottom {
    bottom: 75px !important;
  } */
</style>