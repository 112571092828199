<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            color="transparent"
            min-height="103.5vh">
            <v-card
              flat
              color="#ff64ad"
              class="pt-12 pb-4"
              style="border-radius: 0px 0px 15px 15px;">
              <v-card-title
                class="white--text pb-1">
                #Produk {{ detail.prod_layanan_kode }}
              </v-card-title>

              <v-card-text
                class="white--text line-text-second pb-1">
                <v-divider 
                  class="mb-3"
                  style="border: 2px solid #fff; border-radius: 50px;" />
                <div>
                  No. Reg: {{ detail.prod_ajuan_no_reg }}
                </div>

                <div>
                  Dari Ajuan: {{ detail.prod_from_layanan_kode }}
                </div>

                <div>
                  <!-- Nomor (KK/NIK/DLL): {{ detail.prod_nomor }} -->
                  Nomor Identitas: {{ detail.prod_nomor }}
                </div>

                <div>
                  Status: {{ detail.prod_status }}
                </div>

                <v-divider 
                  class="my-3"
                  style="border: 2px solid #fff; border-radius: 50px;" />

                <div
                  class="caption font-weight-bold d-flex align-center mt-4"
                  :class="detail.prod_status === 'SIAP DIDOWNLOAD' ? 'mb-4' : ''">
                  <div
                    class="d-flex align-center">
                    <v-icon small color="white" class="mr-1">mdi-calendar-month</v-icon>

                    {{ detail.prod_create_datetime | date }}
                  </div>

                  <v-spacer />

                  <div
                    class="d-flex align-center">
                    <v-icon small color="white" class="mr-1">mdi-clock-time-three-outline</v-icon>

                    {{ detail.prod_create_datetime | time }}
                  </div>
                </div>

                <v-btn v-if="detail.prod_status === 'SIAP DIDOWNLOAD'"
                  :href="detail.prod_url"
                  target="_blank"
                  block
                  elevation="0"
                  color="orange"
                  class="font-weight-bold mt-5 white--text text-capitalize"
                  style="border-radius: 10px;">
                  Download File TTE
                </v-btn>
              </v-card-text>
            </v-card>

            <v-card-text
              class="py-0 pl-0">
              <v-timeline
                align-center
                dense>
                <v-skeleton-loader
                  v-for="(item, index) in process.load ? 7 : list"
                  :key="index"
                  :loading="process.load"
                  type="list-item-avatar-three-line">
                  <div>
                    <div v-if="!process.load && list.length > 0">
                      <v-timeline-item
                        :color="item.log_status === 'DIAJUKAN TTE' ? 'cyan' :
                                item.log_status === 'SIAP DICETAK' ? 'blue' :
                                item.log_status === 'SUDAH DICETAK' ? 'indigo' :
                                item.log_status === 'SIAP DIAMBIL' ? 'lime' :
                                item.log_status === 'SUDAH DIAMBIL' ? 'purple' :
                                item.log_status === 'SIAP DIDOWNLOAD' ? 'green' : 'red'"
                        small>
                        <v-row
                          class="cursor-pointer">
                          <v-col 
                            cols="3">
                            <strong
                              class="black--text"
                              style="opacity: 60%;">
                              {{ item.log_create_datetime | time }}
                            </strong>
                          </v-col>

                          <v-col>
                            <strong
                              :class="item.log_status === 'DIAJUKAN TTE' ? 'cyan--text' :
                                      item.log_status === 'SIAP DICETAK' ? 'blue--text' :
                                      item.log_status === 'SUDAH DICETAK' ? 'indigo--text' :
                                      item.log_status === 'SIAP DIAMBIL' ? 'lime--text' :
                                      item.log_status === 'SUDAH DIAMBIL' ? 'purple--text' :
                                      item.log_status === 'SIAP DIDOWNLOAD' ? 'green--text' : 'red--text'">
                              {{ item.log_status }}
                            </strong>

                            <div
                              class="caption font-weight-light five-line line-text-second">
                              <span
                                v-html="item.log_note">
                              </span>
                            </div>

                            <div 
                              class="caption font-weight-bold black--text"
                              style="opacity: 60%;">
                              {{ item.log_create_datetime | date }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-timeline-item>
                    </div>
                  </div>
                </v-skeleton-loader>
              </v-timeline>
            </v-card-text>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        detail: {},
        list: [],
        pagination: {},
        process: {
          load: false,
          log: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/product/:id',
          title: 'Riwayat Produk',
          description: 'Riwayat Produk Sakura Dukcapil Klaten (Sistem Layanan Administrasi Kependudukan Dalam Jaringan)',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      
    },
    watch: {
      
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      user() {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      this.fetchDetail()
    },
    methods: {
      async fetchDetail () {
        this.process.load = true

        let params = {
          id: this.$route.query.prod_id
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/produk/${this.$route.query.code}/detail`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false
            
            this.detail = response.data

            this.fetchLog()
          }
        })
      },
      async fetchLog () {
        this.process.log = true

        let params = {
          log_ajuan_id: this.$route.params.id,
          log_produk_id: this.$route.query.prod_id,
          order_by: 'DESC'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/produk/${this.$route.query.code}/log`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.log = false
            
            this.list = response.data
            this.pagination = response.pagination
          }
        })
      }
    }
  }
</script>

<style scoped>
  .v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse):before {
    min-height: calc(100vh - 249px);
  }
</style>