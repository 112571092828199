<template>
  <div class="fill-height pb-13">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card 
            flat 
            color="transparent">
            <v-card
              flat
              color="#ff64ad"
              class="pt-12"
              style="border-radius: 0px 0px 30px 30px;">
              <v-card-text>
                <v-row 
                  align="center">
                  <v-col 
                    cols="7" 
                    class="white--text pr-0">
                    <div 
                      class="title mb-2">
                      #DirumahAja masih bisa pengajuan
                    </div>

                    <div 
                      class="body-2 white--text font-weight-light black--text line-text-second">
                      Ajukan perubahan data dengan layanan yang kami sediakan untuk Anda.
                    </div>
                  </v-col>

                  <v-col 
                    cols="5">
                    <v-img 
                      alt="kominfo" 
                      :src="require('@/assets/image/service.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="white">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card-title
              class="title pt-6 black--text line-text-second">
              Layanan Kami
            </v-card-title>
            
            <v-card-subtitle 
              class="body-2 font-weight-light pb-0">
              Sistem Administrasi Kependudukan
            </v-card-subtitle>

            <v-card-text
              class="pt-2">
              <v-row 
                justify="center">
                <v-col 
                  cols="4"
                  v-for="(item, index) in process.load ? 12 : list"
                  :key="index">
                  <v-skeleton-loader
                    :loading="process.load"
                    type="avatar"
                    tile>
                    <div>
                      <v-card v-if="!process.load && list.length > 0"
                        :to="`/submission?id=${index}`"
                        flat
                        color="white"
                        class="pa-2"
                        style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
                              border-radius: 10px;">
                        <v-img 
                          width="40"
                          height="auto"
                          alt="kominfo" 
                          :src="item.layanan_image"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#ff64ad">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>

                        <!-- <div 
                          class="text-center font-weight-bold mt-1"
                          style="font-size: 10px; opacity: 60%;">
                          {{ item.layanan_kode }}
                        </div> -->

                        <div 
                          class="text-center font-weight-bold mt-3"
                          style="font-size: 10px; 
                                opacity: 60%;
                                line-height: 1.2;">
                          {{ item.layanan_kode }}
                        </div>

                        <div 
                          class="text-center one-line"
                          style="font-size: 8px; opacity: 40%;">
                          ({{ item.layanan_nama }})
                        </div>
                      </v-card>
                    </div>
                  </v-skeleton-loader>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        list: [],
        process: {
          load: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/service',
          title: 'Layanan',
          description: 'Layanan Sakura Dukcapil Klaten (Sistem Layanan Administrasi Kependudukan Dalam Jaringan)',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        let params = {
          sort: 'DESC'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/layanan`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false
            
            this.list = response.data
          } 
        })
      }
    }
  }
</script>