<template>
  <div>
    <v-row justify="center" align="center" :class="margin">
      <v-col cols="12" class="text-center" :class="margin">
        <v-img 
          class="mx-auto"
          contain
          alt="kominfo"
          :width="size"
          :height="size"
          :src="require('@/assets/image/empty.png')">
          <template v-slot:placeholder>
            <v-row
              class="fill-height"
              align="center"
              justify="center">
              <v-progress-circular 
                indeterminate 
                color="#ff64ad">
              </v-progress-circular>
            </v-row>
          </template>
        </v-img>

        <div class="body-1 font-weight-light text-second my-6">
          Belum ada data <b>{{ message }}</b>!!!
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    props: {
      margin: {
        type: String,
        default: 'my-16'
      },
      size: {
        type: String,
        default: 150
      },
      message: {
        type: String,
        default: ''
      }
    }
  }
</script>