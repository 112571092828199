<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex 
        style="min-height: 100vh;">
        <section>
          <v-card v-if="process.load"
            flat 
            color="transparent"
            class="py-3">
            <v-card-text
              class="text-center">
              <v-skeleton-loader
                type="list-item-avatar-three-line, list-item-three-line, list-item-two-line, list-item-three-line, list-item-two-line, list-item-three-line, list-item-two-line, list-item-three-line, actions">
              </v-skeleton-loader>
            </v-card-text>
          </v-card>

          <v-card v-else
            flat 
            color="transparent"
            class="pt-4">
            <v-card-text 
              class="pl-12 pb-0">
              <v-row
                align="center">
                <v-col
                  cols="6"
                  class="py-0">
                  <v-avatar 
                    size="130"
                    style="display: inline;">
                    <v-img 
                      style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; 
                            border: 5px solid #fff;"
                      alt="kominfo" 
                      :src="form.image.length < 1 ? require('@/assets/image/member-default.jpg') : form.image"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="#ff64ad">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    
                    <v-avatar
                      @click="sheet.upload = true; type = 'camera'"
                      style="
                          position: absolute; 
                          bottom: 7px; 
                          right: 7px;
                          z-index: 9;"
                      color="#ff64ad"
                      size="40">
                      <v-progress-circular v-if="process.image"
                        indeterminate
                        :size="25"
                        color="white">
                      </v-progress-circular>

                      <v-icon v-else
                        color="white">
                        mdi-camera
                      </v-icon>
                    </v-avatar>
                  </v-avatar>

                  <upload-folder 
                    v-model="upload.folder">
                    <div slot="activator">
                      <v-avatar 
                        size="50" 
                        class="cursor-pointer" 
                        v-ripple
                        style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;">
                        <v-img 
                          alt="kominfo" 
                          :src="require('@/assets/image/folder.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#ff64ad">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-avatar>

                      <div class="font-weight-bold black--text mt-2">
                        Galeri
                      </div>
                    </div>
                  </upload-folder>
                </v-col>

                <v-col
                  cols="6"
                  class="py-0">
                  <div 
                    class="red--text font-italic caption">
                    *Swafoto wajib menggunakan wajah.
                  </div>
                </v-col>
              </v-row>
            </v-card-text>


            <v-img 
              alt="kominfo" 
              :src="require('@/assets/background/bg_center.png')"
              class="ma-auto"
              style="width: 100%; position: relative; top: -25px;">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular 
                    indeterminate 
                    color="#ff64ad">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>

            <v-card-text
              class="mb-16 pb-16">
              <div class="mb-8">
                <ValidationObserver 
                  ref="form">
                  <v-form>
                    <ValidationProvider 
                      name="fullname" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Nama Lengkap
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. Firmansyah Abdul Rafi"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form.fullname"
                        v-uppercase
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="kk" 
                      rules="required|min:16|max:16" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        KK
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        type="number"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        min="0"
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. 33100062XXXXXXX"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        :counter="16"
                        v-model="form.kk"
                        v-uppercase
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="nik" 
                      rules="required|min:16|max:16" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        NIK
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        type="number"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        min="0"
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. 33100062XXXXXXX"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        :counter="16"
                        v-model="form.nik"
                        v-uppercase
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="phone" 
                      rules="required|min:8|max:13"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Telepon
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        type="number"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        min="0"
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. 081332XXXXXX"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form.phone"
                        v-uppercase
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="email" 
                      rules="required|email" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Email
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. XXXXX@gmail.com"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form.email"
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="kecamatan_code" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Kecamatan
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-autocomplete
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. Trucuk"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        :search-input.sync="autocomplete.subdistrict"
                        :loading="process.subdistrict"
                        
                        :items="config.subdistrict"
                        item-value="kecamatan_code"
                        item-text="kecamatan_name"
                        item-color="#ff64ad"

                        v-model="form.kecamatan_code"
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-autocomplete>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="kelurahan_code" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Kelurahan
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-autocomplete
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. Kradenan"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        :search-input.sync="autocomplete.village"
                        :loading="process.village"
                        
                        :items="config.village"
                        item-value="desa_code"
                        item-text="desa_name"
                        item-color="#ff64ad"

                        v-model="form.kelurahan_code"
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-autocomplete>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="dukuh" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Dukuh / Dusun
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. Kemiri"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form.dukuh"
                        v-uppercase
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider>

                    <v-row>
                      <v-col
                        cols="6"
                        class="py-0">
                        <ValidationProvider 
                          name="rt" 
                          rules="required" 
                          v-slot="{ errors, valid }">
                          <div class="body-2 mb-2">
                            <span class="red--text">*</span>
                            RT
                            <span class="error--text">
                              (wajib diisi)
                            </span>
                          </div>
                          
                          <v-text-field
                            v-on:keyup.enter="save"
                            type="number"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            min="0"
                            filled
                            rounded
                            dense
                            color="#ff64ad"
                            placeholder="Contoh. 22"
                            :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                            v-model="form.rt"
                            v-uppercase
                            :success="valid"
                            :error-messages="errors"
                            required
                            style="border-radius: 10px;">
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>

                      <v-col
                        cols="6"
                        class="py-0">
                        <ValidationProvider 
                          name="rw" 
                          rules="required" 
                          v-slot="{ errors, valid }">
                          <div class="body-2 mb-2">
                            <span class="red--text">*</span>
                            RW
                            <span class="error--text">
                              (wajib diisi)
                            </span>
                          </div>
                          
                          <v-text-field
                            v-on:keyup.enter="save"
                            type="number"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            min="0"
                            filled
                            rounded
                            dense
                            color="#ff64ad"
                            placeholder="Contoh. 10"
                            :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                            v-model="form.rw"
                            v-uppercase
                            :success="valid"
                            :error-messages="errors"
                            required
                            style="border-radius: 10px;">
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>

                    <ValidationProvider 
                      name="password" 
                      rules="required|min:5|max:20" 
                      v-slot="{ errors }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Password
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-text-field
                        v-on:keyup.enter="save"
                        filled
                        rounded
                        dense 
                        color="#ff64ad"
                        placeholder="Contoh. ******"
                        :type="show ? 'text' : 'password'"
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show = !show"
                        :counter="20"
                        v-model="form.password"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider>
                  </v-form>
                </ValidationObserver>

                <div v-if="message.error.length > 0"
                  class="text-center mt-3 mb-16">
                  <v-alert
                    type="error" 
                    text 
                    dense 
                    prominent 
                    v-show="message.error.length > 0">
                    {{ message.error }}
                  </v-alert>
                </div>
              </div>
            </v-card-text>

            <v-card
              flat
              color="#fff"
              min-height="100"
              style="border-radius: 15px 15px 0px 0px;
                    position: absolute;
                    bottom: 0px;position: fixed;
                    bottom: 0;
                    width: 460px;
                    z-index: 1;">
              <v-card-text
                class="px-4 py-2">
                <v-row align="start">
                  <v-col 
                    cols="3"
                    class="pr-1">
                    <v-img 
                      contain
                      alt="kominfo" 
                      :src="require('@/assets/image/immigration.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="#ff64ad">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>

                  <v-col 
                    cols="9">
                    <div class="mb-3">
                      <div 
                        class="body-2 line-text-second black--text" 
                        style="opacity: 60%;">
                        Lengkapi data Anda untuk verifikasi akun, kemudian klik <b class="font-italic">Verifikasi</b>.
                      </div>
                    </div>

                    <div>
                      <v-btn
                        :disabled="process.form"
                        :loading="process.form"
                        v-on:keyup.enter="save"
                        @click="save"
                        block
                        color="green"
                        class="text-capitalize white--text pl-1 pr-2"
                        style="border-radius: 10px; box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px !important;">
                        <v-avatar 
                          size="25"
                          style="position: absolute; left: 2px;">
                          <v-icon small>mdi-check-bold</v-icon>
                        </v-avatar>

                        <span class="ml-6">
                          Verifikasi
                        </span>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>

          <v-bottom-sheet
            v-model="sheet.upload"
            hide-overlay
            persistent>
            <camera v-if="type === 'camera'"
              :sheet="sheet.upload"
              @frame="sheet.upload = $event"
              @input="addCamera($event)"
              @close="sheet.upload = $event; type = '';" />
          </v-bottom-sheet>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'notAuthenticated',
    data () {
      return {
        type: '',
        sheet: {
          upload: false
        },
        show: false,
        autocomplete: {
          subdistrict: '',
          village: ''
        },
        upload: {
          camera: null,
          folder: null
        },
        config: {
          subdistrict: [],
          village: []
        },
        form: {
          token: '',
          image: '',
          fullname: '',
          kk: '',
          nik: '',
          email: '',
          phone: '',
          password: '',
          kecamatan_code: '',
          kecamatan_name: '',
          kelurahan_code: '',
          kelurahan_name: '',
          dukuh: '',
          rt: '',
          rw: ''
        },
        process: {
          load: false,
          form: false,
          subdistrict: false,
          village: false,
          image: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/auth/verification/:slug',
          title: 'Verifikasi',
          description: 'Verifikasi Sakura Dukcapil Klaten (Sistem Layanan Administrasi Kependudukan Dalam Jaringan)',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Camera: () => import(/* webpackPrefetch: true */ '@/components/upload/camera.vue'),
      UploadFolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue')
    },
    watch: {
      'upload.folder': {
        handler: function () {
          if (this.upload.folder) {
            this.process.image = this.upload.folder.process

            if (!this.process.image) {
              // CALLBACK FROM UPLOAD IMAGE

              this.form.image = this.upload.folder.data.full.file_url
            }
          }
        },
        deep: true
      },
      'form.kecamatan_code': function() {
        let t = this
        let kec = this.config.subdistrict.filter(function(v) {
          return t.form.kecamatan_code == v.kecamatan_code
        })

        if (kec.length > 0) {
          this.form.kecamatan_name = kec[0].kecamatan_name

          this.form.kelurahan_code = ''
          this.form.kelurahan_name = ''
        }

        this.static().village()
      },
      'form.kelurahan_code': function() {
        let t = this
        let kel = this.config.village.filter(function(v) {
          return t.form.kelurahan_code == v.desa_code
        })

        if (kel.length > 0) {
          this.form.kelurahan_name = kel[0].desa_name
        }
      },
      'autocomplete.subdistrict': function() {
        this.static().subdistrict()
      },
      'autocomplete.village': function() {
        this.static().village()
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        let params = {
          token: this.$route.params.slug
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/auth/register/verify_data`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false
            
            this.form = response.data.rvd_userdata

            this.form.token = response.data.rvd_token

            this.form.password = ''
          } 
        })
      },
      static () {
        let t = this
        return {
          async subdistrict () {
            t.process.subdistrict = true

            let params = {
              search: t.autocomplete.subdistrict,
              sort: 'DESC'
            }

            await t.$axios.$get(`${process.env.PREFIX_MASTER}/location/kecamatan`, { params })
            .then(response => {
              if (response.code == 200) {
                t.process.subdistrict = false

                t.config.subdistrict = response.data
              }
            })
          },
          async village () {
            t.process.village = true

            let params = {
              desa_kecamatan_code: t.form.kecamatan_code,
              search: t.autocomplete.village,
              sort: 'DESC'
            }

            await t.$axios.$get(`${process.env.PREFIX_MASTER}/location/desa`, { params })
            .then(response => {
              if (response.code == 200) {
                t.process.village = false

                t.config.village = response.data
              }
            })
          }
        }
      },
      addCamera (e) {
        this.process.image = e.process

        if (!e.process) {
          this.form.image = e.data.full.file_url
        } 
      },
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          console.log(this.form.image.length)

          if (this.form.image.length > 0) {

            // SAVE TO REST API
            await this.$axios.$post(`${process.env.PREFIX_PROXY}/auth/register/verify_data`, this.form)
            .then((response) => {
  
              if (response.code === 200) {
                this.$router.push({ path: '/auth/success' })
              } else {
                this.process.form = false
                this.message.error = response.message
              }
  
            })

          } else {
            this.process.form = false
            this.message.error = 'Swafoto belum diisi, silahkan mengambil foto wajah Anda.'
          }

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>