import { render, staticRenderFns } from "./skd.vue?vue&type=template&id=09873643&scoped=true&"
import script from "./skd.vue?vue&type=script&lang=js&"
export * from "./skd.vue?vue&type=script&lang=js&"
import style0 from "./skd.vue?vue&type=style&index=0&id=09873643&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09873643",
  null
  
)

export default component.exports