<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.success"
          persistent>
          <v-card 
            class="border-radius pa-2">
            <v-card-title class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar size="35">
                <v-img
                  contain
                  width="30"
                  height="30"
                  alt="kominfo"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        color="#ff64ad">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span class="body-1 font-weight-bold mt-1 ml-1">
                Pemngajuan Pengiriman Berhasil
              </span>
            </v-card-title>
            
            <v-divider class="mx-4 mt-3 mb-2"/>

            <v-card-text class="body-2 font-weight-light text-first line-text-second mt-3">
              Terima kasih, Anda telah mengajukan pengiriman produk Anda di SAKURA.
            </v-card-text>

            <v-divider class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn @click="dialog.success = false; sheet.pos = false; fetch();"
                color="red"
                elevation="0"
                class="text-capitalize white--text"
                style="border-radius: 15px; box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;">
                <v-icon small class="mr-1">mdi-close-thick</v-icon>
                Tutup
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        
        <section>
          <v-card v-if="process.pos"
            flat
            class="my-2"
            style="border: 2px dashed #e0e0e0; border-radius: 5px;">
            <v-card-text>
              <v-skeleton-loader
                class="ma-auto"
                :loading="process.load"
                type="article">
              </v-skeleton-loader>
            </v-card-text>
          </v-card>

          <v-card v-if="!process.pos && Object.keys(detail_pos).length > 0"
            flat
            class="my-2"
            style="border: 2px dashed #eaeaea; border-radius: 5px;">
            <v-card-text>
              <div
                class="d-flex align-center">
                <div
                  class="font-weight-bold black--text">
                  #{{ detail_pos.delivery_kode }}
                </div>

                <v-spacer />

                <div
                  class="font-weight-light">
                  {{ detail_pos.delivery_create_datetime | date }}
                </div>
              </div>

              <v-divider 
                class="my-3"
                style="border-top: 2px dashed #eaeaea;" />

              <div
                class="font-weight-light">
                <div
                  class="d-flex align-center mb-1">
                  <div>
                    Penerima
                  </div>

                  <v-spacer />

                  <div>
                    {{ detail_pos.delivery_receiver.name }}
                  </div>
                </div>

                <div
                  class="d-flex align-center mb-1">
                  <div>
                    Telepon
                  </div>

                  <v-spacer />

                  <div>
                    {{ detail_pos.delivery_receiver.phone }}
                  </div>
                </div>

                <div
                  class="d-flex align-center mb-1">
                  <div>
                    Alamat
                  </div>

                  <v-spacer />

                  <div>
                    {{ detail_pos.delivery_receiver.address }}
                  </div>
                </div>

                <div
                  class="d-flex align-center mb-1">
                  <div>
                    Kecamatan
                  </div>

                  <v-spacer />

                  <div>
                    {{ detail_pos.delivery_receiver.subSubDistrictName }}
                  </div>
                </div>

                <div
                  class="d-flex align-center">
                  <div>
                    Kode POS
                  </div>

                  <v-spacer />

                  <div>
                    {{ detail_pos.delivery_receiver.zipcode }}
                  </div>
                </div>

                <v-divider 
                  class="my-3"
                  style="border-top: 2px dashed #eaeaea;" />

                <div
                  class="d-flex align-center">
                  <div>
                    No. Resi
                  </div>

                  <v-spacer />

                  <div>
                    {{ detail_pos.delivery_resi !== '' ? detail_pos.delivery_resi : '-' }}
                  </div>
                </div>

                <v-divider 
                  class="my-3"
                  style="border-top: 2px dashed #eaeaea;" />

                <div
                  class="d-flex align-center">
                  <div>
                    Status Pengiriman
                  </div>

                  <v-spacer />

                  <div>
                    <div
                      @click="sheet.log = true; fetchLogPOS();"

                      class="font-weight-bold d-flex align-center cursor-pointer"
                      :class="detail_pos.delivery_status === 'REQUEST' ? 'orange--text' :
                              detail_pos.delivery_status === 'DIPROSES' ? 'blue--text' :
                              detail_pos.delivery_status === 'DISORTIR' ? 'cyan--text' :
                              detail_pos.delivery_status === 'DIKEMAS' ? 'indigo--text' :
                              detail_pos.delivery_status === 'DIKIRIM' ? 'lime--text' :
                              detail_pos.delivery_status === 'SELESAI' ? 'green--text' : 'red--text'">
                      <v-icon
                        size="20"
                        :color="detail_pos.delivery_status === 'REQUEST' ? 'orange' :
                                detail_pos.delivery_status === 'DIPROSES' ? 'blue' :
                                detail_pos.delivery_status === 'DISORTIR' ? 'cyan' :
                                detail_pos.delivery_status === 'DIKEMAS' ? 'indigo' :
                                detail_pos.delivery_status === 'DIKIRIM' ? 'lime' :
                                detail_pos.delivery_status === 'SELESAI' ? 'green' : 'red'">
                        mdi-eye-settings
                      </v-icon>

                      <span
                        class="ml-1">
                        {{ detail_pos.delivery_status }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>

          <v-card 
            flat
            color="transparent">
            <v-list 
              flat 
              color="transparent"
              three-line>
              <v-list-item-group>
                <v-skeleton-loader
                  v-for="(item, index) in process.load ? 5 : list"
                  :key="index"
                  class="ma-auto"
                  :loading="process.load"
                  type="list-item-avatar-three-line">
                  <template>
                    <div>
                      <v-list-item v-if="!process.load && list.length > 0"
                        class="pl-3 mb-4"
                        style="border-radius: 5px;
                              border: 2px solid #eaeaea; 
                              border-style: dashed;
                              background: #fff;
                              cursor: auto;">
                        <v-list-item-avatar 
                          tile 
                          size="50"
                          class="mr-3">
                          <v-img 
                            style="border-radius: 5px;"
                            alt="kominfo" 
                            :src="require(`@/assets/image/service/${item.prod_layanan_kode}.png`)"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#ff64ad">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title 
                            class="body-2 font-weight-bold d-flex align-center">
                            <span>
                              {{ item.prod_layanan_kode }} - {{ item.prod_nomor }}
                            </span>

                            <v-spacer />

                            <v-btn
                              icon
                              x-small
                              :to="`/product/${item.prod_ajuan_id}?prod_id=${item.prod_id}&code=${item.prod_layanan_kode}`">
                              <v-icon size="20" color="blue">mdi-information</v-icon>
                            </v-btn>
                          </v-list-item-title>

                          <v-list-item-subtitle 
                            class="caption font-weight-light black--text my-2" 
                            style="opacity: 60%;">
                            <div
                              style="margin-bottom: 7px;">
                              <span
                                class="one-line text-uppercase">
                                {{ item.prod_nama }}
                              </span>
                            </div>

                            <div
                              class="font-weight-bold"
                              :class="item.prod_status === 'BELUM DIVERIFIKASI' || item.prod_status === 'BELUM DIAJUKAN TTE' ? 'orange--text' :
                                      item.prod_status === 'TIDAK DIPROSES' ? 'red--text' : 'cyan--text'">
                              <span
                                class="one-line">
                                {{ item.prod_status }}
                              </span>
                            </div>
                          </v-list-item-subtitle>

                          <v-list-item-subtitle 
                            class="caption font-weight-light black--text mb-1" 
                            style="opacity: 60%;">
                            <span
                              class="ten-line line-text-first mb-1"
                              v-html="item.prod_status_note.replace(/(<([^>]+)>)/ig, '')">
                            </span>
                          </v-list-item-subtitle>

                          <v-list-item-subtitle 
                            class="caption font-weight-light black--text d-flex align-center">
                            <span
                              class="one-line">
                              {{ item.prod_create_datetime | date }}
                            </span>

                            <v-spacer />

                            <span v-if="item.prod_status === 'SIAP DIDOWNLOAD' && item.prod_url.length > 0">
                              <a
                                :href="item.prod_url"
                                target="_blank">
                                <v-icon size="20" color="green">mdi-download</v-icon>
                              </a>
                            </span>

                            <span v-if="item.prod_status === 'SIAP DIAMBIL' && (item.prod_from_layanan_kode === 'KTP' || item.prod_from_layanan_kode === 'KIA') && Object.keys(detail_pos).length < 1"
                              class="ml-2">
                              <div>
                                <v-icon 
                                  @click="sheet.pos = true; reset();"

                                  class="cursor-pointer"
                                  size="20" 
                                  color="orange">
                                  mdi-truck-fast
                                </v-icon>
                              </div>
                            </span>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </template>
                </v-skeleton-loader>
              </v-list-item-group>
            </v-list>

            <v-card-text v-if="pagination.total_data > 10 && limit < pagination.total_data"
              class="text-center">
              <v-btn
                small
                rounded
                outlined
                elevation="0"
                color="#ff64ad"
                class="color-default text-capitalize"
                @click="limit += 10">
                Lihat Lebih Banyak
              </v-btn>
            </v-card-text>
                      
            <Empty v-if="!process.load && pagination.total_data < 1"
              margin="my-10"
              padding="pa-16"
              font="body-2"
              :size="150"
              message="Produk" />
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.pos"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            style="border-radius: 0px;">
            <v-card-title 
              @click="sheet.pos = false"
              class="cursor-pointer pa-4"
              style="border-radius: 0px 0px 15px 15px;
                    position: absolute;
                    width: 100%;
                    z-index: 1;
                    background: #ff64ad;">
              <v-icon color="white">
                mdi-arrow-left-thick
              </v-icon>

              <span
                class="body-1 font-weight-bold text-capitalize white--text ml-1">
                Pengiriman POS
              </span>
            </v-card-title>

            <v-card
              flat
              color="#ff64ad"
              class="pt-12 pb-4"
              style="border-radius: 0px 0px 15px 15px;">
              <v-card-title
                class="white--text pb-1">
                #Pengajuan Pengiriman
              </v-card-title>

              <v-card-text
                class="white--text line-text-second pb-1">
                <v-divider 
                  class="mb-3"
                  style="border: 2px solid #fff; border-radius: 50px;" />

                <div>
                  <div>
                    No. Reg: {{ detail.ajuan_no_reg }}
                  </div>

                  <div>
                    Nama Lengkap: {{ detail.ajuan_data_ajuan.field.nama_lengkap }}
                  </div>

                  <div>
                    NIK: {{ detail.ajuan_data_ajuan.field.nik }}
                  </div>

                  <div
                    class="font-weight-bold mt-3">
                    Alamat:
                  </div>

                  <div>
                    Kecamatan: {{ detail.ajuan_kecamatan_name }} ({{ detail.ajuan_kecamatan_code }})
                  </div>

                  <div>
                    Kelurahan: {{ detail.ajuan_kelurahan_name }} ({{ detail.ajuan_kelurahan_code }})
                  </div>
                </div>

                <v-divider 
                  class="my-3"
                  style="border: 2px solid #fff; border-radius: 50px;" />
                
                <div>
                  <div
                    class="caption font-weight-bold d-flex align-center mt-4">
                    <div
                      class="d-flex align-center">
                      <v-icon small color="white" class="mr-1">mdi-calendar-month</v-icon>

                      {{ detail.ajuan_create_datetime | date }}
                    </div>

                    <v-spacer />

                    <div
                      class="d-flex align-center">
                      <v-icon small color="white" class="mr-1">mdi-clock-time-three-outline</v-icon>

                      {{ detail.ajuan_create_datetime | time }}
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>

            <v-card-text
              class="pt-6 pb-2">
              <ValidationObserver 
                ref="form">
                <v-form>
                  <div>
                    <div
                      class="font-weight-bold black--text">
                      Kontak & Alamat Pengiriman
                    </div>

                    <div
                      class="mb-4">
                      Sesuaikan kontak & alamat pengiriman di bawah ini :
                    </div>
                  </div>

                  <ValidationProvider 
                    name="name" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Nama Penerima
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>
                    
                    <v-text-field
                      filled
                      rounded
                      dense
                      color="#ff64ad"
                      placeholder="Contoh. Firmansyah Abdul Rafi"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                      v-model="form.receiver.name"
                      v-uppercase
                      :success="valid"
                      :error-messages="errors"
                      required
                      style="border-radius: 10px;">
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="phone" 
                    rules="required|min:8|max:13"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Telepon
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save"
                      type="number"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      min="0"
                      filled
                      rounded
                      dense
                      color="#ff64ad"
                      placeholder="Contoh. 081332XXXXXX"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                      v-model="form.receiver.phone"
                      v-uppercase
                      :success="valid"
                      :error-messages="errors"
                      required
                      style="border-radius: 10px;">
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="email" 
                    rules="required|email" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Email
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      color="#ff64ad"
                      placeholder="Contoh. XXXXX@gmail.com"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                      v-model="form.receiver.email"
                      :success="valid"
                      :error-messages="errors"
                      required
                      style="border-radius: 10px;">
                    </v-text-field>
                  </ValidationProvider>

                  <!-- <ValidationProvider 
                    name="kecamatan_code" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Kecamatan
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>

                    <v-autocomplete
                      filled
                      rounded
                      dense
                      color="#ff64ad"
                      placeholder="Contoh. Trucuk"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      :search-input.sync="autocomplete.subdistrict"
                      :loading="process.subdistrict"
                      
                      :items="config.subdistrict"
                      item-value="kecamatan_code"
                      item-text="kecamatan_name"
                      item-color="#ff64ad"

                      @change="form.kelurahan_code = ''; form.kelurahan_name = '';"

                      v-model="form.kecamatan_code"
                      :success="valid"
                      :error-messages="errors"
                      required
                      style="border-radius: 10px;">
                    </v-autocomplete>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="kelurahan_code" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Kelurahan
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>

                    <v-autocomplete
                      filled
                      rounded
                      dense
                      color="#ff64ad"
                      placeholder="Contoh. Kradenan"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      :search-input.sync="autocomplete.village"
                      :loading="process.village"
                      
                      :items="config.village"
                      item-value="desa_code"
                      item-text="desa_name"
                      item-color="#ff64ad"

                      v-model="form.kelurahan_code"
                      :success="valid"
                      :error-messages="errors"
                      required
                      style="border-radius: 10px;">
                    </v-autocomplete>
                  </ValidationProvider> -->

                  <ValidationProvider 
                    name="address" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Alamat
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>
                    
                    <v-text-field
                      filled
                      rounded
                      dense
                      color="#ff64ad"
                      placeholder="Contoh. Kemiri"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                      v-model="form.receiver.address"
                      v-uppercase
                      :success="valid"
                      :error-messages="errors"
                      required
                      style="border-radius: 10px;">
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="subDistrictCode" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Kecamatan
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>

                    <v-autocomplete
                      filled
                      rounded
                      dense
                      color="#ff64ad"
                      placeholder="Contoh. Trucuk"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                      
                      :items="config.subdistrict_pos"
                      item-value="subDistrictCode"
                      item-text="subDistrictName"
                      item-color="#ff64ad"

                      v-model="form.receiver.subDistrictCode"
                      :success="valid"
                      :error-messages="errors"
                      required
                      style="border-radius: 10px;">
                    </v-autocomplete>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="zipcode" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Kode Pos
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>

                    <v-autocomplete
                      filled
                      rounded
                      dense
                      color="#ff64ad"
                      placeholder="Contoh. Kradenan (57467)"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                      
                      :items="config.postal_code"
                      item-value="postalCode"
                      item-text="titlePostalCode"
                      item-color="#ff64ad"

                      v-model="form.receiver.zipcode"
                      :success="valid"
                      :error-messages="errors"
                      required
                      style="border-radius: 10px;">
                    </v-autocomplete>
                  </ValidationProvider>

                  <!-- <v-row>
                    <v-col
                      cols="6"
                      class="py-0">
                      <ValidationProvider 
                        name="rt" 
                        rules="required" 
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          RT
                          <span class="error--text">
                            (wajib diisi)
                          </span>
                        </div>
                        
                        <v-text-field
                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          min="0"
                          filled
                          rounded
                          dense
                          color="#ff64ad"
                          placeholder="Contoh. 22"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                          v-model="form.rt"
                          v-uppercase
                          :success="valid"
                          :error-messages="errors"
                          required
                          style="border-radius: 10px;">
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>

                    <v-col
                      cols="6"
                      class="py-0">
                      <ValidationProvider 
                        name="rw" 
                        rules="required" 
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          RW
                          <span class="error--text">
                            (wajib diisi)
                          </span>
                        </div>
                        
                        <v-text-field
                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          min="0"
                          filled
                          rounded
                          dense
                          color="#ff64ad"
                          placeholder="Contoh. 10"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                          v-model="form.rw"
                          v-uppercase
                          :success="valid"
                          :error-messages="errors"
                          required
                          style="border-radius: 10px;">
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row> -->
                </v-form>
              </ValidationObserver>

              <div v-if="message.error.length > 0"
                class="text-center my-3">
                <v-alert
                  type="error" 
                  text 
                  dense 
                  prominent 
                  v-show="message.error.length > 0">
                  {{ message.error }}
                </v-alert>
              </div>
            </v-card-text>

            <v-card-actions
              class="mb-3 pt-0">
              <v-btn
                @click="save()"
                :loading="process.form"
                :disabled="process.form"

                block
                elevation="0"
                color="orange"
                class="body-2 font-weight-bold white--text text-capitalize d-flex align-center"
                style="border-radius: 10px;">
                <v-icon size="20">mdi-truck-check</v-icon>

                <v-spacer />
                
                <span
                  class="ml-1">
                  Ajukan Pengiriman
                </span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.log"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            style="border-radius: 0px;">
            <v-card-title 
              @click="sheet.log = false; fetchPOS();"
              class="cursor-pointer pa-4"
              style="border-radius: 0px 0px 15px 15px;
                    position: absolute;
                    width: 100%;
                    z-index: 1;
                    background: #ff64ad;">
              <v-icon color="white">
                mdi-arrow-left-thick
              </v-icon>

              <span
                class="body-1 font-weight-bold text-capitalize white--text ml-1">
                Log Pengiriman POS
              </span>
            </v-card-title>

            <v-card v-if="Object.keys(detail_pos).length > 0"
              flat
              color="#ff64ad"
              class="pt-12 pb-4"
              style="border-radius: 0px 0px 15px 15px;">
              <v-card-title
                class="white--text pb-1">
                #{{ detail_pos.delivery_kode }}
              </v-card-title>

              <v-card-text
                class="white--text line-text-second pb-1">
                <v-divider 
                  class="mb-3"
                  style="border: 2px solid #fff; border-radius: 50px;" />

                <div>
                  <div>
                    Penerima: {{ detail_pos.delivery_receiver.name }}
                  </div>

                  <div>
                    Telepon: {{ detail_pos.delivery_receiver.phone }}
                  </div>

                  <div
                    class="font-weight-bold mt-3">
                    Alamat:
                  </div>

                  <div>
                    Kecamatan: {{ detail_pos.delivery_receiver.subdistrict }}
                  </div>

                  <div>
                    Kelurahan: {{ detail_pos.delivery_receiver.village }}
                  </div>

                  <div>
                    Kode POS: {{ detail_pos.delivery_receiver.zipcode }}
                  </div>
                </div>

                <v-divider 
                  class="my-3"
                  style="border: 2px solid #fff; border-radius: 50px;" />
                
                <div>
                  <div
                    class="caption font-weight-bold d-flex align-center mt-4">
                    <div
                      class="d-flex align-center">
                      <v-icon small color="white" class="mr-1">mdi-calendar-month</v-icon>

                      {{ detail_pos.delivery_create_datetime | date }}
                    </div>

                    <v-spacer />

                    <div
                      class="d-flex align-center">
                      <v-icon small color="white" class="mr-1">mdi-clock-time-three-outline</v-icon>

                      {{ detail_pos.delivery_create_datetime | time }}
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>

            <v-card-text
              class="py-0 pl-0">
              <v-timeline
                align-center
                dense>
                <v-skeleton-loader
                  v-for="(item, index) in process.log ? 7 : log_pos"
                  :key="index"
                  :loading="process.log"
                  type="list-item-avatar-three-line">
                  <div>
                    <div v-if="!process.load && log_pos.length > 0">
                      <v-timeline-item
                        :color="item.status === 'REQUEST' ? 'orange' :
                                item.status === 'DIPROSES' ? 'blue' :
                                item.status === 'DISORTIR' ? 'cyan' :
                                item.status === 'DIKEMAS' ? 'indigo' :
                                item.status === 'DIKIRIM' ? 'lime' :
                                item.status === 'SELESAI' ? 'green' : 'red'"
                        small>
                        <v-row
                          class="cursor-pointer">
                          <v-col 
                            cols="3">
                            <strong
                              class="black--text"
                              style="opacity: 60%;">
                              {{ item.created_at | time }}
                            </strong>
                          </v-col>

                          <v-col>
                            <strong
                              :class="item.status === 'REQUEST' ? 'orange--text' :
                                      item.status === 'DIPROSES' ? 'blue--text' :
                                      item.status === 'DISORTIR' ? 'cyan--text' :
                                      item.status === 'DIKEMAS' ? 'indigo--text' :
                                      item.status === 'DIKIRIM' ? 'lime--text' :
                                      item.status === 'SELESAI' ? 'green--text' : 'red--text'">
                              {{ item.status }}
                            </strong>

                            <div
                              class="caption font-weight-light five-line line-text-second">
                              <span
                                v-html="item.msg">
                              </span>
                            </div>

                            <div 
                              class="caption font-weight-bold black--text"
                              style="opacity: 60%;">
                              {{ item.created_at | date }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-timeline-item>
                    </div>
                  </div>
                </v-skeleton-loader>
              </v-timeline>
             </v-card-text>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        dialog: {
          success: false
        },

        sheet: {
          pos: false,
          log: false
        },

        detail_pos: {},
        log_pos: [],

        list: [],
        pagination: {},

        limit: 10,

        config: {
          subdistrict_pos: [],
          postal_code: []
        },

        form: {
          ajuan_no_reg:[],
          receiver: {
            name: '',
            phone: '',
            email: '',
            address: '',

            subdistrict: '',
            village: '',

            subDistrictCode: '',
            subSubDistrictName: '',

            zipcode: '',
            geolang: '',
            geolat: ''
          },
          service: {
            serviceCode: 0,
            fee: 0,
            feeTax: 0,
            insurance: 0,
            insuranceTax: 0
          }
        },

        process: {
          load: false,

          form: false,

          subdistrict_pos: false,
          postal_code: false,
          get_fee: false,

          pos: false,
          log: false
        },
        
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'limit': function() {
        this.fetch()
      },
      
      'form.receiver.subDistrictCode': function() {
        let t = this
        let kec = this.config.subdistrict_pos.filter(function(v) {
          return t.form.receiver.subDistrictCode == v.subDistrictCode
        })

        if (kec.length > 0) {
          this.form.receiver.subdistrict = kec[0].subDistrictName

          this.form.receiver.subSubDistrictName = kec[0].subDistrictName
        }

        this.static().postal_code()
      },

      'form.receiver.zipcode': function() {
        let t = this
        let kel = this.config.postal_code.filter(function(v) {
          return t.form.receiver.zipcode == v.postalCode
        })

        if (kel.length > 0) {
          this.form.receiver.village = kel[0].subSubDistrictName
        }

        this.static().get_fee()
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      user() {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      fetch () {
        this.fetchProduct()

        this.fetchPOS()
      },

      async fetchProduct () {
        this.process.load = true

        let params = {
          limit: this.limit,
          prod_ajuan_id: this.detail.ajuan_id,
          order_by: 'ASC'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/produk/list`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false
            
            this.list = response.data
            this.pagination = response.pagination
          }
        })
      },

      reset () {
        if (this.$refs.form) this.$refs.form.reset()

        let self = this

        navigator.permissions.query({ name: 'geolocation' })
        .then(function(result) {
          if (result.state !== 'denied') {
            navigator.geolocation.getCurrentPosition(position => {
              self.form = {
                ajuan_no_reg: self.detail.ajuan_no_reg,
                receiver: {
                  name: self.user.fullname,
                  phone: self.user.phone,
                  email: self.user.email,
                  address: self.user.dukuh,

                  subdistrict: '',
                  village: '',

                  subDistrictCode: '',
                  subSubDistrictName: '',

                  zipcode: '',
                  geolang: position.coords.latitude,
                  geolat: position.coords.longitude
                },
                service: {
                  serviceCode: 0,
                  fee: 0,
                  feeTax: 0,
                  insurance: 0,
                  insuranceTax: 0
                }
              }

              self.static().subdistrict_pos()
            })
          } else {
            // JIKA DENIED
          }
        })
        
        this.process.form = false

        this.message.error = ''
      },

      static () {
        let t = this
        return {
          // async subdistrict () {
          //   t.process.subdistrict = true

          //   let params = {
          //     search: t.autocomplete.subdistrict,
          //     sort: 'DESC'
          //   }

          //   await t.$axios.$get(`${process.env.PREFIX_MASTER}/location/kecamatan`, { params })
          //   .then(response => {
          //     if (response.code == 200) {
          //       t.process.subdistrict = false

          //       t.config.subdistrict = response.data
          //     }
          //   })
          // },
          // async village () {
          //   t.process.village = true

          //   let params = {
          //     desa_kecamatan_code: t.form.kecamatan_code,
          //     search: t.autocomplete.village,
          //     sort: 'DESC'
          //   }

          //   await t.$axios.$get(`${process.env.PREFIX_MASTER}/location/desa`, { params })
          //   .then(response => {
          //     if (response.code == 200) {
          //       t.process.village = false

          //       t.config.village = response.data
          //     }
          //   })
          // },

          async subdistrict_pos () {
            t.process.subdistrict_pos = true

            await t.$axios.$post(`${process.env.PREFIX_PROXY}/delivery/getSubDistrict`, { 
              subDistrictName: 'klaten'
            })
            .then(response => {
              if (response.code == 200) {
                t.process.subdistrict_pos = false

                t.config.subdistrict_pos = response.data
              }
            })
          },

          async postal_code () {
            t.process.postal_code = true

            t.config.postal_code = []

            await t.$axios.$post(`${process.env.PREFIX_PROXY}/delivery/getSubSubDistrict`, { 
              subDistrictCode: t.form.receiver.subDistrictCode,
              subSubDistrictName: t.form.receiver.subSubDistrictName
            })
            .then(response => {
              if (response.code == 200) {
                t.process.postal_code = false

                response.data.forEach(obj => {
                  t.config.postal_code.push({
                    subSubDistrictName: obj.subSubDistrictName,
                    postalCode: obj.postalCode,

                    titlePostalCode: `${obj.subSubDistrictName} (${obj.postalCode})`
                  })
                })
              }
            })
          },

          async get_fee () {
            t.process.get_fee = true

            await t.$axios.$post(`${process.env.PREFIX_PROXY}/delivery/getFee`, { 
              receiverzipcode: t.form.receiver.zipcode
            })
            .then(response => {
              if (response.code == 200) {
                t.process.get_fee = false
                
                if (response.data.length > 0) {
                  t.form.service = {
                    serviceCode: response.data[0].serviceCode,
                    fee: response.data[0].fee,
                    feeTax: response.data[0].feeTax,
                    insurance: response.data[0].insurance,
                    insuranceTax: response.data[0].insuranceTax
                  }
                }
              }
            })
          }
        }
      },

      async save() {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.PREFIX_PROXY}/delivery/requestPengiriman`, this.form)
          .then((response) => {

            this.process.form = false

            if (response.code === 200) {
              this.dialog.success = true
            } else {
              this.message.error = response.message
            }

          })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },

      async fetchPOS () {
        this.process.pos = true

        let params = {
          ajuan_no_reg: this.detail.ajuan_no_reg
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/delivery/detail`, { params })
        .then((response) => {
          this.process.pos = false

          if (response.code === 200) {
            if (Object.keys(response.data).length > 0) {
              this.detail_pos = response.data
            }
          } else {
            this.detail_pos = {}
          }
        })
      },

      async fetchLogPOS () {
        this.process.log = true

        let params = {
          ajuan_no_reg: this.detail.ajuan_no_reg
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/delivery/log`, { params })
        .then((response) => {
          this.process.log = false

          if (response.code === 200) {
            this.log_pos = response.data
          }
        })
      }
    }
  }
</script>

<style scoped>
  .v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse):before {
    min-height: calc(100vh - 249px);
  }
</style>