<template>
  <div class="fill-height pt-16 pb-12">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card 
            flat 
            color="transparent">
            <v-card-text 
              class="pb-0">
              <v-text-field
                v-model="search"
                v-uppercase
                @click:clear="search = ''; fetch();"
                v-on:keyup.enter="fetch()"
                filled
                rounded
                dense 
                single-line
                hide-details
                color="#ff64ad"
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari . . ."
                clearable
                style="border-radius: 10px;">
              </v-text-field>
            </v-card-text>

            <v-card-text>
              <v-list 
                flat 
                three-line 
                color="transparent">
                <v-list-item-group>
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 10 : list"
                    :key="index"
                    :loading="process.load"
                    type="list-item-avatar-three-line">
                    <template>
                      <div>
                        <v-list-item v-if="!process.load && list.length > 0"
                          @click="sheet.detail = true; detail = item;"
                          :class="index < list.length - 1 ? 'mb-4' : ''"
                          class="pl-3"
                          style="box-shadow: rgba(0, 0, 0, 0.01) 0px 3px 5px; 
                                border-radius: 5px;
                                border-left: 4px solid #ff64ad;
                                background: #fff;">
                          <v-list-item-avatar 
                            style="box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;"
                            color="#ff64ad"
                            size="55"
                            class="mr-3 my-3">
                            <span class="body-1 white--text font-weight-bold">
                              {{ item.announcement_title | initial }}
                            </span>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-subtitle 
                              class="d-flex align-center" 
                              style="font-size: 10px; opacity: 60%;">
                              <v-icon size="10">mdi-calendar-month</v-icon>
                              
                              <span class="ml-1">
                                {{ item.announcement_create_datetime | date }}
                              </span>
                            </v-list-item-subtitle>
                            
                            <v-list-item-title 
                              class="body-2 font-weight-bold my-1 mb-2">
                              {{ item.announcement_title }}
                            </v-list-item-title>

                            <v-list-item-subtitle 
                              class="caption font-weight-light black--text line-text-first" 
                              style="opacity: 60%;">
                              <span v-html="item.announcement_content.replace(/(<([^>]+)>)/ig, '')"></span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-list-item-group>
              </v-list>

              <v-card-text v-if="pagination.total_data > 10 && limit < pagination.total_data"
                class="text-center">
                <v-btn
                  small
                  rounded
                  outlined
                  elevation="0"
                  color="#ff64ad"
                  class="color-default text-capitalize"
                  @click="limit += 10">
                  Lihat Lebih Banyak
                </v-btn>
              </v-card-text>

              <Empty v-if="!process.load && pagination.total_page < 1"
                margin="my-16"
                size="125"
                message="Pengumuman" />
            </v-card-text>
          </v-card>

          <v-bottom-sheet
            v-model="sheet.detail"
            hide-overlay
            persistent
            fullscreen
            scrollable>
            <v-card
              flat>
              <v-card-title 
                @click="sheet.detail = false; detail = {};"
                class="cursor-pointer pa-4"
                style="background: #ff64ad; 
                      border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 1;">
                <v-icon color="white">mdi-arrow-left-thick</v-icon>

                <span
                  class="body-1 font-weight-bold text-capitalize white--text ml-1">
                  Detail Pengumuman
                </span>
              </v-card-title>

              <v-card-text
                class="pt-16 mt-3">
                <div
                  class="title font-weight-bold line-text-first black--text">
                  {{ detail.announcement_title }}
                </div>

                <div
                  class="caption mt-1 d-flex align-center">
                  <v-icon small style="opacity: 90%;">mdi-calendar-month</v-icon>
                  
                  <span
                    class="ml-1">
                    {{ detail.announcement_create_datetime | date }}
                  </span>
                </div>

                <v-divider 
                  class="my-3"
                  style="border: 2px solid #f3f3f3;
                        border-radius: 50px;" />

                <div
                  class="body-1 font-weight-light line-text-second">
                  <span v-html="detail.announcement_content"></span>
                </div>
              </v-card-text>
            </v-card>
          </v-bottom-sheet>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        sheet: {
          detail: false
        },
        search: '',
        detail: {},
        list: [],
        pagination: {},
        limit: 10,
        process: {
          load: false
        },
        // SEO
        content: {
          url: '/announcement',
          title: 'Pengumuman',
          description: 'Pengumuman Sakura Dukcapil Klaten (Sistem Layanan Administrasi Kependudukan Dalam Jaringan)',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'limit': function() {
        this.fetch()
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        let params = {
          limit: this.limit,
          search: this.search,
          announcement_status: 'publish'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/announcement`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false
            
            this.list = response.data
            this.pagination = response.pagination
          } 
        })
      }
    }
  }
</script>