var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('TopToolbar'),_vm._v(" "),(!_vm.firstLoad && _vm.token &&
          _vm.$route.path !== '/' &&
          _vm.$route.path !== '/auth/login' && 
          _vm.$route.path !== '/auth/register' &&
          _vm.$route.path !== '/auth/forgot' &&
          _vm.$route.path !== '/auth/check' &&
          _vm.$route.path !== '/auth/checkforgot' &&
          _vm.$route.path !== '/auth/success' &&
          _vm.$route.path !== '/auth/successforgot' &&
          _vm.$route.name !== 'auth-verification-slug' &&
          _vm.$route.name !== 'auth-reset-slug' &&
          _vm.$route.name.split('-')[0] !== 'progress' &&
          _vm.$route.name.split('-')[0] !== 'product')?_c('BottomNav'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }