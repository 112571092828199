<template>
  <div class="fill-height pt-12 pb-16 mb-4">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card 
            flat 
            color="transparent">
            <div v-if="detail.is_request_update > 0"
              class="text-center line-text-second d-flex align-center pa-4"
              style="z-index: 2; 
                    height: 80vh;
                    position: fixed;">
              <div>
                <v-img 
                  contain
                  width="150"
                  height="150"
                  alt="kominfo" 
                  :src="require('@/assets/logo/favicon.png')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        color="#ff64ad">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                
                <div
                  class="text-h5 font-weight-bold mt-6 mb-3">
                  Perubahan Elemen Data
                </div>

                <div
                  class="body-1 font-weight-light mb-7">
                  Pengajuan perubahan elemen data Anda sedang diperiksa oleh Admin kami, mohon untuk menunggu sampai pengajuan Anda selesai diperiksa dan disetujui.
                </div>

                <v-btn
                  rounded
                  color="#ff64ad"
                  class="text-capitalize white--text pl-1 pr-2"
                  style="box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;"
                  to="/home">
                  <v-avatar 
                    color="#ff64ad" 
                    size="25"
                    style="position: absolute; left: 2px;">
                    <v-icon>mdi-arrow-left-thick</v-icon>
                  </v-avatar>

                  <span class="ml-9">
                    kembali ke beranda
                  </span>
                </v-btn>
              </div>
            </div>

            <div :class="detail.is_request_update > 0 ? 'screen_disabled' : ''">
              <v-card-text>
                <v-row 
                  align="center">
                  <v-col 
                    cols="6" 
                    class="body-1 font-weight-bold black--text">
                    #Layanan
                  </v-col>

                  <v-col 
                    cols="6" 
                    class="text-right">
                    <v-icon 
                      color="black"
                      style="opacity: 60%;">
                      mdi-dots-horizontal
                    </v-icon>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col 
                    class="py-2">
                    <no-ssr>
                      <!-- <swiper 
                        v-model="selected.service"
                        ref="swiper"
                        class="swiper"
                        :options="swiperOption" 
                        @slideChange="onSlideChange"> -->

                      <swiper 
                        v-model="selected.service"
                        ref="swiper"
                        class="swiper"
                        :options="swiperOption">
                        <swiper-slide 
                          v-for="(item, index) in process.load ? 10 : list.service" 
                          :key="index" 
                          :class="index < 1 ? 'pr-4' : index < list.service.length ? 'pr-4' : 'px-4'"
                          style="width: auto;">
                          <v-skeleton-loader
                            class="ma-auto"
                            :loading="process.load"
                            type="avatar"
                            tile>
                            <div>
                              <v-card v-if="!process.load && list.service.length > 0"
                                @click="selected.service = index; selected.layanan_kode = item.layanan_kode;"
                                flat
                                width="45"
                                height="45"
                                :color="selected.service == index ? '#ff64ad1a' : 'white'"
                                class="border-radius d-flex align-center justify-center pa-3"
                                style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px !important;">
                                <div 
                                  class="text-center">
                                  <v-avatar 
                                    size="30" 
                                    tile>
                                    <v-img 
                                      alt="kominfo" 
                                      :src="item.layanan_image"
                                      class="ma-auto"
                                      style="border-radius: 10px;">
                                      <template v-slot:placeholder>
                                        <v-row
                                          class="fill-height"
                                          align="center"
                                          justify="center">
                                          <v-progress-circular 
                                            indeterminate 
                                            color="white">
                                          </v-progress-circular>
                                        </v-row>
                                      </template>
                                    </v-img>
                                  </v-avatar>

                                  <div 
                                    class="text-center font-weight-bold mt-1"
                                    style="font-size: 10px; opacity: 60%;">
                                    {{ item.layanan_kode }}
                                  </div>
                                </div>
                              </v-card>
                            </div>
                          </v-skeleton-loader>
                        </swiper-slide>
                      </swiper>
                    </no-ssr>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-text v-if="!process.load && list.service.length > 0"
                class="py-0">
                <Kk v-if="selected.layanan_kode === 'KK'"
                  :detail="list.service[selected.service]" />

                <Ktp v-if="selected.layanan_kode === 'KTP'"
                  :detail="list.service[selected.service]" />

                <Kia v-if="selected.layanan_kode === 'KIA'"
                  :detail="list.service[selected.service]" />

                <Akl v-if="selected.layanan_kode === 'AKL'"
                  :detail="list.service[selected.service]" />
                  
                <Akm v-if="selected.layanan_kode === 'AKM'"
                  :detail="list.service[selected.service]" />

                <Skp v-if="selected.layanan_kode === 'SKP'"
                  :detail="list.service[selected.service]" />

                <Skd v-if="selected.layanan_kode === 'SKD'"
                  :detail="list.service[selected.service]" />

                <Upd v-if="selected.layanan_kode === 'UPD'"
                  :detail="list.service[selected.service]" />

                <Rkm v-if="selected.layanan_kode === 'RKM'"
                  :detail="list.service[selected.service]" />

                <!-- <Akw v-if="selected.layanan_kode === 'AKW'"
                  :detail="list.service[selected.service]" />

                <Acr v-if="selected.layanan_kode === 'ACR'"
                  :detail="list.service[selected.service]" />

                <Kut v-if="selected.layanan_kode === 'KUT'"
                  :detail="list.service[selected.service]" /> -->
              </v-card-text>
            </div>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        swiperOption: {
          slidesPerView: 'auto'
        },
        selected: {
          service: 0,
          layanan_kode: ''
        },
        detail: {},
        list: {
          service: []
        },
        process: {
          load: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/submission',
          title: 'Pengajuan Layanan',
          description: 'Pengajuan Layanan Sakura Dukcapil Klaten (Sistem Layanan Administrasi Kependudukan Dalam Jaringan)',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Kk: () => import(/* webpackPrefetch: true */ '@/components/submission/kk.vue'),
      Ktp: () => import(/* webpackPrefetch: true */ '@/components/submission/ktp.vue'),
      Kia: () => import(/* webpackPrefetch: true */ '@/components/submission/kia.vue'),
      Akl: () => import(/* webpackPrefetch: true */ '@/components/submission/akl.vue'),
      Akm: () => import(/* webpackPrefetch: true */ '@/components/submission/akm.vue'),
      Akw: () => import(/* webpackPrefetch: true */ '@/components/submission/akw.vue'),
      Acr: () => import(/* webpackPrefetch: true */ '@/components/submission/acr.vue'),
      Skp: () => import(/* webpackPrefetch: true */ '@/components/submission/skp.vue'),
      Skd: () => import(/* webpackPrefetch: true */ '@/components/submission/skd.vue'),
      Upd: () => import(/* webpackPrefetch: true */ '@/components/submission/upd.vue'),
      Kut: () => import(/* webpackPrefetch: true */ '@/components/submission/kut.vue'),
      Rkm: () => import(/* webpackPrefetch: true */ '@/components/submission/rkm.vue')
    },
    watch: {

    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      }
    },
    mounted () {
      // this.selected.service = this.$route.query.id !== undefined ? parseInt(this.$route.query.id) : 0

      this.fetch()
    },
    methods: {
      fetch () {
        this.fetchUser()
        this.fetchService()
      },
      async fetchUser () {
        await this.$axios.$get(`${process.env.PREFIX_PROXY}/profile`)
        .then((response) => {
          if (response.code === 200) {
            this.detail = response.data
          } 
        })
      },
      async fetchService () {
        this.process.load = true

        let params = {
          sort: 'DESC'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/layanan`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false
            
            this.list.service = response.data

            this.selected.service = this.$route.query.id !== undefined ? parseInt(this.$route.query.id) : 0

            this.selected.layanan_kode = this.list.service[this.selected.service].layanan_kode
          } 
        })
      },
      onSlideChange() {
        this.selected.service = this.$refs.swiper.$swiper.activeIndex
      },
      onSlideChangeIndex(index) {
        this.$refs.swiper.$swiper.slideTo(index, 1000, false)
        this.selected.service = index
      }
    }
  }
</script>