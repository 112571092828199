<template>
  <div class="fill-height pt-14 pb-16 mb-4">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card 
            flat
            color="transparent">
            <v-card-text>
              <v-row>
                <v-col 
                  class="py-2">
                  <no-ssr>
                    <swiper 
                      v-model="selected.service"
                      ref="swiper"
                      class="swiper"
                      :options="swiperOption">
                      <swiper-slide 
                        v-for="(item, index) in process.service ? 10 : list.service" 
                        :key="index" 
                        :class="index < 1 ? 'pr-4' : index < list.service.length ? 'pr-4' : 'px-4'"
                        style="width: auto;">
                        <v-skeleton-loader
                          class="ma-auto"
                          :loading="process.service"
                          type="avatar"
                          tile>
                          <div>
                            <v-card v-if="!process.service && list.service.length > 0"
                              @click="selected.service = index"
                              flat
                              width="45"
                              height="45"
                              :color="selected.service == index ? '#ff64ad1a' : 'white'"
                              class="border-radius d-flex align-center justify-center pa-3"
                              style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px !important;">
                              <div 
                                class="text-center">
                                <v-avatar 
                                  size="30" 
                                  tile>
                                  <v-img 
                                    alt="kominfo" 
                                    :src="item.layanan_image"
                                    class="ma-auto"
                                    style="border-radius: 10px;">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular 
                                          indeterminate 
                                          color="white">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-avatar>

                                <div 
                                  class="text-center font-weight-bold mt-1"
                                  style="font-size: 10px; opacity: 60%;">
                                  {{ item.layanan_kode }}
                                </div>
                              </div>
                            </v-card>
                          </div>
                        </v-skeleton-loader>
                      </swiper-slide>
                    </swiper>
                  </no-ssr>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text
              class="py-0">
              <v-card 
                flat
                color="white"
                style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 10px;">
                <v-skeleton-loader
                  v-for="(item, index) in list.data"
                  :key="index"
                  :loading="process.load"
                  type="article, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line">
                  <div>
                    <div v-if="!process.load && list.data.length > 0">
                      <div
                        class="body-1 font-weight-bold pa-4 pb-2">
                        Persyaratan Pengajuan {{ list.data[0].lc_title }}
                      </div>

                      <v-divider 
                        class="mx-4"
                        style="border: 2px solid #f9f9f9; border-radius: 50px;" />

                      <v-card-text
                        class="pt-3 px-2">
                        <span 
                          class="black--text line-text-second"
                          style="opacity: 60%;"
                          v-html="list.data[0].lc_content">
                        </span>
                      </v-card-text>
                    </div>
                  </div>
                </v-skeleton-loader>
              </v-card>
            </v-card-text>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        swiperOption: {
          slidesPerView: 'auto'
        },
        selected: {
          service: 0
        },
        list: {
          service: [],
          data: []
        },
        pagination: {},
        process: {
          service: false,
          load: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'selected.service': function() {
        this.fetchData()
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      this.fetchService()
    },
    methods: {
      async fetchService () {
        this.process.service = true

        let params = {
          sort: 'DESC'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/layanan`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.service = false
            
            this.list.service = response.data

            this.selected.service = 0

            this.list.service.map((obj, index) => {
              if (this.$route.query.code === obj.layanan_kode) {
                this.selected.service = index
              }
            })

            this.code_api = this.$route.query.code === undefined ? this.list.service[this.selected.service].layanan_kode : this.$route.query.code

            this.fetchData()
          } 
        })
      },
      async fetchData () {
        this.process.load = true

        let params = {
          sort: 'DESC',
          lc_layanan_kode: this.list.service[this.selected.service].layanan_kode
        }

        console.log(params)

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/layanan_content`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false
            
            this.list.data = response.data
            this.pagination = response.pagination
          } 
        })
      }
    }
  }
</script>