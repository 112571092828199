<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex 
        style="height: 100vh;">
        <section>
          <v-img 
            alt="kominfo" 
            :src="require('@/assets/background/bg_top_bottom.png')"
            class="ma-auto"
            style="width: 100%;
                position: absolute;
                top: 0;
                transform: scale(-1, -1);">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  indeterminate 
                  color="#ff64ad">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </section>

        <section>
          <v-row
            align="center"
            style="height: 100vh;">
            <v-col>
              <v-card  
                style="z-index: 1;"
                flat 
                color="transparent">
                <v-card-text>
                  <v-img 
                    width="100"
                    alt="kominfo" 
                    :src="require('@/assets/logo/favicon.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          color="white">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card-text>

                <v-card-text 
                  class="text-h5 font-weight-bold d-flex align-center justify-center black--text mb-4"
                  style="opacity: 60%;">
                  <v-icon color="#ff64ad">mdi-ray-end</v-icon>
                  
                  <span class="mx-2">
                    SAKURA
                  </span>

                  <v-icon color="#ff64ad">mdi-ray-start</v-icon>
                </v-card-text>

                <v-card-text
                  class="text-center">
                  <div
                    class="body-1 font-weight-light line-text-second mb-12">
                    Akun Anda sudah terkirim, silahkan cek email Anda. Kami telah mengirimkan link untuk memverifikasi akun yang Anda daftarkan.
                  </div>

                  <div>
                    <v-btn
                      to="/auth/login"
                      color="#ff64ad"
                      width="150"
                      elevation="0"
                      class="white--text text-capitalize"
                      style="border-radius: 10px;">
                      Login Sekarang
                    </v-btn>
                  </div>

                  <div
                    class="body-2 font-weight-bold my-4"
                    style="opacity: 80%;">
                    Atau
                  </div>

                  <div>
                    <v-btn
                      to="/auth/register"
                      color="orange"
                      width="150"
                      elevation="0"
                      class="white--text text-capitalize"
                      style="border-radius: 10px;">
                      Daftar Akun
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </section>

        <section>
          <v-img 
            alt="kominfo" 
            :src="require('@/assets/background/bg_top_bottom.png')"
            class="ma-auto"
            style="width: 100%;
                position: absolute;
                bottom: 0;">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  indeterminate 
                  color="#ff64ad">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'notAuthenticated',
    data () {
      return {
        // SEO
        content: {
          url: '/auth/check',
          title: 'Cek Email',
          description: 'Cek Email Sakura Dukcapil Klaten (Sistem Layanan Administrasi Kependudukan Dalam Jaringan)',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>