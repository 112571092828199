<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card 
            flat
            color="white"
            style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 10px;">
            <div
                class="body-2 black--text font-italic line-text-second pa-4 pb-0"
                style="opacity: 60%;">
                Silakan pelajari ketentuannya, siapkan persyaratannya, lalu klik tombol <strong>+</strong> untuk membuat ajuan baru.
              </div>
              
            <div
              class="body-1 font-weight-bold pa-4 pb-2">
              Persyaratan Pengajuan Kutipan Kedua
            </div>

            <v-divider 
              class="mx-4"
              style="border: 2px solid #f9f9f9; border-radius: 50px;" />

            <v-card-text
              class="pt-3 px-2">
              <ol
                class="black--text line-text-second"
                style="opacity: 60%;">
                <li>
                  Belum diketahui syaratnya.
                </li>
              </ol> 
            </v-card-text>
          </v-card>

          <v-card 
            flat
            color="#ff64ad1a"
            class="mt-4"
            style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 10px;">
            <v-card-title
              class="body-1 font-weight-bold font-italic color-default pb-2">
              #Catatan Penting !!!
            </v-card-title>

            <v-divider 
              class="mx-4"
              style="border: 2px solid #ff64ad; border-radius: 50px;" />

            <v-card-text
              class="pt-3 px-2">
              <ul
                class="color-default line-text-second"
                style="opacity: 60%;">
                <li>
                  Data yang diinputkan harus sesuai dokumen pendukung yang dilapirkan.
                </li>
                
                <li>
                  Lampiran harus foto dokumen asli.
                </li>
              </ul> 
            </v-card-text>
          </v-card>

          <v-fab-transition>
            <v-btn
              color="#ff64ad"
              elevation="5"
              fixed
              dark
              bottom
              right
              fab>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-fab-transition>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        form: {
          
        },
        process: {
          
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      
    },
    watch: {
      
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>

<style scoped>
  .v-btn--absolute.v-btn--bottom, .v-btn--fixed.v-btn--bottom {
    bottom: 75px !important;
  }
</style>