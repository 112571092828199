<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex 
        style="min-height: 100vh;">
        <section>
          <v-card 
            flat 
            color="#ff64ad"
            height="200"
            style="border-radius: 0px 0px 50px 50px;">
            <v-list 
              flat 
              color="transparent"
              class="pa-0">
              <v-list-item 
                style="border-radius: 0px;">
                <v-list-item-avatar 
                  size="45"
                  tile>
                  <v-img 
                    contain
                    alt="kominfo" 
                    :src="require(`@/assets/image/time/${isDay}.png`)"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          color="#FFFFFF">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-subtitle 
                    class="white--text mb-1">
                    Selamat {{ isDay }},
                  </v-list-item-subtitle>

                  <v-list-item-title 
                    class="body-1 font-weight-bold white--text">
                    Di Sakura Dukcapil Klaten
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn
                    to="/auth/login"
                    fab
                    width="40"
                    height="40"
                    color="#FFFFFF40"
                    elevation="0"
                    style="border: 3px solid #FFFFFF60 !important;">
                    <v-icon size="22" color="#FFFFFF">mdi-login</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-card-text>
              <no-ssr>
                <carousel-3d 
                  class="mt-2 mb-0"
                  ref="carousel3dBanner"
                  :autoplay="true"
                  :autoplay-timeout="5000"
                  :perspective="0"
                  :border="0"
                  :space="0"
                  :height="210"
                  :count="list.banner.length"
                  :display="pagination.banner.total_display"
                  @after-slide-change="onAfterSlideChange">
                  <slide 
                    v-for="(item, index) in process.banner ? 3 : list.banner"
                    :key="index"
                    :index="index"
                    style="background: none;">
                    <v-skeleton-loader
                      class="ma-auto"
                      :loading="process.banner"
                      type="image">
                      <div>
                        <v-img v-if="!process.banner && list.banner.length > 0"
                          width="100%"
                          height="185"
                          alt="kominfo" 
                          :src="item.image"
                          gradient="to top, rgba(0, 0, 0, .7), rgba(0, 0, 0, 0)"
                          class="ma-auto"
                          style="border-radius: 20px; position: relative;">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#ff64ad">
                              </v-progress-circular>
                            </v-row>
                          </template>

                          <div 
                            class="white--text ma-4"
                            style="position: absolute; bottom: 5px;"> 
                            <div
                              class="body-1 font-weight-bold">
                              {{ item.title }}
                            </div>

                            <div
                              class="body-2 font-weight-light">
                              <span
                                v-html="item.content">
                              </span>
                            </div>
                          </div>

                          <div 
                            class="text-center"
                            style="position: absolute; bottom: 10px; left: 0; right: 0;"> 
                            <v-btn 
                              v-for="(item, index) in list.banner" 
                              :key="index"
                              :width="selected.banner === index ? 5 : 8"
                              :height="selected.banner === index ? 10 : 8"
                              elevation="0"
                              x-small
                              :rounded="selected.banner === index"
                              :fab="selected.banner !== index"
                              :color="selected.banner === index ? '#ff64ad' : '#fff'"
                              class="mx-1"
                              @click="goToSlide(index)">
                            </v-btn>
                          </div>
                        </v-img>
                      </div>
                    </v-skeleton-loader>
                  </slide>
                </carousel-3d>
              </no-ssr>
            </v-card-text>

            <div
              class="my-2 mb-3">
              <v-img 
                contain
                width="230"
                height="auto"
                alt="kominfo" 
                :src="require('@/assets/image/logo_berakhlak.png')"
                class="ma-auto">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      indeterminate 
                      color="#ff64ad">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </div>

            <v-card-title
              class="d-flex justify-center title pt-2 black--text line-text-second">
              Layanan Kami
            </v-card-title>
            
            <v-card-subtitle 
              class="body-2 font-weight-light text-center pb-0">
              Sistem Administrasi Kependudukan
            </v-card-subtitle>

            <v-card-text
              class="py-0">
              <v-row 
                justify="center">
                <v-col 
                  cols="2">
                  <v-divider style="border: 5px solid #ff64ad; border-radius: 50px;"/>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text
              class="pb-0">
              <v-row>
                <v-col
                  class="px-0">
                  <no-ssr>
                    <swiper 
                      v-model="selected.service"
                      ref="swiper"
                      class="swiper"
                      :options="swiperOption">
                      <swiper-slide 
                        v-for="(item, index) in process.load ? 10 : list.service" 
                        :key="index" 
                        :class="index < 1 ? 'pl-3 pr-4' : index < list.service.length - 1 ? 'pr-4' : 'pl-4 pr-3'"
                        style="width: auto;">
                        <v-skeleton-loader
                          class="ma-auto"
                          :loading="process.load"
                          type="avatar"
                          tile>
                          <div>
                            <v-card v-if="!process.service && list.service.length > 0"
                              @click="selected.service = index; fetchGraphic();"
                              :color="selected.service == index ? '#ff64ad1a' : 'white'"
                              flat
                              width="75"
                              height="75"
                              class="pa-2"
                              style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
                                    border-radius: 10px;">
                              <v-img 
                                width="40"
                                height="auto"
                                alt="kominfo" 
                                :src="item.layanan_image"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      indeterminate 
                                      color="#ff64ad">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>

                              <div 
                                class="text-center font-weight-bold mt-3"
                                style="font-size: 10px; 
                                      opacity: 60%;
                                      line-height: 1.2;">
                                {{ item.layanan_kode }}
                              </div>

                              <div 
                                class="text-center one-line"
                                style="font-size: 8px; opacity: 40%;">
                                ({{ item.layanan_nama }})
                              </div>
                            </v-card>
                          </div>
                        </v-skeleton-loader>
                      </swiper-slide>
                    </swiper>
                  </no-ssr>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text>
              <v-card
                flat
                style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
                      border-radius: 10px;">
                <div
                  class="mb-2"
                  style="background: #FF64AD;">
                  <v-card-title
                    class="body-1 font-weight-bold white--text pt-2">
                    Statistik Ajuan <span v-if="list.service.length > 0" class="ml-1">{{ list.service[selected.service].layanan_kode }}</span>
                  </v-card-title>

                  <v-card-subtitle
                    class="body-2 font-weight-light white--text pb-2">
                    Sampai Dengan <b>{{ clock.date | date }}</b>
                  </v-card-subtitle>
                </div>

                <v-card-text
                  :class="process.graphic ? 'pa-4' : 'pa-0'">
                  <v-skeleton-loader
                    class="ma-auto"
                    :loading="process.graphic"
                    type="image">
                    <div>
                      <div v-if="!process.graphic && Object.keys(this.list.bar).length > 0">
                        <apexchart 
                          height="269" 
                          :options="list.bar.chartOptions" 
                          :series="list.bar.series">
                        </apexchart>
                      </div>
                    </div>
                  </v-skeleton-loader>
                </v-card-text>
              </v-card>
            </v-card-text>

            <v-card-text
              class="py-0">
              <v-row>
                <v-col
                  cols="7">
                  <v-card
                    flat
                    height="224"
                    class="pa-3"
                    style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
                          border-radius: 10px;">
                    <div
                      class="d-flex align-center">
                      <v-avatar 
                        tile
                        size="20">
                        <v-img
                          contain
                          width="30"
                          height="30"
                          alt="kominfo"
                          :src="require('@/assets/image/doc.png')">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                size="20"
                                indeterminate 
                                color="#ff64ad">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-avatar>

                      <span
                        class="body-2 ml-2"
                        style="opacity: 60%; font-weight: 500;">
                        Dokumen
                      </span>
                    </div>

                    <div
                      class="mt-5">
                      <div
                        class="mb-6">
                        <div
                          class="caption mb-1"
                          style="opacity: 60%;">
                          Ajuan
                        </div>

                        <div
                          class="d-flex align-start">
                          <v-icon size="10" color="blue" class="mt-1">mdi-circle</v-icon>

                          <span
                            class="text-h4 black--text ml-1"
                            style="opacity: 70%;">
                            {{ count.ajuan | numeric }}
                          </span>
                        </div>
                      </div>

                      <div>
                        <div
                          class="caption mb-1"
                          style="opacity: 60%;">
                          Produk
                        </div>

                        <div
                          class="d-flex align-start">
                          <v-icon size="10" color="green" class="mt-1">mdi-circle</v-icon>

                          <span
                            class="text-h4 black--text ml-1"
                            style="opacity: 70%;">
                            {{ count.product | numeric }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-col>

                <v-col
                  cols="5">
                  <v-card
                    flat
                    height="100"
                    class="pa-3 mb-6"
                    style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
                          border-radius: 10px;">
                    <div
                      class="d-flex align-center">
                      <v-avatar 
                        tile
                        size="20">
                        <v-img
                          contain
                          width="30"
                          height="30"
                          alt="kominfo"
                          :src="require('@/assets/image/user.png')">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                size="20"
                                indeterminate 
                                color="#ff64ad">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-avatar>

                      <span
                        class="body-2 ml-2"
                        style="opacity: 60%; font-weight: 500;">
                        Pengguna
                      </span>
                    </div>

                    <div
                      class="d-flex align-start mt-5">
                      <v-icon size="10" color="green" class="mt-1">mdi-circle</v-icon>

                      <span
                        class="text-h5 black--text ml-1"
                        style="opacity: 80%;">
                        {{ count.user | numeric }}
                      </span>
                    </div>
                  </v-card>

                  <v-card
                    flat
                    height="100"
                    class="pa-3"
                    style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
                          border-radius: 10px;">
                    <div
                      class="d-flex align-center">
                      <v-avatar 
                        tile
                        size="20">
                        <v-img
                          contain
                          width="30"
                          height="30"
                          alt="kominfo"
                          :src="require('@/assets/image/team.png')">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                size="20"
                                indeterminate 
                                color="#ff64ad">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-avatar>

                      <span
                        class="body-2 ml-2"
                        style="opacity: 60%; font-weight: 500;">
                        Petugas
                      </span>
                    </div>

                    <div
                      class="d-flex align-start mt-5">
                      <v-icon size="10" color="green" class="mt-1">mdi-circle</v-icon>

                      <span
                        class="text-h5 black--text ml-1"
                        style="opacity: 80%;">
                        {{ count.operator | numeric }}
                      </span>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text
              class="mb-4">
              <v-card
                flat
                color="#ff64ad"
                style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px !important;
                      border-radius: 15px;">
                <v-card-text
                  class="py-2">
                  <v-list 
                    dense 
                    three-line
                    color="transparent" 
                    class="py-0">
                    <v-list-item 
                      class="px-0">
                      <v-list-item-avatar 
                        tile
                        size="75">
                        <v-img 
                          alt="kominfo" 
                          :src="require(`@/assets/image/register.png`)"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="white">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <div 
                          class="title text-capitalize font-weight-bold white--text one-line mb-2">
                          Belum punya akun?
                        </div>
                        
                        <div 
                          class="body-2 line-text-second white--text mb-4">
                          Daftar sekarang untuk pengajuan atau perubahan dokumen Anda.
                        </div>

                        <div
                          class="text-right mb-1">
                          <v-btn
                            to="/auth/register"
                            small
                            elevation="0"
                            class="caption font-weight-bold color-default text-capitalize"
                            style="border-radius: 15px;">
                            Daftar Sekarang

                            <v-icon small class="ml-1">mdi-arrow-right-thick</v-icon>
                          </v-btn>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-card-text>

            <v-card-text v-if="process.news || list.news.length > 0"
              class="d-flex align-start pl-0 py-0">
              <div>
                <v-card-title
                  class="title black--text pt-0">
                  Berita Terkini
                </v-card-title>

                <v-card-subtitle
                  class="body-2 font-weight-light pb-2">
                  Berita terkini untuk Anda.
                </v-card-subtitle>
              </div>

              <v-spacer />

              <nuxt-link v-if="pagination.news.total_data > 5"
                to="/news">
                <span
                  class="caption d-flex align-center justify-end black--text">
                  <span
                    style="opacity: 40%;">
                    Lihat semua
                  </span>

                  <v-icon 
                    small 
                    class="ml-1"
                    style="opacity: 60%;">
                    mdi-chevron-double-right
                  </v-icon>
                </span>
              </nuxt-link>
            </v-card-text>

            <div>
              <no-ssr>
                <swiper 
                  class="swiper"
                  ref="swiper"
                  :options="swiperOption">
                  <swiper-slide 
                    v-for="(item, index) in process.news ? 5 : list.news" :key="index" 
                    class="py-3" 
                    :class="index < 1 ? 'px-4' : index < list.news.length ? 'pr-4' : 'px-4'"
                    style="width: auto;">
                    <v-skeleton-loader
                      class="ma-auto"
                      width="175"
                      :loading="process.news"
                      type="card, article">
                      <div>
                        <v-card v-if="!process.news && list.news.length > 0"
                          :to="`/news/${item.id}`"
                          class="border-radius"
                          style="box-shadow: rgba(0, 0, 0, 0.01) 0px 3px 5px;"
                          width="175">
                          <v-img
                            width="100%"
                            height="130"
                            alt="kominfo" 
                            :src="item.post_image[0].url"
                            class="ma-auto">
                            <v-card flat
                              color="transparent"
                              class="d-flex align-center pa-3"
                              style="border-radius: 50px;">
                              <v-avatar size="20">
                                <v-img 
                                  alt="kominfo" 
                                  :src="item.post_extra.createdby.image"
                                  class="ma-auto">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular 
                                        indeterminate 
                                        color="#ff64ad"
                                        size="15">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-avatar>

                              <div 
                                class="caption text-capitalize font-weight-bold one-line white--text pl-2">
                                {{ item.post_extra.createdby.name }}
                              </div>
                            </v-card>

                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#ff64ad">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>

                          <div class="pa-3">
                            <div class="body-2 font-weight-bold one-line">
                              {{ item.post_title }}
                            </div>

                            <div class="caption font-weight-light two-line my-2" style="opacity: 60%;">
                              <span v-html="item.post_content.replace(/(<([^>]+)>)/ig, '')"></span>
                            </div>

                            <div class="d-flex align-center">
                            <v-icon small color="#212121">mdi-tag-multiple</v-icon>

                            <span class="caption one-line ml-1">
                              {{ item.post_cat_title }}
                            </span>
                          </div>

                            <v-divider class="my-2" style="border: 2px solid #f9f9f9;" />

                            <div class="caption d-flex align-center" style="opacity: 60%;">
                              <div>
                                {{ item.post_create_datetime | date }}
                              </div>

                              <v-spacer />

                              <div>
                                {{ item.post_create_datetime | time }}
                              </div>
                            </div>
                          </div>
                        </v-card>
                      </div>
                    </v-skeleton-loader>
                  </swiper-slide>
                </swiper>
              </no-ssr>
            </div>

            <v-card-text>
              <v-row
                align="center"
                class="mb-16">
                <v-col
                  cols="3">
                  <v-img
                    contain
                    alt="kominfo"
                    :src="require('@/assets/image/pt_pos_2.png')"
                    style="transform: scaleX(-1);">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          color="#ff64ad">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>

                <v-col
                  cols="9">
                  <v-card
                    flat
                    color="#ff64ad"
                    style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px !important;
                          border-radius: 15px;">
                    <v-card-text
                      class="py-2">
                      <v-list 
                        dense 
                        three-line
                        color="transparent" 
                        class="py-0">
                        <v-list-item 
                          class="px-0">
                          <v-list-item-content>
                            <div 
                              class="text-capitalize white--text mb-2">
                              <div
                                class="body-1 font-weight-bold">
                                Bekerjasama dengan 
                              </div>
                              
                              <div
                                class="title font-weight-bold ">
                                PT POS Indonesia
                              </div>
                            </div>
                            
                            <div 
                              class="body-2 line-text-second white--text">
                              Kami telah bekerjasama dengan PT POS Indonesia untuk kemudahan pengiriman dokumen Anda.
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </section>

        <div
          class="pa-2"
          style="background: #FF64AD; 
              height: 50px; 
              position: absolute;
              bottom: 0;
              width: 100%;
              z-index: 11;
              border-radius: 15px 15px 0px 0px;">
          <div
            class="d-flex align-center">
            <div
              class="caption line-text-first white--text mr-6">
              Jika mengalami masalah, silahkan hubungi kontak kami untuk mendapatkan informasi lebih lanjut.
            </div>

            <v-spacer />

            <div>
              <a
                href="https://api.whatsapp.com/send/?phone=6282133607337&text&type=phone_number&app_absent=0"
                target="_blank">
                <v-avatar 
                  size="36" 
                  tile>
                  <v-img 
                    alt="kominfo" 
                    :src="require('@/assets/image/whatsapp.png')"
                    class="ma-auto"
                    style="border-radius: 10px;">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          size="20"
                          indeterminate 
                          color="white">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>
              </a>
            </div>
          </div>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'notAuthenticated',
    data () {
      return {
        swiperOption: {
          slidesPerView: 'auto'
        },

        clock: {
          date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          time: ''
        },

        selected: {
          banner: 0,
          news: 0,

          service: 0
        },

        list: {
          banner: [],
          service: [],
          news: [],

          bar: {
            series: [],
            chartOptions: {
              chart: {
                animations: {
                  enabled: true,
                  easing: 'linear',
                  dynamicAnimation: {
                    speed: 1000
                  }
                },
                toolbar: {
                  show: true
                },
                zoom: {
                  enabled: true
                }
              },
              stroke: {
                width: 3,
                curve: 'smooth'
              },
              dataLabels: {
                enabled: false
              },
              colors: ['#FF64AD'],
              yaxis: {
                labels: {
                  formatter: function (value) {
                    return Math.round(value)
                  }
                }
              },
              xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des']
              }
            }
          }
        },

        count: {
          ajuan: 0,
          product: 0,
          user: 0,
          operator: 0
        },

        pagination: {
          banner: {},
          news: {}
        },

        process: {
          banner: false,
          service: false,
          count: false,
          news: false,

          graphic: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/',
          title: 'Sakura',
          description: 'Sakura Dukcapil Klaten (Sistem Layanan Administrasi Kependudukan Dalam Jaringan)',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      fetch () {
        this.fetchBanner()
        this.fetchService()
        this.fetchCount()
        this.fetchNews()
      },

      async fetchBanner () {
        this.process.banner = true

        let params = {
          type: 'banner'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/site`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.banner = false
            
            this.list.banner = response.data
            this.pagination.banner = response.pagination
          } 
        })
      },
      async fetchService () {
        this.process.service = true

        let params = {
          sort: 'DESC'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/info/layanan`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.service = false
            
            this.list.service = response.data

            if (this.list.service.length > 0) this.fetchGraphic()
          } 
        })
      },
      async fetchGraphic () {
        this.process.graphic = true

        let params = {
          layanan_kode: this.list.service[this.selected.service].layanan_kode
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/info/layanan_graphic`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.graphic = false
            
            this.list.bar = {
              series: response.data.graphic_data,
              chartOptions: {
                chart: {
                  animations: {
                    enabled: true,
                    easing: 'linear',
                    dynamicAnimation: {
                      speed: 1000
                    }
                  },
                  toolbar: {
                    show: true
                  },
                  zoom: {
                    enabled: true
                  }
                },
                stroke: {
                  width: 3,
                  curve: 'smooth'
                },
                dataLabels: {
                  enabled: false
                },
                colors: ['#FF64AD'],
                yaxis: {
                  labels: {
                    formatter: function (value) {
                      return Math.round(value)
                    }
                  }
                },
                xaxis: {
                  categories: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des']
                }
              }
            }
          } 
        })
      },
      async fetchCount () {
        this.process.count = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/info/data_count`)
        .then((response) => {
          if (response.code === 200) {
            this.process.count = false

            this.count = response.data
          } 
        })
      },
      async fetchNews () {
        this.process.news = true

        let params = {
          post_type: 'news',
          post_status: 'publish',
          limit: 5
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/post`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.news = false
            
            this.list.news = response.data
            this.pagination.news = response.pagination
          } 
        })
      },

      onAfterSlideChange(index) {
        this.selected.banner = index
      },
      goToSlide(index) {
        this.selected.banner = index
        this.$refs.carousel3dBanner.goSlide(index)
      },
      goToSlidePrev(index) {
        this.selected.banner = index === 0 ? this.list.banner.length - 1 : this.selected.banner - 1
        this.$refs.carousel3dBanner.goSlide(this.selected.banner)
      },
      goToSlideNext(index) {
        this.selected.banner = index === this.list.banner.length - 1 ? 0 : this.selected.banner + 1
        this.$refs.carousel3dBanner.goSlide(this.selected.banner)
      },

      onSlideChange() {
        this.selected.service = this.$refs.swiper.$swiper.activeIndex
      },
      onSlideChangeIndex(index) {
        this.$refs.swiper.$swiper.slideTo(index, 1000, false)
        this.selected.service = index
      }
    }
  }
</script>