<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card v-if="process.load && Object.keys(detail).length < 1"
            flat
            color="transparent"
            class="mt-12">
            <v-skeleton-loader
              :loading="process.load"
              type="image, article, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line">
            </v-skeleton-loader>
          </v-card>

          <v-card v-if="!process.load && Object.keys(detail).length > 0"
            flat 
            color="transparent">
            <v-card
              flat
              color="#ff64ad"
              class="pt-12 pb-4"
              style="border-radius: 0px 0px 15px 15px;">
              <v-card-title
                class="white--text pb-1">
                #Pengajuan {{ detail.ajuan_layanan_kode }}
              </v-card-title>

              <v-card-text
                class="white--text line-text-second pb-1">
                <v-divider 
                  class="mb-3"
                  style="border: 2px solid #fff; border-radius: 50px;" />
                  
                <div>
                  No. Reg: {{ detail.ajuan_no_reg }}
                </div>

                <div>
                  Nama Lengkap: {{ detail.ajuan_data_ajuan.field.nama_lengkap }}
                </div>

                <div>
                  NIK: {{ detail.ajuan_data_ajuan.field.nik }}
                </div>

                <div>
                  Jenis Pengajuan: {{ detail.ja_judul === '' ? '-' : detail.ja_judul }}
                </div>

                <div>
                  Alasan: {{ detail.ajuan_keterangan === '' ? '-' : detail.ajuan_keterangan }}
                </div>

                <div
                  class="font-weight-bold mt-3">
                  Alamat:
                </div>

                <div>
                  Kecamatan: {{ detail.ajuan_kecamatan_name }} ({{ detail.ajuan_kecamatan_code }})
                </div>

                <div>
                  Kelurahan: {{ detail.ajuan_kelurahan_name }} ({{ detail.ajuan_kelurahan_code }})
                </div>

                <v-divider 
                  class="my-3"
                  style="border: 2px solid #fff; border-radius: 50px;" />

                <div
                  class="caption font-weight-bold d-flex align-center mt-4 mb-4">
                  <div
                    class="d-flex align-center">
                    <v-icon small color="white" class="mr-1">mdi-calendar-month</v-icon>

                    {{ detail.ajuan_create_datetime | date }}
                  </div>

                  <v-spacer />

                  <div
                    class="d-flex align-center">
                    <v-icon small color="white" class="mr-1">mdi-clock-time-three-outline</v-icon>

                    {{ detail.ajuan_create_datetime | time }}
                  </div>
                </div>

                <div
                  class="white--text pa-2"
                  style="border: 1px solid #fff;
                        border-radius: 10px;
                        border-style: dashed;
                        background: #ffffff33;">
                  <v-chip
                    class="white--text px-2 mb-1"
                    :color="detail.ajuan_is_mandiri ? 'green' : 'blue'"
                    x-small>
                    <!-- {{ detail.ajuan_is_mandiri ? 'Mandiri' : 'Group' }} -->
                    {{ detail.ajuan_pelapor_role_name }}
                  </v-chip>
                  
                  <div>
                    Pengaju / Pelapor: {{ detail.pelapor_fullname }}
                  </div>

                  <div>
                    NIK: {{ detail.ajuan_pelapor_nik }}
                  </div>

                  <div>
                    Email: {{ detail.ajuan_is_mandiri ? detail.pelapor_email : detail.pelapor_extra.instansi_email }}
                  </div>

                  <div>
                    Telepon: {{ detail.ajuan_is_mandiri ? detail.pelapor_phone : detail.pelapor_extra.instansi_phone }}
                  </div>
                </div>

                <v-btn v-if="detail.is_have_produk"
                  @click="sheet.product = !sheet.product ? true : false"
                  block
                  elevation="0"
                  :color="!sheet.product ? 'green' : '#ffffff33'"
                  class="font-weight-bold mt-5 white--text text-capitalize"
                  style="border-radius: 10px;">
                  {{ !sheet.product ? 'Lihat Produk' : 'Lihat Riwayat' }}
                </v-btn>
                

                <v-btn v-if="!detail.is_have_review && detail.ajuan_status === 'SELESAI DIPROSES'"
                  @click="sheet.review = true; form_review.ajuan_id = detail.ajuan_id;"
                  block
                  elevation="0"
                  color="orange"
                  class="font-weight-bold mt-5 white--text text-capitalize"
                  style="border-radius: 10px;">
                  Beri Ulasan
                </v-btn>
              </v-card-text>
            </v-card>
            
            <v-card-text v-if="!sheet.product"
              class="pl-0 py-0">
              <v-timeline
                align-center
                dense>
                <v-timeline-item
                  v-for="(item, index) in list"
                  :key="index"
                  :color="item.log_status === 'BELUM DIVERIFIKASI' ? 'orange' :
                          item.log_status === 'PROSES VERIFIKASI' ? 'blue' :
                          item.log_status === 'DISETUJUI' ? 'cyan' :
                          item.log_status === 'DISETUJUI TANPA NIK' ? 'indigo' :
                          item.log_status === 'DISETUJUI TERBIT NIK' ? 'lime' :
                          item.log_status === 'DIKOREKSI' ? 'purple' :
                          item.log_status === 'SELESAI DIPROSES' ? 'green' : 'red'"
                  small>
                  <v-row
                    @click="selected.detail = item; sheet.detail = true;"
                    class="cursor-pointer">
                    <v-col 
                      cols="3">
                      <strong
                        class="black--text"
                        style="opacity: 60%;">
                        {{ item.log_create_datetime | time }}
                      </strong>
                    </v-col>

                    <v-col>
                      <strong
                        :class="item.log_status === 'BELUM DIVERIFIKASI' ? 'orange--text' :
                                item.log_status === 'PROSES VERIFIKASI' ? 'blue--text' :
                                item.log_status === 'DISETUJUI' ? 'cyan--text' :
                                item.log_status === 'DISETUJUI TANPA NIK' ? 'indigo--text' :
                                item.log_status === 'DISETUJUI TERBIT NIK' ? 'lime--text' :
                                item.log_status === 'DIKOREKSI' ? 'purple--text' :
                                item.log_status === 'SELESAI DIPROSES' ? 'green--text' : 'red--text'">
                        {{ item.log_status }}
                      </strong>

                      <div
                        class="caption font-weight-light one-line line-text-second">
                        <span
                          v-html="item.log_note">
                        </span>
                      </div>

                      <div 
                        class="caption font-weight-bold black--text"
                        style="opacity: 60%;">
                        {{ item.log_create_datetime | date }}
                      </div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>

            <v-card-text v-if="sheet.product"
              class="pt-3 pb-0">
              <Product
                :detail="detail" />
            </v-card-text>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.detail"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            style="border-radius: 0px;">
            <v-card-title 
              @click="sheet.detail = false"
              class="cursor-pointer pa-4"
              style="border-radius: 0px 0px 15px 15px;
                    position: absolute;
                    width: 100%;
                    z-index: 1;
                    background: #ff64ad;">
              <v-icon color="white">
                mdi-arrow-left-thick
              </v-icon>

              <span
                class="body-1 font-weight-bold text-capitalize white--text ml-1">
                Detail Ajuan
              </span>
            </v-card-title>

            <v-card-text v-if="Object.keys(selected.detail).length > 0"
              class="body-2 font-weight-light text-first line-text-second mt-16">
              <div
                class="title black--text font-weight-bold mt-2">
                #{{ selected.detail.log_ajuan_no_reg }}
              </div>

              <div
                class="mb-2">
                {{ selected.detail.log_layanan_kode }} - 

                <span
                  :class="selected.detail.log_status === 'BELUM DIVERIFIKASI' ? 'orange--text' :
                          selected.detail.log_status === 'PROSES VERIFIKASI' ? 'blue--text' :
                          selected.detail.log_status === 'DISETUJUI' ? 'cyan--text' :
                          selected.detail.log_status === 'DISETUJUI TANPA NIK' ? 'indigo--text' :
                          selected.detail.log_status === 'DISETUJUI TERBIT NIK' ? 'lime--text' :
                          selected.detail.log_status === 'DIKOREKSI' ? 'purple--text' :
                          selected.detail.log_status === 'SELESAI DIPROSES' ? 'green--text' : 'red--text'">
                  {{ selected.detail.log_status }}
                </span>
              </div>

              <div v-if="selected.detail.log_status === 'DITOLAK'"
                class="pa-2 px-3 red--text log_note"
                style="border: 1px solid #fff;
                      border-radius: 7px;
                      border-style: dashed;
                      background: #ff000033;">
                <span
                  v-html="selected.detail.log_note">
                </span>
              </div>

              <div
                class="my-3"
                style="opacity: 60%;">
                Tanggal <b>{{ selected.detail.log_create_datetime | date }}</b> Pukul <b>{{ selected.detail.log_create_datetime | time }}</b>
              </div>

              <div
                class="font-weight-bold black--text">
                Nama Lengkap:
              </div>
              
              <div
                class="mb-2">
                {{ selected.detail.log_extra.field.nama_lengkap }}
              </div>

              <div
                class="font-weight-bold black--text">
                NIK:
              </div>
              
              <div
                class="mb-2">
                {{ selected.detail.log_extra.field.nik }}
              </div>

              <div
                class="font-weight-bold black--text">
                Kecamatan:
              </div>
              
              <div
                class="mb-2">
                {{ selected.detail.log_extra.field.kecamatan_name }} ({{ selected.detail.log_extra.field.kecamatan_code }})
              </div>

              <div
                class="font-weight-bold black--text">
                Kelurahan:
              </div>
              
              <div
                class="mb-2">
                {{ selected.detail.log_extra.field.kelurahan_name }} ({{ selected.detail.log_extra.field.kelurahan_code }})
              </div>

              <div
                class="font-weight-bold black--text">
                Keterangan:
              </div>

              <div
                class="mb-2">
                <span
                  v-html="selected.detail.log_note">
                </span>
              </div>

              <div v-if="selected.detail.log_extra.data_dukung.length > 0"
                class="font-weight-bold black--text">
                Data Dukung:
              </div>

              <div
                v-for="(item, index) in selected.detail.log_extra.data_dukung"
                :key="index"
                :class="index < selected.detail.log_extra.data_dukung.length - 1 ? 'mb-2' : ''">
                <div>
                  #{{ item.name }}
                </div>

                <v-row>
                  <v-col
                    v-for="(item_file, index_file) in item.file"
                    :key="index_file"
                    cols="6">
                    <v-img
                      width="100%"
                      height="125"
                      alt="vuetify"
                      gradient="to bottom, rgba(0, 0, 0, .1), rgba(0, 0, 0, .5)"
                      :src="item_file.url"
                      style="border-radius: 10px;">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="#ff64ad">
                          </v-progress-circular>
                        </v-row>
                      </template>

                      <a
                        :href="item_file.url"
                        target="_blank">
                        <v-icon
                          class="cursor-pointer pa-2"
                          size="20"
                          color="white"
                          style="position: absolute;
                                top: 0px;
                                right: 25px;">
                          mdi-download
                        </v-icon>
                      </a>

                      <v-icon 
                        @click="sheet.zoom = true; selected.title = item.name; selected.zoom = item_file.url;"
                        class="cursor-pointer pa-2"
                        size="20"
                        color="white"
                        style="position: absolute;
                              top: 0px;
                              right: 0px;">
                        mdi-fullscreen
                      </v-icon>
                    </v-img>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>

            <v-card-actions v-if="detail.ajuan_status === 'DITOLAK' && selected.detail.log_status === 'DITOLAK'"
              class="mb-3 pt-0">
              <v-btn
                @click="sheet.update = true; fetchService(detail);"
                block
                elevation="0"
                color="orange"
                class="body-2 font-weight-bold white--text text-capitalize d-flex align-center"
                style="border-radius: 10px;">
                <v-icon size="20">mdi-text-box-edit-outline</v-icon>

                <v-spacer />
                
                <span
                  class="ml-1">
                  Koreksi Data Sekarang ?
                </span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.zoom"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            style="border-radius: 0px;">
            <v-card-title 
              @click="sheet.zoom = false"
              class="cursor-pointer pa-4"
              style="border-radius: 0px 0px 15px 15px;
                    position: absolute;
                    width: 100%;
                    z-index: 1;
                    background: #ff64ad;">
              <div
                class="d-flex align-center">
                <v-icon color="white">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  class="body-1 font-weight-bold text-capitalize white--text ml-1">
                  <span class="one-line">
                    Data Dukung ({{ selected.title }})
                  </span>
                </span>
              </div>
            </v-card-title>

            <v-card-text
              class="pt-16 mt-4 pa-4">
              <v-card
                flat
                style="border-radius: 10px;">
                <v-img
                  contain
                  alt="vuetify"
                  :src="selected.zoom">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        color="#ff64ad">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-card>
            </v-card-text>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.update"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            style="border-radius: 0px;">
            <v-card-title 
              @click="sheet.update = false"
              class="cursor-pointer pa-4"
              style="border-radius: 0px 0px 15px 15px;
                    position: absolute;
                    width: 100%;
                    z-index: 1;
                    background: #ff64ad;">
              <v-icon color="white">
                mdi-arrow-left-thick
              </v-icon>

              <span
                class="body-1 font-weight-bold text-capitalize white--text ml-1">
                Koreksi Ajuan
              </span>
            </v-card-title>

            <v-card-text v-if="Object.keys(config.service).length > 0"
              class="mt-16 pt-4 px-4">
              <ValidationObserver 
                ref="form">
                <v-form>
                  <ValidationProvider v-if="config.service.layanan_jenis_ajuan.length > 0"
                    name="type" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Jenis Pengajuan
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>
                    
                    <v-select
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      color="#ff64ad"
                      placeholder="Contoh. Perubahan Data"
                      :append-icon="!valid ? '' : 'mdi-check-circle-outline'"
                      
                      :items="config.service.layanan_jenis_ajuan"
                      item-text="ja_judul"
                      item-value="ja_id"
                      item-color="#ff64ad"

                      v-model="form.jenis_ajuan_id"
                      :success="valid"
                      :error-messages="errors"
                      required
                      style="border-radius: 10px;">
                    </v-select>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="nik" 
                    rules="required|min:16|max:16" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      NIK
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      color="#ff64ad"
                      type="number"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      min="0"
                      placeholder="Contoh. 33100062XXXXXXX"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                      :counter="16"
                      v-model="form.nik"
                      v-uppercase
                      :success="valid"
                      :error-messages="errors"
                      required
                      style="border-radius: 10px;">
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="nama_lengkap" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Nama Lengkap
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      color="#ff64ad"
                      placeholder="Contoh. Fabdulrafi"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                      v-model="form.nama_lengkap"
                      v-uppercase
                      :success="valid"
                      :error-messages="errors"
                      required
                      style="border-radius: 10px;">
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="kecamatan_code" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Kecamatan
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>

                    <v-autocomplete
                      filled
                      rounded
                      dense
                      color="#ff64ad"
                      placeholder="Contoh. Trucuk"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      :search-input.sync="autocomplete.subdistrict"
                      :loading="process.subdistrict"
                      
                      :items="config.subdistrict"
                      item-value="kecamatan_code"
                      item-text="kecamatan_name"
                      item-color="#ff64ad"

                      v-model="form.kecamatan_code"
                      :success="valid"
                      :error-messages="errors"
                      required
                      style="border-radius: 10px;">
                    </v-autocomplete>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="kelurahan_code" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Kelurahan
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>

                    <v-autocomplete
                      filled
                      rounded
                      dense
                      color="#ff64ad"
                      placeholder="Contoh. Kradenan"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      :search-input.sync="autocomplete.village"
                      :loading="process.village"
                      
                      :items="config.village"
                      item-value="desa_code"
                      item-text="desa_name"
                      item-color="#ff64ad"

                      v-model="form.kelurahan_code"
                      :success="valid"
                      :error-messages="errors"
                      required
                      style="border-radius: 10px;">
                    </v-autocomplete>
                  </ValidationProvider>

                  <div>
                    <div class="body-2 mb-2">
                      Alasan
                      <span>
                        (bisa dikosongi)
                      </span>
                    </div>

                    <v-textarea
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      rows="3"
                      color="#ff64ad"
                      placeholder="Contoh.Perubahan nama dan foto"
                      :append-icon="form.keterangan.length < 1 ? '' : 'mdi-check-circle-outline'"

                      v-model="form.keterangan"
                      v-uppercase
                      :success="form.keterangan.length > 0"
                      style="border-radius: 10px;">
                    </v-textarea>
                  </div>

                  <v-card
                    v-for="(item, index) in form.data_ajuan.data_dukung"
                    :key="index"
                    flat
                    color="white"
                    class="mb-6"
                    style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 10px;">
                    <v-card-title
                      class="body-2 pb-2">
                      <span class="red--text" v-if="item.require">*</span>

                      <span style="opacity: 60%;">
                        {{ item.name }} 
                      </span>
                      
                      <span class="error--text ml-1" v-if="item.require">
                        (wajib diisi)
                      </span>
                    </v-card-title>

                    <v-divider 
                      class="mx-4"
                      style="border: 2px solid #f9f9f9; border-radius: 50px;" />

                    <v-card-text
                      class="py-4">
                      <v-row>
                        <v-col 
                          v-for="(item_img, index_img) in item.file"
                          :key="index_img"
                          cols="6" 
                          md="4">
                          <v-card 
                            flat
                            height="136"
                            class="d-flex align-center justify-center"
                            style="border-radius: 15px;
                                  border: 2px solid #eaeaea; 
                                  border-style: dashed;
                                  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; ">
                            <v-img 
                              alt="kominfo" 
                              width="100%"
                              height="136"
                              :src="item_img.url"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#ff64ad">
                                  </v-progress-circular>
                                </v-row>
                              </template>

                              <div @click="item.file.splice(index_img, 1)"
                                class="cursor-pointer"
                                style="position: absolute; top: 5px; right: 5px;">
                                <v-icon size="20" color="red">mdi-close-circle</v-icon>
                              </div>
                            </v-img>
                          </v-card>
                        </v-col>

                        <v-col 
                          cols="6"
                          md="4">
                          <v-card @click="sheet.option = true; index_file = index;"
                            flat
                            height="136"
                            class="d-flex align-center justify-center"
                            style="border-radius: 15px;
                                  border: 2px solid #eaeaea; 
                                  border-style: dashed;">
                            <v-icon v-if="!process.file || index_file !== index"
                              size="50" 
                              color="grey">
                              mdi-cloud-upload-outline
                            </v-icon>

                            <v-progress-circular v-if="process.file && index_file === index"
                              indeterminate
                              color="#ff64ad">
                            </v-progress-circular>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-form>
              </ValidationObserver>

              <div v-if="message.error.length > 0"
                class="text-center my-3">
                <v-alert
                  type="error" 
                  text 
                  dense 
                  prominent 
                  v-show="message.error.length > 0">
                  {{ message.error }}
                </v-alert>
              </div>

              <v-row :class="sheet.option ? 'pb-12' : ''">
                <v-col 
                  cols="6" 
                  class="py-0">
                  <v-btn
                    :disabled="process.form"
                    :loading="process.form"
                    v-on:keyup.enter="save"
                    @click="save"
                    block
                    color="green"
                    class="text-capitalize white--text pl-1 pr-2"
                    style="border-radius: 10px; box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;">
                    <v-avatar 
                      size="25"
                      style="position: absolute; left: 2px;">
                      <v-icon small>mdi-check-bold</v-icon>
                    </v-avatar>

                    <span class="ml-6">
                      Simpan
                    </span>
                  </v-btn>
                </v-col>

                <v-col 
                  cols="6" 
                  class="py-0">
                  <v-btn 
                    @click="sheet.update = false"
                    :disabled="process.form"
                    block
                    color="red"
                    class="text-capitalize white--text pl-1 pr-2"
                    style="border-radius: 10px; box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;">
                    <v-avatar 
                      size="25"
                      style="position: absolute; left: 2px;">
                      <v-icon small>mdi-close-thick</v-icon>
                    </v-avatar>

                    <span class="ml-6">
                      Batal
                    </span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.option"
          hide-overlay>
          <v-card
            flat
            color="#fff"
            class="d-flex align-center"
            style="border-radius: 30px 30px 0px 0px;">
            <v-card-text
              class="pa-3">
              <v-row 
                align="center" 
                justify="center">
                <!-- CAMERA -->
                <v-col cols="4" class="text-center">
                  <div @click="sheet.upload = true; selected.type = 'camera';"
                    class="cursor-pointer">
                    <v-avatar 
                      size="50" 
                      class="cursor-pointer" 
                      v-ripple
                      style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;">
                      <v-img 
                        alt="kominfo" 
                        :src="require('@/assets/image/camera.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#ff64ad">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <div class="font-weight-bold black--text mt-2">
                      Kamera
                    </div>
                  </div>
                </v-col>

                <v-col cols="4" class="text-center px-0">
                  <div class="d-flex align-center">
                    <v-divider style="border: 2px solid #eaeaea; border-radius: 50px;" />
                    <div class="mx-2">
                      Atau
                    </div>
                    <v-divider style="border: 2px solid #eaeaea; border-radius: 50px;" />
                  </div>
                </v-col>

                <!-- FOLDER -->
                <v-col 
                  cols="4" 
                  class="text-center">
                  <upload-folder 
                    v-model="upload.folder">
                    <div slot="activator">
                      <v-avatar 
                        size="50" 
                        class="cursor-pointer" 
                        v-ripple
                        style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;">
                        <v-img 
                          alt="kominfo" 
                          :src="require('@/assets/image/folder.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#ff64ad">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-avatar>

                      <div class="font-weight-bold black--text mt-2">
                        Galeri
                      </div>
                    </div>
                  </upload-folder>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.upload"
          hide-overlay
          persistent>
          <upload-camera 
            v-if="selected.type === 'camera'"
            :sheet="sheet.upload"
            @frame="sheet.upload = $event"
            @input="addCamera($event)"
            @close="sheet.upload = $event; selected.type = '';" />
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.review"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            style="border-radius: 0px;">
            <v-card-title 
              @click="sheet.review = false"
              class="cursor-pointer pa-4"
              style="border-radius: 0px 0px 15px 15px;
                    position: absolute;
                    width: 100%;
                    z-index: 1;
                    background: #ff64ad;">
              <v-icon color="white">
                mdi-arrow-left-thick
              </v-icon>

              <span
                class="body-1 font-weight-bold text-capitalize white--text ml-1">
                Beri Ulasan Ajuan
              </span>
            </v-card-title>

            <v-card-text
              class="mt-16 pt-4 px-4">
              <ValidationObserver 
                ref="form_review">
                <v-form>
                  <div>
                    <div class="body-2 mb-2">
                      Berikan Rating Anda
                    </div>

                    <div class="text-center">
                      <v-rating
                        v-model="form_review.rating"
                        background-color="orange lighten-3"
                        color="orange"
                        size="60"
                        dense
                        hover>
                      </v-rating>
                    </div>
                  </div>
                  
                  <div>
                    <div class="body-2 mt-6 mb-2">
                      Keterangan
                      <span>
                        (bisa dikosongi)
                      </span>
                    </div>

                    <v-textarea
                      v-on:keyup.enter="save_review"
                      filled
                      rounded
                      dense
                      rows="3"
                      color="#ff64ad"
                      placeholder="Contoh. Pengajuan cepat selesai"
                      :append-icon="form_review.content.length < 1 ? '' : 'mdi-check-circle-outline'"

                      v-model="form_review.content"
                      v-uppercase
                      :success="form_review.content.length > 0"
                      style="border-radius: 10px;">
                    </v-textarea>
                  </div>
                </v-form>
              </ValidationObserver>

              <div v-if="message.error.length > 0"
                class="text-center my-3">
                <v-alert
                  type="error" 
                  text 
                  dense 
                  prominent 
                  v-show="message.error.length > 0">
                  {{ message.error }}
                </v-alert>
              </div>
            </v-card-text>

            <v-card
              flat
              color="#fff"
              min-height="150"
              style="border-radius: 15px 15px 0px 0px;
                    position: fixed;
                    bottom: 0;
                    width: 460px;
                    z-index: 1;">
              <v-card-text
                class="pa-4">
                <v-row 
                  align="center">
                  <v-col 
                    cols="3" 
                    class="py-0">
                    <v-img 
                      contain
                      alt="kominfo" 
                      :src="require('@/assets/image/rating.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="#ff64ad">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>

                  <v-col 
                    cols="9" 
                    class="black--text py-0">
                    <div>
                      <div 
                        class="body-2 line-text-second black--text" 
                        style="opacity: 60%;">
                        Berikan ulasan mengenai pelayanan Ajuan di SAKURA, kemudian klik <b class="font-italic">Simpan</b>.
                      </div>
                    </div>
                  </v-col>
                </v-row>
                
                <v-row 
                  class="mt-4">
                  <v-col 
                    cols="6" 
                    class="py-0">
                    <v-btn
                      :disabled="process.form"
                      :loading="process.form"
                      v-on:keyup.enter="save_review"
                      @click="save_review"
                      block
                      color="green"
                      class="text-capitalize white--text pl-1 pr-2"
                      style="border-radius: 10px; 
                            box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon small>mdi-check-bold</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Simpan
                      </span>
                    </v-btn>
                  </v-col>

                  <v-col 
                    cols="6" 
                    class="py-0">
                    <v-btn 
                      @click="sheet.review = false"
                      :disabled="process.form"
                      block
                      color="red"
                      class="text-capitalize white--text pl-1 pr-2"
                      style="border-radius: 10px; 
                            box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon small>mdi-close-thick</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Batal
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        sheet: {
          detail: false,
          zoom: false,

          // KOREKSI AJUAN
          update: false,
          option: false,
          upload: false,
          
          // PRODUK
          product: false,

          // ULASAN
          review: false
        },
        selected: {
          detail: {},
          title: '',
          zoom: ''
        },
        detail: {},
        list: [],
        pagination: {},
        
        // KOREKSI AJUAN
        config: {
          service: {},
          support: [],

          // ADDRESS
          subdistrict: [],
          village: []
        },
        upload: {
          camera: null,
          folder: null
        },
        index_file: '',
        autocomplete: {
          subdistrict: '',
          village: ''
        },
        form: {
          id: '',
          layanan_kode: '',
          jenis_ajuan_id: '',
          is_mandiri: 1,
          is_online: 1,
          nik: '',
          nama_lengkap: '',
          keterangan: '',
          data_ajuan: {
            code: '',
            field : {
              nik : '',
              nama_lengkap : '',

              // ADDRESS
              kecamatan_code: '',
              kecamatan_name: '',
              kelurahan_code: '',
              kelurahan_name: ''
            },
            data_dukung : []
          },

          // ADDRESS
          kecamatan_code: '',
          kecamatan_name: '',
          kelurahan_code: '',
          kelurahan_name: ''
        },

        form_review: {
          // ULASAN
          ajuan_id: '',
          rating: 0,
          content: ''
        },

        process: {
          load: false,
          log: false,
          form: false,
          file: false,

          // ADDRESS
          subdistrict: false,
          village: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/progress/:id',
          title: 'Riwayat Ajuan',
          description: 'Riwayat Ajuan Sakura Dukcapil Klaten (Sistem Layanan Administrasi Kependudukan Dalam Jaringan)',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      UploadCamera: () => import(/* webpackPrefetch: true */ '@/components/upload/camera.vue'),
      UploadFolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue'),
      Product: () => import(/* webpackPrefetch: true */ '@/components/product/index.vue')
    },
    watch: {
      'upload.folder': {
        handler: function () {
          if (this.upload.folder) {
            this.process.file = this.upload.folder.process

            if (!this.process.file) {
              // CALLBACK FROM UPLOAD IMAGE
              this.sheet.option = false

              this.form.data_ajuan.data_dukung[this.index_file].file.push({
                url: this.upload.folder.data.full.file_url,
                type: this.upload.folder.type,
                process: this.upload.folder.process,
                user_id: this.user.id,
                file_id: this.upload.folder.id === undefined ? 0 : this.upload.folder.id
              })
            }
          }
        },
        deep: true
      },
      'form.kecamatan_code': function() {
        let t = this
        let kec = this.config.subdistrict.filter(function(v) {
          return t.form.kecamatan_code == v.kecamatan_code
        })

        if (kec.length > 0) {
          this.form.kecamatan_name = kec[0].kecamatan_name

          // this.form.kelurahan_code = ''
          // this.form.kelurahan_name = ''
        }

        this.static().village()
      },
      'form.kelurahan_code': function() {
        let t = this
        let kel = this.config.village.filter(function(v) {
          return t.form.kelurahan_code == v.desa_code
        })

        if (kel.length > 0) {
          this.form.kelurahan_name = kel[0].desa_name
        }
      },
      'autocomplete.subdistrict': function() {
        this.static().subdistrict()
      },
      'autocomplete.village': function() {
        this.static().village()
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      user() {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      fetch () {
        this.fetchDetail()
        this.fetchLog()
      },
      async fetchDetail () {
        this.process.load = true

        let params = {
          id: this.$route.params.id
        }

        let reporter = this.$route.query.reporter > 0 ? 'mandiri' : 'perantara'

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/ajuan/${this.$route.query.code}/${reporter}/detail`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false
            
            this.detail = response.data
          } 
        })
      },
      async fetchLog () {
        this.process.log = true

        let params = {
          log_ajuan_id: this.$route.params.id
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/ajuan/${this.$route.query.code}/log`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.log = false
            
            this.list = response.data
            this.pagination = response.pagination
          } 
        })
      },
      async fetchService (item) {
        let params = {
          kode: this.detail.ajuan_layanan_kode
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/layanan/detail`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.config.service = response.data

            this.fetchSupport(item)
          } 
        })
      },
      async fetchSupport (item) {
        let params = {
          mdadu_layanan_kode: this.detail.ajuan_layanan_kode,
          order_by: 'DESC',
          sort: 'mdadu_is_required'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/master_data_dukung`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.config.support = response.data

            this.reset(item)
          } 
        })
      },
      static () {
        let t = this
        return {
          async subdistrict () {
            t.process.subdistrict = true

            let params = {
              search: t.autocomplete.subdistrict,
              sort: 'DESC'
            }

            await t.$axios.$get(`${process.env.PREFIX_MASTER}/location/kecamatan`, { params })
            .then(response => {
              if (response.code == 200) {
                t.process.subdistrict = false

                t.config.subdistrict = response.data
              }
            })
          },
          async village () {
            t.process.village = true

            let params = {
              desa_kecamatan_code: t.form.kecamatan_code,
              search: t.autocomplete.village,
              sort: 'DESC'
            }

            await t.$axios.$get(`${process.env.PREFIX_MASTER}/location/desa`, { params })
            .then(response => {
              if (response.code == 200) {
                t.process.village = false

                t.config.village = response.data
              }
            })
          }
        }
      },
      reset (item) {
        this.form = {
          id: item.ajuan_id,
          layanan_kode: item.ajuan_layanan_kode,
          jenis_ajuan_id: item.ajuan_jenis_ajuan_id,
          is_mandiri: item.ajuan_is_mandiri,
          is_online: item.ajuan_is_online,
          nik: item.ajuan_data_ajuan.field.nik,
          nama_lengkap: item.ajuan_data_ajuan.field.nama_lengkap,
          keterangan: item.ajuan_keterangan,
          data_ajuan: item.ajuan_data_ajuan,

          // ADDRESS
          kecamatan_code: item.ajuan_kecamatan_code,
          kecamatan_name: item.ajuan_kecamatan_name,
          kelurahan_code: item.ajuan_kelurahan_code,
          kelurahan_name: item.ajuan_kelurahan_name
        }

        if (this.config.support.length > 0) {
          if (item.ajuan_data_ajuan.data_dukung.length > 0) {
            this.form.data_ajuan.data_dukung = item.ajuan_data_ajuan.data_dukung
          } else {
            this.config.support.map(obj => {
              this.form.data_ajuan.data_dukung.push({
                id: obj.mdadu_id,
                name: obj.mdadu_judul,
                require: obj.mdadu_is_required,
                file: []
              })
            })
          }
        }
        
        this.process.form = false

        this.message.error = ''
      },
      addCamera (e) {
        this.sheet.option = false

        this.process.file = e.process

        if (!e.process) {
          this.form.data_ajuan.data_dukung[this.index_file].file.push({
            url: e.data.full.file_url,
            type: e.data.type,
            process: e.data.process,
            user_id: this.user.id,
            file_id: e.data.id === undefined ? 0 : e.data.id
          })

          this.selected.type = ''
        } 
      },
      async save() {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          this.form.data_ajuan.code = this.form.layanan_kode
          this.form.data_ajuan.field.nik = this.form.nik
          this.form.data_ajuan.field.nama_lengkap = this.form.nama_lengkap
          this.form.data_ajuan.field.kecamatan_code = this.form.kecamatan_code
          this.form.data_ajuan.field.kecamatan_name = this.form.kecamatan_name
          this.form.data_ajuan.field.kelurahan_code = this.form.kelurahan_code
          this.form.data_ajuan.field.kelurahan_name = this.form.kelurahan_name

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.PREFIX_PROXY}/ajuan/${this.form.layanan_kode}/update`, this.form)
          .then((response) => {

            if (response.code === 200) {
              this.sheet.detail = false

              this.sheet.update = false
              
              this.fetch()
            } else {
              this.process.form = false
              this.message.error = response.message
            }

          })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },
      async save_review() {
        this.message.error = ''

        const isValid = await this.$refs.form_review.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.PREFIX_PROXY}/ajuan_review/create`, this.form_review)
          .then((response) => {

            if (response.code === 200) {
              this.sheet.review = false
              
              this.fetch()
            } else {
              this.process.form = false
              this.message.error = response.message
            }

          })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>

<style>
  .v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse):before {
    min-height: 49.5vh;
  }

  .log_note p {
    margin-bottom: 2px !important;
  }
</style>