<template>
  <div class="fill-height pt-16">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.form"
          persistent>
          <v-card 
            class="border-radius pa-2">
            <v-card-title class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar size="35">
                <v-img
                  contain
                  width="30"
                  height="30"
                  alt="kominfo"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        color="#ff64ad">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span class="body-1 font-weight-bold mt-1 ml-1">
                Filter Ajuan
              </span>

              <v-spacer/>

              <v-icon 
                @click="dialog.form = false"
                class="cursor-pointer"
                size="24"
                color="red">
                mdi-close-circle-outline
              </v-icon>
            </v-card-title>
            
            <v-divider class="mx-4 mt-3 mb-2"/>

            <v-card-text class="mt-3 px-4">
              <v-form>
                <div>
                  <div class="body-2 mb-2">
                    Jenis Ajuan
                  </div>
                  
                  <v-select
                    filled
                    rounded
                    dense
                    color="#ff64ad"
                    placeholder="Contoh. Perubahan Data"
                    
                    :items="list.jenis"
                    item-text="ja_judul"
                    item-value="ja_id"
                    item-color="#ff64ad"

                    v-model="form.jenis"
                    clearable
                    @click:clear="form.date = ''"
                    style="border-radius: 10px;">
                  </v-select>
                </div>

                <!-- <div>
                  <div class="body-2 mb-2">
                    Pelapor
                  </div>
                  
                  <v-select
                    filled
                    rounded
                    dense
                    color="#ff64ad"
                    placeholder="Contoh. Perubahan Data"
                    
                    :items="[
                      {
                        text: 'Mandiri',
                        value: 1
                      },
                      {
                        text: 'Group',
                        value: 0
                      }
                    ]"
                    item-color="#ff64ad"

                    v-model="form.pelapor"
                    style="border-radius: 10px;">
                  </v-select>
                </div> -->

                <div>
                  <div class="body-2 mb-2">
                    Status Ajuan
                  </div>
                  
                  <v-select
                    filled
                    rounded
                    dense
                    color="#ff64ad"
                    placeholder="Contoh. Perubahan Data"
                    
                    :items="list.status"
                    item-color="#ff64ad"

                    v-model="form.status"
                    clearable
                    @click:clear="form.date = ''"
                    style="border-radius: 10px;">
                  </v-select>
                </div>

                <div>
                  <div class="body-2 mb-2">
                    Tanggal
                  </div>

                  <v-menu
                    ref="start"
                    min-width="290px"
                    max-width="290px"
                    transition="scale-transition"
                    offset-y
                    :nudge-right="40"
                    :close-on-content-click="false"
                    v-model="picker">
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        readonly
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. 2022-01-01"
                        v-model="form.date"
                        v-uppercase
                        style="border-radius: 10px;"
                        hide-details
                        clearable
                        @click:clear="form.date = ''"
                        v-on="on">
                      </v-text-field>
                    </template>
                    
                    <v-date-picker 
                      color="#ff64ad"
                      v-model="form.date"
                      @input="picker = false">
                    </v-date-picker>
                  </v-menu>
                </div>
              </v-form>
            </v-card-text>

            <v-divider class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.form = false; fetchAjuan();"
                color="#ff64ad"
                elevation="0"
                class="text-capitalize white--text"
                style="border-radius: 15px; box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;">
                <v-icon small class="mr-1">mdi-check-bold</v-icon>
                Filter
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <v-card 
            flat
            color="#f9f9f9">
            <v-card-text v-if="this.detail.level !== 'user'"
              class="py-0">
              <v-tabs
                v-model="form.pelapor"
                background-color="transparent"
                color="#ff64ad"
                slider-size="5"
                show-arrows
                next-icon="mdi-arrow-right-bold-box-outline"
                prev-icon="mdi-arrow-left-bold-box-outline"
                dense
                grow
                class="tabs-stream">
                <v-tab
                  v-for="(item, index) in list.pelapor"
                  :key="index"
                  class="px-0 d-flex align-center">
                  <v-avatar
                    tile
                    size="20">
                    <v-img 
                      contain
                      :src="item.icon">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            size="20"
                            color="#ff64ad">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>

                  <span 
                    class="body-2 text-capitalize ml-2" 
                    :class="form.pelapor === index ? 'font-weight-bold' : 'text-third'">
                    {{ item.text }}
                  </span>
                </v-tab>
              </v-tabs>
            </v-card-text>

            <v-card-text 
              class="d-flex align-center pb-0">
              <v-text-field
                v-model="search"
                v-uppercase
                @click:clear="search = ''; fetchAjuan();"
                v-on:keyup.enter="fetchAjuan()"
                filled
                rounded
                dense 
                single-line
                hide-details
                color="#ff64ad"
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari . . ."
                clearable
                style="border-radius: 10px;">
              </v-text-field>

              <v-btn
                @click="dialog.form = true"
                min-width="38"
                min-height="38"
                color="#ff64ad"
                elevation="0"
                class="px-0 ml-4"
                style="border-radius: 10px;">
                <v-icon color="white">mdi-filter-variant</v-icon>
              </v-btn>
            </v-card-text>

            <v-row>
              <v-col 
                class="pb-0 pt-6">
                <no-ssr>
                  <swiper 
                    v-model="selected.service"
                    ref="swiper"
                    class="swiper"
                    :options="swiperOption">
                    <swiper-slide 
                      v-for="(item, index) in process.service ? 10 : list.service" 
                      :key="index" 
                      :class="index < 1 ? 'px-4' : index < list.service.length ? 'pr-4' : 'px-4'"
                      style="width: auto;">
                      <v-skeleton-loader
                        class="ma-auto"
                        :loading="process.service"
                        type="avatar"
                        tile>
                        <div>
                          <v-card v-if="!process.service && list.service.length > 0"
                            @click="selected.service = index"
                            flat
                            width="45"
                            height="45"
                            :color="selected.service == index ? '#ff64ad1a' : 'white'"
                            class="border-radius d-flex align-center justify-center pa-3"
                            style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px !important;">
                            <div 
                              class="text-center">
                              <v-avatar 
                                size="30" 
                                tile>
                                <v-img 
                                  alt="kominfo" 
                                  :src="item.layanan_image"
                                  class="ma-auto"
                                  style="border-radius: 10px;">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular 
                                        indeterminate 
                                        color="white">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-avatar>

                              <div 
                                class="text-center font-weight-bold mt-1"
                                style="font-size: 10px; opacity: 60%;">
                                {{ item.layanan_kode }}
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </v-skeleton-loader>
                    </swiper-slide>
                  </swiper>
                </no-ssr>
              </v-col>
            </v-row>

            <v-card-text>
              <v-list 
                flat 
                three-line
                color="transparent">
                <v-list-item-group>
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 10 : list.data"
                    :key="index"
                    :loading="process.load"
                    type="list-item-avatar-three-line">
                    <template>
                      <div>
                        <v-list-item v-if="!process.load && list.data.length > 0"
                          :to="`/progress/${item.ajuan_layanan_kode}/${item.id}?code=${item.ajuan_layanan_kode}&reporter=${item.ajuan_is_mandiri}`"
                          :class="index < list.data.length - 1 ? 'mb-4' : ''"
                          class="pl-3"
                          style="box-shadow: rgba(0, 0, 0, 0.01) 0px 3px 5px; 
                                border-radius: 5px;
                                border-left: 4px solid #ff64ad;
                                background: #fff;">
                          <v-list-item-avatar 
                            tile 
                            size="40"
                            class="mr-3">
                            <v-img 
                              style="border-radius: 5px;"
                              alt="kominfo" 
                              :src="require(`@/assets/image/service/${item.ajuan_layanan_kode}.png`)"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#ff64ad">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <div
                              class="d-flex align-center mb-2">
                              <v-chip
                                class="white--text px-2"
                                :color="item.ajuan_is_mandiri ? 'green' : 'blue'"
                                x-small>
                                <!-- {{ item.ajuan_is_mandiri ? 'Mandiri' : 'Group' }} -->
                                {{ item.ajuan_pelapor_role_name }}
                              </v-chip>

                              <v-icon v-if="item.ajuan_status === 'DITOLAK'"
                                color="red"
                                size="20"
                                style="position: absolute; right: 15px;">
                                mdi-cancel
                              </v-icon>
                            </div>

                            <v-list-item-title 
                              class="body-2 font-weight-bold">
                              {{ item.ajuan_no_reg }}

                              <!-- <v-icon v-if="item.ajuan_status === 'DITOLAK'"
                                color="red"
                                size="20"
                                style="position: absolute; right: 15px;">
                                mdi-cancel
                              </v-icon> -->
                            </v-list-item-title>

                            <v-list-item-subtitle 
                              class="caption font-weight-light black--text mt-1 mb-2" 
                              style="opacity: 60%;">
                              <span
                                class="one-line">
                                {{ item.ajuan_keterangan === '' ? '-' : item.ajuan_keterangan }}
                              </span>
                            </v-list-item-subtitle>

                            <div
                              class="mb-3">
                              <v-progress-linear
                                style="border-radius: 50px;"
                                :value="item.ajuan_status === 'BELUM DIVERIFIKASI' ? 10 :
                                        item.ajuan_status === 'PROSES VERIFIKASI' || item.ajuan_status === 'PROSES INPUT NIK' ? 25 :
                                        item.ajuan_status === 'DISETUJUI' ? 50 :
                                        item.ajuan_status === 'DISETUJUI TANPA NIK' ? 50 :
                                        item.ajuan_status === 'DISETUJUI TERBIT NIK' ? 50 :
                                        item.ajuan_status === 'DIKOREKSI' ? 75 :
                                        item.ajuan_status === 'SELESAI DIPROSES' ? 100 : 0"
                                        
                                :color="item.ajuan_status === 'BELUM DIVERIFIKASI' ? 'orange' :
                                        item.ajuan_status === 'PROSES VERIFIKASI' || item.ajuan_status === 'PROSES INPUT NIK' ? 'blue' :
                                        item.ajuan_status === 'DISETUJUI' ? 'cyan' :
                                        item.ajuan_status === 'DISETUJUI TANPA NIK' ? 'indigo' :
                                        item.ajuan_status === 'DISETUJUI TERBIT NIK' ? 'lime' :
                                        item.ajuan_status === 'DIKOREKSI' ? 'purple' :
                                        item.ajuan_status === 'SELESAI DIPROSES' ? 'green' : 'red'"
                                height="8">
                              </v-progress-linear>
                            </div>

                            <div
                              class="d-flex align-center"
                              style="font-size: 10px; opacity: 40%;">
                              <div>
                                {{ item.ajuan_create_datetime | date }}
                              </div>

                              <v-spacer />

                              <div>
                                {{ item.ajuan_create_datetime | time }}
                              </div>
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-list-item-group>
              </v-list>

              <v-card-text v-if="pagination.total_data > 10 && limit < pagination.total_data"
                class="text-center">
                <v-btn
                  small
                  rounded
                  outlined
                  elevation="0"
                  color="#ff64ad"
                  class="color-default text-capitalize"
                  @click="limit += 10">
                  Lihat Lebih Banyak
                </v-btn>
              </v-card-text>

              <Empty v-if="!process.load && pagination.total_page < 1"
                margin="my-16"
                size="125"
                message="Ajuan" />
            </v-card-text>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        dialog: {
          form: false
        },
        code_api: '',
        search: '',
        swiperOption: {
          slidesPerView: 'auto'
        },
        selected: {
          service: 0
        },
        list: {
          service: [],
          data: [],
          jenis: [],
          status: [
            'BELUM DIVERIFIKASI',
            'PROSES VERIFIKASI',
            'DISETUJUI',
            'DISETUJUI TANPA NIK',
            'DISETUJUI TERBIT NIK',
            'DITOLAK',
            'DIKOREKSI',
            'BELUM DIAJUKAN TTE',
            'DIAJUKAN TTE',
            'SIAP DICETAK',
            'TIDAK DIPROSES',
            'SUDAH DICETAK',
            'SIAP DIDOWNLOAD',
            'SIAP DIAMBIL',
            'SUDAH DIAMBIL',
            'SELESAI DIPROSES'
          ],
          pelapor: [
            {
              text: 'Mandiri',
              icon: require('@/assets/image/profile/note.png'),
              value: 1
            },
            {
              text: 'Group',
              icon: require('@/assets/image/profile/team.png'),
              value: 0
            }
          ]
        },
        pagination: {},
        limit: 10,
        picker: false,
        form: {
          pelapor: 0,
          jenis: '',
          status: '',
          date: ''
        },
        process: {
          service: false,
          load: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'selected.service': function() {
        this.code_api = this.list.service[this.selected.service].layanan_kode

        this.fetchAjuan()
      },
      'form.pelapor': function() {
        this.fetchService()
      },
      'limit': function() {
        this.fetchAjuan()
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      this.fetchService()
    },
    methods: {
      async fetchService () {
        this.process.service = true

        let params = {
          // sort: 'DESC',
          order_by: 'ASC',
          auth_role: this.list.pelapor[this.form.pelapor].value > 0 ? 0 : 1
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/layanan`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.service = false
            
            this.list.service = response.data

            this.selected.service = 0

            this.list.service.map((obj, index) => {
              if (this.$route.query.code === obj.layanan_kode) {
                this.selected.service = index
              }
            })

            this.code_api = this.$route.query.code === undefined ? this.list.service[this.selected.service].layanan_kode : this.$route.query.code

            this.fetchJenis()

            this.fetchAjuan()
          } 
        })
      },
      async fetchJenis () {
        let params = {
          id: this.list.service[this.selected.service].layanan_id
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/layanan/detail`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.list.jenis = response.data.layanan_jenis_ajuan
          } 
        })
      },
      async fetchAjuan () {
        this.process.load = true

        let params = {
          limit: this.limit,
          search: this.search,
          ajuan_jenis_ajuan_id: this.form.jenis,
          // ajuan_is_mandiri: this.form.pelapor,
          ajuan_status: this.form.status,
          ajuan_create_datetime: this.form.date
        }

        let reporter = this.list.pelapor[this.form.pelapor].value > 0 ? 'mandiri' : 'perantara'
 
        await this.$axios.$get(`${process.env.PREFIX_PROXY}/ajuan/${this.code_api}/${reporter}`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false
            
            this.list.data = response.data
            this.pagination = response.pagination
          } 
        })
      },
      onSlideChange() {
        this.selected.service = this.$refs.swiper.$swiper.activeIndex
      },
      onSlideChangeIndex(index) {
        this.$refs.swiper.$swiper.slideTo(index, 1000, false)
        this.selected.service = index
      }
    }
  }
</script>