<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex 
        style="min-height: 100vh;">
        <section>
          <v-card
            flat 
            color="transparent"
            class="pt-10">
            <v-card-text
              class="text-center">
              <v-img 
                width="125"
                height="125"
                alt="kominfo" 
                :src="require('@/assets/image/security.png')"
                class="ma-auto">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      indeterminate 
                      color="#ff64ad">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="title font-weight-bold black--text mt-8 mb-1">
                Masukkan Password Baru
              </div>

              <div
                class="body-2 font-weight-light line-text-first">
                Usahakan password Anda mengandung huruf dan angka untuk keamanan akun Anda.
              </div>
            </v-card-text>

            <v-card-text
              class="mb-16 pb-16 mt-4">
              <div class="mb-8">
                <ValidationObserver 
                  ref="form">
                  <v-form>
                    <ValidationProvider 
                      name="password" 
                      vid="confirm"
                      rules="required|min:5|max:20" 
                      v-slot="{ errors }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Password
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-text-field
                        v-on:keyup.enter="save"
                        filled
                        rounded
                        dense 
                        color="#ff64ad"
                        placeholder="Contoh. ******"
                        :type="show.password ? 'text' : 'password'"
                        :append-icon="show.password ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show.password = !show.password"
                        :counter="20"
                        v-model="form.new_password"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="password" 
                      rules="required|min:5|max:20|confirmed:confirm" 
                      v-slot="{ errors }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Verifikasi Password
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-text-field
                        v-on:keyup.enter="save"
                        filled
                        rounded
                        dense 
                        ref="password"
                        color="#ff64ad"
                        placeholder="Contoh. ******"
                        :type="show.verify_password ? 'text' : 'password'"
                        :append-icon="show.verify_password ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show.verify_password = !show.verify_password"
                        :counter="20"
                        v-model="form.new_password_verify"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider>
                  </v-form>
                </ValidationObserver>

                <div v-if="message.error.length > 0"
                  class="text-center mt-3 mb-16">
                  <v-alert
                    type="error" 
                    text 
                    dense 
                    prominent 
                    v-show="message.error.length > 0">
                    {{ message.error }}
                  </v-alert>
                </div>
              </div>
            </v-card-text>

            <v-card
              flat
              color="#fff"
              min-height="100"
              style="border-radius: 15px 15px 0px 0px;
                    position: absolute;
                    bottom: 0px;position: fixed;
                    bottom: 0;
                    width: 460px;
                    z-index: 1;">
              <v-card-text
                class="px-4 py-2">
                <v-row align="start">
                  <v-col 
                    cols="3"
                    class="pr-1">
                    <v-img 
                      contain
                      alt="kominfo" 
                      :src="require('@/assets/image/change_key.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="#ff64ad">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>

                  <v-col 
                    cols="9">
                    <div class="mb-3">
                      <div 
                        class="body-2 line-text-second black--text" 
                        style="opacity: 60%;">
                        Silahkan ubah password akun Anda, kemudian klik <b class="font-italic">Simpan</b>.
                      </div>
                    </div>

                    <div>
                      <v-btn
                        :disabled="process.form"
                        :loading="process.form"
                        v-on:keyup.enter="save"
                        @click="save"
                        block
                        color="green"
                        class="text-capitalize white--text pl-1 pr-2"
                        style="border-radius: 10px; box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px !important;">
                        <v-avatar 
                          size="25"
                          style="position: absolute; left: 2px;">
                          <v-icon small>mdi-check-bold</v-icon>
                        </v-avatar>

                        <span class="ml-6">
                          Simpan
                        </span>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'notAuthenticated',
    data () {
      return {
        show: {
          password: false,
          verify_password: false
        },
        form: {
          token: '',
          new_password: '',
          new_password_verify: ''
        },
        process: {
          form: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/auth/reset/:slug',
          title: 'Reset Password',
          description: 'Reset Password Sakura Dukcapil Klaten (Sistem Layanan Administrasi Kependudukan Dalam Jaringan)',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      
    },
    watch: {
      
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      }
    },
    mounted () {
      this.form.token = this.$route.params.slug
    },
    methods: {
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.PREFIX_PROXY}/auth/reset-password`, this.form)
          .then((response) => {

            if (response.code === 200) {
              this.$router.push({ path: '/auth/successforgot' })
            } else {
              this.process.form = false
              this.message.error = response.message
            }

          })
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>