<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card 
            flat
            color="transparent">
            <v-card-text 
              class="pa-0">
              <v-carousel 
                v-model="selected.banner" 
                cycle
                hide-delimiters 
                :show-arrows="false" 
                height="auto">
                <v-carousel-item
                  v-for="(item, index) in process.banner ? 3 : list"
                  :key="index">
                  <v-skeleton-loader
                    class="ma-auto"
                    :loading="process.banner"
                    type="image">
                    <div>
                      <v-img v-if="!process.banner && list.length > 0"
                        width="100%"
                        height="350"
                        alt="kominfo" 
                        :src="item.image"
                        gradient="to top, rgba(0, 0, 0, .5), rgba(0, 0, 0, .1)"
                        class="ma-auto d-flex align-end">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#ff64ad">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </div>
                  </v-skeleton-loader>
                </v-carousel-item>
              </v-carousel>

              <v-bottom-sheet
                v-model="sheet"
                hide-overlay
                persistent>
                <div class="text-center mb-4">
                  <v-btn 
                    v-for="(item, index) in list" 
                    :key="index"
                    :width="selected.banner === index ? 5 : 8"
                    :height="selected.banner === index ? 10 : 8"
                    elevation="0"
                    x-small
                    :rounded="selected.banner === index"
                    :fab="selected.banner !== index"
                    :color="selected.banner === index ? '#ff64ad' : '#fff'"
                    class="ma-1"
                    @click="selected.banner = index">
                  </v-btn>
                </div>

                <v-card
                  flat
                  color="#f9f9f9"
                  min-height="calc(100vh - 300px)"
                  style="border-radius: 15px 15px 0px 0px;">
                  <v-card-text class="pa-4">
                    <div class="mb-3">
                      <div class="title black--text">
                        Tentang Kami
                      </div>

                      <div class="body-2 font-weight-light black--text" style="opacity: 60%;">
                        Mengenai Aplikasi SAKURA
                      </div>
                    </div>
                    
                    <div class="body-2 font-weight-light line-text-second black--text"
                      style="overflow-y: auto; height: calc((100vh - 300px) - 100px); opacity: 60%;">
                      <div 
                        class="paragraph mb-3"
                        style="text-indent: 25px; text-align: justify;">
                        <v-skeleton-loader
                          v-for="(item, index) in process.load ? 5 : 1"
                          :key="index"
                          class="ma-auto"
                          :loading="process.load"
                          type="list-item-three-line">
                          <div>
                            <span v-if="!process.load"
                              v-html="detail.content">
                            </span>
                          </div>
                        </v-skeleton-loader>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-bottom-sheet>
            </v-card-text>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        sheet: true,
        selected: {
          banner: 0
        },
        detail: {},
        list: [],
        pagination: {},
        process: {
          banner: false,
          load: false
        }
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      fetch () {
        this.fetchBanner()
        this.fetchData()
      },
      async fetchBanner () {
        this.process.banner = true

        let params = {
          type: 'banner'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/site`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.banner = false
            
            this.list = response.data
            this.pagination = response.pagination
          } 
        })
      },
      async fetchData () {
        this.process.load = true

        let params = {
          type: 'aboutus'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/site`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false
            
            this.detail = response.data[0]
          } 
        })
      }
    }
  }
</script>