<template>
  <div class="fill-height pt-16">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.success"
          persistent>
          <v-card 
            class="border-radius pa-2">
            <v-card-title class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar size="35">
                <v-img
                  contain
                  width="30"
                  height="30"
                  alt="kominfo"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        color="#ff64ad">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span class="body-1 font-weight-bold mt-1 ml-1">
                Ubah Profil Berhasil
              </span>
            </v-card-title>
            
            <v-divider class="mx-4 mt-3 mb-2"/>

            <v-card-text class="body-2 font-weight-light text-first line-text-second mt-3">
              Terima kasih, Anda telah melengkapi data pribadi di SAKURA.
            </v-card-text>

            <v-divider class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                href="/profile"
                @click="dialog.success = false"
                color="red"
                elevation="0"
                class="text-capitalize white--text"
                style="border-radius: 15px; box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;">
                <v-icon small class="mr-1">mdi-close-thick</v-icon>
                Tutup
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <v-card 
            flat
            min-height="100vh"
            color="#f9f9f9">
            <v-card-text 
              class="pl-12 pb-0">
              <v-row
                align="center">
                <v-col
                  cols="6"
                  class="py-0">
                  <div
                    style="position: relative;">
                    <v-avatar 
                      size="130">
                      <v-img 
                        style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; 
                              border: 5px solid #fff;"
                        alt="kominfo" 
                        :src="form.image.length < 1 ? require('@/assets/image/member-default.jpg') : form.image"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#ff64ad">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                      
                      <!-- <v-avatar
                        @click="sheet.upload = true; type = 'camera'"
                        style="
                            position: absolute; 
                            bottom: 7px; 
                            right: 7px;
                            z-index: 1;"
                        color="#ff64ad"
                        size="40">
                        <v-progress-circular v-if="process.image"
                          indeterminate
                          :size="25"
                          color="white">
                        </v-progress-circular>

                        <v-icon v-else
                          color="white">
                          mdi-camera
                        </v-icon>
                      </v-avatar> -->
                    </v-avatar>

                    <v-avatar
                      @click="sheet.upload = true; type = 'camera'"
                      style="
                        position: absolute; 
                        bottom: 7px; 
                        right: 7px;
                        z-index: 1;"
                      color="#ff64ad"
                      size="40">
                      <v-progress-circular v-if="process.image"
                        indeterminate
                        :size="25"
                        color="white">
                      </v-progress-circular>

                      <v-icon v-else
                        color="white">
                        mdi-camera
                      </v-icon>
                    </v-avatar>
                  </div>
                </v-col>

                <v-col
                  cols="6"
                  class="py-0">
                  <div 
                    class="red--text font-italic caption">
                    *Swafoto wajib menggunakan wajah.
                  </div>
                </v-col>
              </v-row>
            </v-card-text>

            <v-img 
              alt="kominfo" 
              :src="require('@/assets/background/bg_center.png')"
              class="ma-auto"
              style="width: 100%; position: relative; top: -25px;">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular 
                    indeterminate 
                    color="#ff64ad">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>

            <v-card-text
              class="mb-16 pb-16">
              <div class="mb-8">
                <ValidationObserver 
                  ref="form">
                  <v-form>
                    <ValidationProvider 
                      name="fullname" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Nama Lengkap
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. Firmansyah Abdul Rafi"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form.fullname"
                        v-uppercase
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="kk" 
                      rules="required|min:16|max:16" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        KK
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        type="number"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        min="0"
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. 33100062XXXXXXX"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        :counter="16"
                        v-model="form.kk"
                        v-uppercase
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="nik" 
                      rules="required|min:16|max:16" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        NIK
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        disabled
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. 33100062XXXXXXX"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        :counter="16"
                        v-model="form.nik"
                        v-uppercase
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="phone" 
                      rules="required|min:8|max:13"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Telepon
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        type="number"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        min="0"
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. 081332XXXXXX"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form.phone"
                        v-uppercase
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="email" 
                      rules="required|email" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Email
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. XXXXX@gmail.com"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form.email"
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="kecamatan_code" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Kecamatan
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-autocomplete
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. Trucuk"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        :search-input.sync="autocomplete.subdistrict"
                        :loading="process.subdistrict"
                        
                        :items="config.subdistrict"
                        item-value="kecamatan_code"
                        item-text="kecamatan_name"
                        item-color="#ff64ad"

                        v-model="form.kecamatan_code"
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-autocomplete>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="kelurahan_code" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Desa / Kelurahan
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-autocomplete
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. Kradenan"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        :search-input.sync="autocomplete.village"
                        :loading="process.village"
                        
                        :items="config.village"
                        item-value="desa_code"
                        item-text="desa_name"
                        item-color="#ff64ad"

                        v-model="form.kelurahan_code"
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-autocomplete>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="dukuh" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Dukuh / Dusun
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        filled
                        rounded
                        dense
                        color="#ff64ad"
                        placeholder="Contoh. Kemiri"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form.dukuh"
                        v-uppercase
                        :success="valid"
                        :error-messages="errors"
                        required
                        style="border-radius: 10px;">
                      </v-text-field>
                    </ValidationProvider>

                    <v-row>
                      <v-col
                        cols="6"
                        class="py-0">
                        <ValidationProvider 
                          name="rt" 
                          rules="required" 
                          v-slot="{ errors, valid }">
                          <div class="body-2 mb-2">
                            <span class="red--text">*</span>
                            RT
                            <span class="error--text">
                              (wajib diisi)
                            </span>
                          </div>
                          
                          <v-text-field
                            v-on:keyup.enter="save"
                            type="number"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            min="0"
                            filled
                            rounded
                            dense
                            color="#ff64ad"
                            placeholder="Contoh. 22"
                            :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                            v-model="form.rt"
                            v-uppercase
                            :success="valid"
                            :error-messages="errors"
                            required
                            style="border-radius: 10px;">
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>

                      <v-col
                        cols="6"
                        class="py-0">
                        <ValidationProvider 
                          name="rw" 
                          rules="required" 
                          v-slot="{ errors, valid }">
                          <div class="body-2 mb-2">
                            <span class="red--text">*</span>
                            RW
                            <span class="error--text">
                              (wajib diisi)
                            </span>
                          </div>
                          
                          <v-text-field
                            v-on:keyup.enter="save"
                            type="number"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            min="0"
                            filled
                            rounded
                            dense
                            color="#ff64ad"
                            placeholder="Contoh. 10"
                            :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                            v-model="form.rw"
                            v-uppercase
                            :success="valid"
                            :error-messages="errors"
                            required
                            style="border-radius: 10px;">
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>

                    <div>
                      <div class="body-2 mb-2">
                        Catatan
                        <span>
                          (bisa dikosongi)
                        </span>
                      </div>

                      <v-textarea
                        v-on:keyup.enter="save"
                        filled
                        rounded
                        dense
                        rows="3"
                        color="#ff64ad"
                        placeholder="Contoh.Perubahan nama dan foto"
                        :append-icon="form.extra.request_update_note.length < 1 ? '' : 'mdi-check-circle-outline'"

                        v-model="form.extra.request_update_note"
                        v-uppercase
                        :success="form.extra.request_update_note.length > 0"
                        style="border-radius: 10px;">
                      </v-textarea>
                    </div>
                  </v-form>
                </ValidationObserver>

                <div v-if="message.error.length > 0"
                  class="text-center mt-3 mb-16">
                  <v-alert
                    type="error" 
                    text 
                    dense 
                    prominent 
                    v-show="message.error.length > 0">
                    {{ message.error }}
                  </v-alert>
                </div>
              </div>
            </v-card-text>

            <v-card
              flat
              color="#fff"
              min-height="150"
              style="border-radius: 15px 15px 0px 0px;
                    position: fixed;
                    bottom: 0;
                    width: 460px;
                    z-index: 1;">
              <v-card-text
                class="pa-4">
                <v-row 
                  align="center">
                  <v-col 
                    cols="3" 
                    class="py-0">
                    <v-img 
                      contain
                      alt="kominfo" 
                      :src="require('@/assets/image/footer/akun.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="#ff64ad">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>

                  <v-col 
                    cols="9" 
                    class="black--text py-0">
                    <div>
                      <div 
                        class="body-2 line-text-second black--text" 
                        style="opacity: 60%;">
                        Ubah data diri Anda untuk mempermudah pengajuan dengan layanan kami, kemudian klik <b class="font-italic">Simpan</b>.
                      </div>
                    </div>
                  </v-col>
                </v-row>
                
                <v-row 
                  class="mt-4">
                  <v-col 
                    cols="6" 
                    class="py-0">
                    <v-btn
                      :disabled="process.form"
                      :loading="process.form"
                      v-on:keyup.enter="save"
                      @click="save"
                      block
                      color="green"
                      class="text-capitalize white--text pl-1 pr-2"
                      style="border-radius: 10px; 
                            box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon small>mdi-check-bold</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Simpan
                      </span>
                    </v-btn>
                  </v-col>

                  <v-col 
                    cols="6" 
                    class="py-0">
                    <v-btn 
                      @click="toClose()"
                      :disabled="process.form"
                      block
                      color="red"
                      class="text-capitalize white--text pl-1 pr-2"
                      style="border-radius: 10px; 
                            box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon small>mdi-close-thick</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Batal
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>

          <v-bottom-sheet
            v-model="sheet.upload"
            hide-overlay
            persistent>
            <camera v-if="type === 'camera'"
              :sheet="sheet.upload"
              @frame="sheet.upload = $event"
              @input="addCamera($event)"
              @close="sheet.upload = $event; type = '';" />
          </v-bottom-sheet>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        dialog: {
          success: false
        },
        type: '',
        sheet: {
          upload: false
        },
        autocomplete: {
          subdistrict: '',
          village: ''
        },
        config: {
          subdistrict: [],
          village: []
        },
        form: {
          image: '',
          fullname: '',
          kk: '',
          nik: '',
          email: '',
          phone: '',
          password: '',
          kecamatan_code: '',
          kelurahan_code: '',
          dukuh: '',
          rt: '',
          rw: '',
          extra: {
            request_update_note: ''
          },
          note: ''
        },
        process: {
          form: false,
          image: false,
          subdistrict: false,
          village: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      Camera: () => import(/* webpackPrefetch: true */ '@/components/upload/camera.vue')
    },
    watch: {
      'form.kecamatan_code': function() {
        let t = this
        let kec = this.config.subdistrict.filter(function(v) {
          return t.form.kecamatan_code == v.kecamatan_code
        })

        if (kec.length > 0) {
          this.form.kecamatan_name = kec[0].kecamatan_name

          this.form.kelurahan_code = ''
          this.form.kelurahan_name = ''
        }

        this.static().village()
      },
      'form.kelurahan_code': function() {
        let t = this
        let kel = this.config.village.filter(function(v) {
          return t.form.kelurahan_code == v.desa_code
        })

        if (kel.length > 0) {
          this.form.kelurahan_name = kel[0].desa_name
        }
      },
      'autocomplete.subdistrict': function() {
        this.static().subdistrict()
      },
      'autocomplete.village': function() {
        this.static().village()
      },
      'form.extra.request_update_note': function(val) {
        this.form.note = val
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      fetch () {
        this.reset()

        this.static().subdistrict()
      },
      static () {
        let t = this
        return {
          async subdistrict () {
            t.process.subdistrict = true

            let params = {
              search: t.autocomplete.subdistrict,
              sort: 'DESC'
            }

            await t.$axios.$get(`${process.env.PREFIX_MASTER}/location/kecamatan`, { params })
            .then(response => {
              if (response.code == 200) {
                t.process.subdistrict = false

                t.config.subdistrict = response.data
              }
            })
          },
          async village () {
            t.process.village = true

            let params = {
              desa_kecamatan_code: t.form.kecamatan_code,
              search: t.autocomplete.village,
              sort: 'DESC'
            }

            await t.$axios.$get(`${process.env.PREFIX_MASTER}/location/desa`, { params })
            .then(response => {
              if (response.code == 200) {
                t.process.village = false

                t.config.village = response.data
              }
            })
          }
        }
      },
      addCamera (e) {
        this.process.image = e.process

        if (!e.process) {
          this.form.image = e.data.full.file_url
        } 
      },
      reset () {
        if (this.$refs.form) this.$refs.form.reset()

        this.form = this.detail

        this.form.note = this.detail.extra.request_update_note
        
        this.process.form = false

        this.message.error = ''
      },
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.PREFIX_PROXY}/profile/request_update`, this.form)
          .then((response) => {

            if (response.code === 200) {
              this.process.form = false
              
              // this.$emit('close', false)

              // Cookie.set('user', JSON.stringify(response.data))

              if (this.$route.query.id === undefined) {
                this.dialog.success = true
              } else {
                window.location = `/submission?id=${this.$route.query.id}`
              }
              
            } else {
              this.process.form = false
              this.message.error = response.message
            }

          })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },
      toClose () {
        if (this.$route.query.setting === undefined) {
          this.$emit('close', false)
        } else {
          this.$router.push({ path: `/submission?id=${this.$route.query.id}` })
        }
      }
    }
  }
</script>