<template>
  <div class="fill-height pt-15">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card v-if="detail.role_id < 1"
            flat
            color="#f9f9f9">
            <v-card-title
              class="body-1 font-weight-bold black--text mb-2">
              Anda belum terdaftar sebagai petugas registrasi
            </v-card-title>

            <v-card-subtitle
              class="body-2 font-weight-light line-text-first mb-6">
              Silahkan hubungi admin kami untuk mendaftarkan akun Anda sebagai petugas registrasi di SAKURA.
            </v-card-subtitle>

            <v-card-text
              class="my-4">
              <v-img 
                width="75"
                height="75"
                alt="kominfo" 
                :src="require('@/assets/image/profile/call_center.png')"
                class="ma-auto">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      indeterminate 
                      color="#ff64ad">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-1 font-weight-bold black--text text-center mt-6 mb-2">
                Hubungi Admin Kami
              </div>

              <div
                class="body-2 font-weight-light text-center line-text-first">
                Anda dapat menghubungi admin kami untuk menanyakan sepurat petugas registrasi
                <a
                  href="https://api.whatsapp.com/send/?phone=62816713631&text&type=phone_number&app_absent=0"
                  target="_blank">
                  disini
                </a>.
              </div>
            </v-card-text>

            <v-card-text
              class="my-4">
              <v-img 
                width="75"
                height="75"
                alt="kominfo" 
                :src="require('@/assets/image/profile/search_user.png')"
                class="ma-auto">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      indeterminate 
                      color="#ff64ad">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-1 font-weight-bold black--text text-center mt-6 mb-2">
                Akun Diperiksa
              </div>

              <div
                class="body-2 font-weight-light text-center line-text-first">
                Admin akan mencari data akun Anda,
              </div>

              <div
                class="body-2 font-weight-light text-center line-text-first">
                apakah Anda sudah terdaftar sebagai pengguna aplikasi di SAKURA atau belum.
              </div>
            </v-card-text>

            <v-card-text
              class="my-4">
              <v-img 
                width="75"
                height="75"
                alt="kominfo" 
                :src="require('@/assets/image/profile/check_document.png')"
                class="ma-auto">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      indeterminate 
                      color="#ff64ad">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-1 font-weight-bold black--text text-center mt-6 mb-2">
                Memberikan Hak Akses
              </div>

              <div
                class="body-2 font-weight-light text-center line-text-first">
                Admin akan mendaftarkan Anda sebagai petugas registrasi dan memberikan Hak Akses untuk akun Anda.
              </div>
            </v-card-text>

            <v-card-text
              class="my-4">
              <v-img 
                width="75"
                height="75"
                alt="kominfo" 
                :src="require('@/assets/image/profile/approved.png')"
                class="ma-auto">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      indeterminate 
                      color="#ff64ad">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-1 font-weight-bold black--text text-center mt-6 mb-2">
                Terdaftar Sebagai Petugas Registrasi
              </div>

              <div
                class="body-2 font-weight-light text-center line-text-first">
                Jika akun Anda sudah terdaftar, di menu profil akan diberitahukan bahwa akun Anda telah terdaftar sebagai petugas registrasi.
              </div>
            </v-card-text>
          </v-card>

          <v-card v-if="detail.role_id > 0"
            flat
            color="#f9f9f9">
            <v-card-title
              class="body-1 font-weight-bold black--text mb-2">
              Petugas Registrasi {{ detail.role_level }}
            </v-card-title>

            <v-card-subtitle
              class="body-2 font-weight-light line-text-first">
              <span v-if="detail.role_level === 'Kabupaten / Kota'">
                Anda telah terdaftar sebagai petugas registrasi {{ detail.role_name }} di wilayah {{ detail.role_level }} {{ detail.role_kabupaten_name }}. Anda mendapatkan hak akses sebagai berikut:
              </span>

              <span v-if="detail.role_level === 'Kecamatan'">
                Anda telah terdaftar sebagai petugas registrasi {{ detail.role_name }} di wilayah {{ detail.role_level }} {{ detail.role_kecamatan_name }}. Anda mendapatkan hak akses sebagai berikut:
              </span>

              <span v-if="detail.role_level === 'Desa / Kelurahan'">
                Anda telah terdaftar sebagai petugas registrasi {{ detail.role_name }} di wilayah {{ detail.role_level }} {{ detail.role_kelurahan_name }}, Kecamatan {{ detail.role_kecamatan_name }}. Anda mendapatkan hak akses sebagai berikut:
              </span>
            </v-card-subtitle>

            <v-card-text>
              <v-row>
                <v-col 
                  class="py-2">
                  <no-ssr>
                    <swiper 
                      v-model="selected.service"
                      ref="swiper"
                      class="swiper"
                      :options="swiperOption">
                      <swiper-slide 
                        v-for="(item, index) in process.service ? 10 : list.service" 
                        :key="index" 
                        :class="index < 1 ? 'pr-4' : index < list.service.length ? 'pr-4' : 'px-4'"
                        style="width: auto;">
                        <v-skeleton-loader
                          class="ma-auto"
                          :loading="process.service"
                          type="avatar"
                          tile>
                          <div>
                            <v-card v-if="!process.service && list.service.length > 0"
                              @click="selected.service = index"
                              flat
                              width="45"
                              height="45"
                              :color="selected.service == index ? '#ff64ad1a' : 'white'"
                              class="border-radius d-flex align-center justify-center pa-3"
                              style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px !important;">
                              <div 
                                class="text-center">
                                <v-avatar 
                                  size="30" 
                                  tile>
                                  <v-img 
                                    alt="kominfo" 
                                    :src="item.layanan_image"
                                    class="ma-auto"
                                    style="border-radius: 10px;">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular 
                                          indeterminate 
                                          color="white">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-avatar>

                                <div 
                                  class="text-center font-weight-bold mt-1"
                                  style="font-size: 10px; opacity: 60%;">
                                  {{ item.layanan_kode }}
                                </div>
                              </div>
                            </v-card>
                          </div>
                        </v-skeleton-loader>
                      </swiper-slide>
                    </swiper>
                  </no-ssr>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text v-if="!process.service && list.service.length > 0"
              class="pt-0">
              <!-- <Kk v-if="selected.service === 0"
                :detail="list.service[selected.service]" />

              <Ktp v-if="selected.service === 1"
                :detail="list.service[selected.service]" />

              <Kia v-if="selected.service === 2"
                :detail="list.service[selected.service]" />

              <Akl v-if="selected.service === 3"
                :detail="list.service[selected.service]" />
                
              <Akm v-if="selected.service === 4"
                :detail="list.service[selected.service]" />

              <Skp v-if="selected.service === 5"
                :detail="list.service[selected.service]" />

              <Skd v-if="selected.service === 6"
                :detail="list.service[selected.service]" />

              <Upd v-if="selected.service === 7"
                :detail="list.service[selected.service]" />

              <Rkm v-if="selected.service === 8"
                :detail="list.service[selected.service]" /> -->
              
              <Kk v-if="list.service[selected.service].layanan_kode === 'KK'"
                :detail="list.service[selected.service]" />

              <Ktp v-if="list.service[selected.service].layanan_kode === 'KTP'"
                :detail="list.service[selected.service]" />

              <Kia v-if="list.service[selected.service].layanan_kode === 'KIA'"
                :detail="list.service[selected.service]" />

              <Akl v-if="list.service[selected.service].layanan_kode === 'AKL'"
                :detail="list.service[selected.service]" />
                
              <Akm v-if="list.service[selected.service].layanan_kode === 'AKM'"
                :detail="list.service[selected.service]" />

              <Skp v-if="list.service[selected.service].layanan_kode === 'SKP'"
                :detail="list.service[selected.service]" />

              <Skd v-if="list.service[selected.service].layanan_kode === 'SKD'"
                :detail="list.service[selected.service]" />

              <Upd v-if="list.service[selected.service].layanan_kode === 'UPD'"
                :detail="list.service[selected.service]" />

              <Rkm v-if="list.service[selected.service].layanan_kode === 'RKM'"
                :detail="list.service[selected.service]" />

              <!-- <Akw v-if="list.service[selected.service].layanan_kode === 'AKW'"
                :detail="list.service[selected.service]" />

              <Acr v-if="list.service[selected.service].layanan_kode === 'ACR'"
                :detail="list.service[selected.service]" />

              <Kut v-if="list.service[selected.service].layanan_kode === 'KUT'"
                :detail="list.service[selected.service]" /> -->
            </v-card-text>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        swiperOption: {
          slidesPerView: 'auto'
        },
        selected: {
          service: 0
        },
        list: {
          service: [],
          data: []
        },
        pagination: {},
        process: {
          service: false,
          load: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      Kk: () => import(/* webpackPrefetch: true */ '@/components/submission/kk.vue'),
      Ktp: () => import(/* webpackPrefetch: true */ '@/components/submission/ktp.vue'),
      Kia: () => import(/* webpackPrefetch: true */ '@/components/submission/kia.vue'),
      Akl: () => import(/* webpackPrefetch: true */ '@/components/submission/akl.vue'),
      Akm: () => import(/* webpackPrefetch: true */ '@/components/submission/akm.vue'),
      Akw: () => import(/* webpackPrefetch: true */ '@/components/submission/akw.vue'),
      Acr: () => import(/* webpackPrefetch: true */ '@/components/submission/acr.vue'),
      Skp: () => import(/* webpackPrefetch: true */ '@/components/submission/skp.vue'),
      Skd: () => import(/* webpackPrefetch: true */ '@/components/submission/skd.vue'),
      Upd: () => import(/* webpackPrefetch: true */ '@/components/submission/upd.vue'),
      Kut: () => import(/* webpackPrefetch: true */ '@/components/submission/kut.vue'),
      Rkm: () => import(/* webpackPrefetch: true */ '@/components/submission/rkm.vue')
    },
    watch: {
      
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      this.fetchService()
    },
    methods: {
      async fetchService () {
        this.process.service = true

        let params = {
          order_by: 'ASC',
          auth_role: 1
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/layanan`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.service = false
            
            this.list.service = response.data

            this.selected.service = 0

            this.list.service.map((obj, index) => {
              if (this.$route.query.code === obj.layanan_kode) {
                this.selected.service = index
              }
            })
          } 
        })
      }
    }
  }
</script>