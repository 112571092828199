<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex 
        style="height: 100vh;">
        <section>
          <v-img 
            alt="kominfo" 
            :src="require('@/assets/background/bg_top_bottom.png')"
            class="ma-auto"
            style="width: 100%;
                position: absolute;
                top: 0;
                transform: scale(-1, -1);">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  indeterminate 
                  color="#ff64ad">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </section>

        <section>
          <v-row
            align="center"
            style="height: 100vh;">
            <v-col>
              <v-card  
                style="z-index: 1;"
                flat 
                color="transparent">
                <v-card-text>
                  <v-img 
                    width="100"
                    alt="kominfo" 
                    :src="require('@/assets/logo/favicon.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          color="white">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card-text>

                <v-card-text 
                  class="text-h5 font-weight-bold d-flex align-center justify-center black--text mb-4"
                  style="opacity: 60%;">
                  <v-icon color="#ff64ad">mdi-ray-end</v-icon>
                  
                  <span class="mx-2">
                    SAKURA
                  </span>

                  <v-icon color="#ff64ad">mdi-ray-start</v-icon>
                </v-card-text>

                <v-card-text
                  class="d-flex align-center pa-0">
                  <v-card
                    flat
                    class="py-6 pl-3 pr-16"
                    style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px !important;
                          border-radius: 0px 100px 100px 0px;
                          width: 90%;
                          position: relative;">
                    <ValidationObserver 
                      ref="form">
                      <v-form>
                        <ValidationProvider 
                          name="nik" 
                          rules="required|min:16|max:16" 
                          v-slot="{ errors }">
                          <v-text-field
                            v-on:keyup.enter="save"
                            dense
                            type="number"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            min="0"
                            color="#ff64ad"
                            placeholder="NIK"
                            :counter="16"
                            v-model="form.nik"
                            v-uppercase
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>

                        <ValidationProvider 
                          name="password" 
                          :rules="{ 
                            required: true, 
                            min:5, 
                            max:20
                          }"
                          v-slot="{ errors }">
                          <v-text-field
                            v-on:keyup.enter="save"
                            dense 
                            color="#ff64ad"
                            placeholder="Password"
                            :type="show ? 'text' : 'password'"
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="show = !show"
                            :counter="20"
                            
                            v-model="form.password"
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>
                      </v-form>
                    </ValidationObserver>

                    <div v-if="message.error.length > 0"
                      class="text-center my-3">
                      <v-alert
                        type="error" 
                        text 
                        dense 
                        prominent 
                        v-show="message.error.length > 0">
                        {{ message.error }}
                      </v-alert>
                    </div>
                  </v-card>

                  <v-btn
                    fab
                    width="50"
                    height="50"
                    :disabled="process.form"
                    :loading="process.form"
                    v-on:keyup.enter="save"
                    @click="save"
                    elevation="5"
                    class="body-1 font-weight-bold white--text text-capitalize"
                    style="position: absolute; right: 15px; background: linear-gradient(to right, rgb(255, 100, 173), #ff64ac82);">
                    <v-icon>mdi-arrow-right-thick</v-icon>
                  </v-btn>
                </v-card-text>

                <nuxt-link
                  to="/auth/forgot">
                  <v-card-text 
                    class="body-2 font-weight-bold black--text text-right py-0 pr-4 my-9"
                    style="opacity: 60%;">
                      Lupa password ?
                  </v-card-text>
                </nuxt-link>

                <div
                  class="mb-9">
                  <recaptcha
                    @error="onError"
                    @success="onSuccess"
                    @expired="onExpired" />
                </div>

                <v-card
                  flat
                  style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px !important;
                        border-radius: 0px 100px 100px 0px;
                        width: 70%;
                        position: relative;">
                  <v-card-text
                    class="d-flex align-center pa-3">
                    <span
                      class="body-1 mr-1">
                      Belum punya akun? klik
                    </span>
                    
                    <v-btn
                      to="/auth/register"
                      text
                      small
                      class="body-1 text-capitalize color-default font-weight-bold px-0">
                      Register
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-card>

              <div
                style="position: absolute;
                      bottom: 20px;
                      right: 0;
                      z-index: 2;">
                <v-btn
                  to="/"
                  color="#FFFFFF60"
                  elevation="0"
                  class="body-1 text-capitalize white--text font-weight-bold"
                  style="border-radius: 50px 0px 0px 50px;">
                  <v-icon>mdi-arrow-left-thick</v-icon>

                  <span class="ml-1">
                    Beranda
                  </span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </section>

        <section>
          <v-img 
            alt="kominfo" 
            :src="require('@/assets/background/bg_top_bottom.png')"
            class="ma-auto"
            style="width: 100%;
                position: absolute;
                bottom: 0;">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  indeterminate 
                  color="#ff64ad">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'notAuthenticated',
    data () {
      return {
        show: false,
        form: {
          nik: '',
          password: '',
          fcm: ''
        },
        process: {
          form: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/auth/login',
          title: 'Masuk',
          description: 'Masuk Sakura Dukcapil Klaten (Sistem Layanan Administrasi Kependudukan Dalam Jaringan)',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      }
    },
    beforeDestroy() {
      
    },
    mounted () {
      
    },
    methods: {
      onError (error) {
        console.log('Error happened:', error)
      },
      onSuccess (token) {
        console.log('Succeeded:', token)

        this.message.error = ''
      },
      onExpired () {
        console.log('Expired')
      },
      
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          try {
            const token_recaptcha = await this.$recaptcha.getResponse()

            // SAVE TO REST API
            await this.$axios.$post(`${process.env.PREFIX_PROXY}/auth/login`, this.form)
            .then((response) => {

              if (response.code === 200) {

                Cookie.set('token', response.data.token)
                Cookie.set('user', JSON.stringify(response.data))

                window.location = '/home'

                this.$recaptcha.reset()
                
              } else {
                this.process.form = false
                this.message.error = response.message
              }

            })

            // await this.$recaptcha.reset()
          } catch (error) {
            console.log('Login error:', error)

            this.process.form = false
            this.message.error = 'Masukan captcha google'
          }

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>