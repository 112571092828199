<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex 
        style="height: 100vh;">
        <section>
          <v-img 
            alt="kominfo" 
            :src="require('@/assets/background/bg_top_bottom.png')"
            class="ma-auto"
            style="width: 100%;
                position: absolute;
                top: 0;
                transform: scale(-1, -1);">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  indeterminate 
                  color="#ff64ad">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </section>

        <section>
          <v-row
            align="center"
            style="height: 100vh;">
            <v-col>
              <v-card  
                style="z-index: 1;"
                flat 
                color="transparent">
                <v-card-text>
                  <v-img 
                    width="100"
                    alt="kominfo" 
                    :src="require('@/assets/logo/favicon.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          color="white">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card-text>

                <v-card-text 
                  class="text-h5 font-weight-bold d-flex align-center justify-center black--text mb-4"
                  style="opacity: 60%;">
                  <v-icon color="#ff64ad">mdi-ray-end</v-icon>
                  
                  <span class="mx-2">
                    SAKURA
                  </span>

                  <v-icon color="#ff64ad">mdi-ray-start</v-icon>
                </v-card-text>

                <v-card-text
                  class="d-flex align-center pa-0">
                  <v-card
                    flat
                    class="py-6 pl-3 pr-16"
                    style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px !important;
                          border-radius: 0px 100px 100px 0px;
                          width: 90%;
                          position: relative;">
                    <ValidationObserver 
                      ref="form">
                      <v-form>
                        <ValidationProvider 
                          name="username" 
                          rules="required" 
                          v-slot="{ errors }">
                          <v-text-field
                            v-on:keyup.enter="save"
                            dense
                            color="#ff64ad"
                            placeholder="NIK / Email"
                            v-model="form.username"
                            v-uppercase
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>
                      </v-form>
                    </ValidationObserver>

                    <div v-if="message.error.length > 0"
                      class="text-center my-3">
                      <v-alert
                        type="error" 
                        text 
                        dense 
                        prominent 
                        v-show="message.error.length > 0">
                        {{ message.error }}
                      </v-alert>
                    </div>
                  </v-card>

                  <v-btn
                    fab
                    width="50"
                    height="50"
                    :disabled="process.form"
                    :loading="process.form"
                    v-on:keyup.enter="save"
                    @click="save"
                    elevation="5"
                    class="body-1 font-weight-bold white--text text-capitalize"
                    style="position: absolute; right: 15px; background: linear-gradient(to right, rgb(255, 100, 173), #ff64ac82);">
                    <v-icon>mdi-key</v-icon>
                  </v-btn>
                </v-card-text>

                <v-card
                  to="/auth/login"
                  flat
                  class="cursor-pointer mt-16"
                  style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px !important;
                        border-radius: 0px 100px 100px 0px;
                        width: 26%;
                        position: relative;">
                  <v-card-text
                    class="body-1 font-weight-bold color-default pa-3">
                    Login
                  </v-card-text>
                </v-card>
              </v-card>

              <div
                style="position: absolute;
                      bottom: 20px;
                      right: 0;
                      z-index: 2;">
                <v-btn
                  to="/"
                  color="#FFFFFF60"
                  elevation="0"
                  class="body-1 text-capitalize white--text font-weight-bold"
                  style="border-radius: 50px 0px 0px 50px;">
                  <v-icon>mdi-arrow-left-thick</v-icon>

                  <span class="ml-1">
                    Beranda
                  </span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </section>

        <section>
          <v-img 
            alt="kominfo" 
            :src="require('@/assets/background/bg_top_bottom.png')"
            class="ma-auto"
            style="width: 100%;
                position: absolute;
                bottom: 0;">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  indeterminate 
                  color="#ff64ad">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'notAuthenticated',
    data () {
      return {
        show: false,
        form: {
          username: ''
        },
        process: {
          form: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/auth/forgot',
          title: 'Lupa Password',
          description: 'Lupa Password Sakura Dukcapil Klaten (Sistem Layanan Administrasi Kependudukan Dalam Jaringan)',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      }
    },
    mounted () {
      
    },
    methods: {
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.PREFIX_PROXY}/auth/forgot-password`, this.form)
          .then((response) => {

            if (response.code === 200) {
              this.$router.push({ path: '/auth/checkforgot' })
            } else {
              this.process.form = false
              this.message.error = response.message
            }

          })
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>