<template>
  <div class="fill-height py-16">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card 
            flat 
            color="transparent">
            <v-card-text class="pb-0">
              <v-text-field
                v-model="search"
                v-uppercase
                @click:clear="search = ''; fetchNews();"
                v-on:keyup.enter="fetchNews()"
                filled
                rounded
                dense 
                single-line
                hide-details
                color="#ff64ad"
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari . . ."
                clearable
                style="border-radius: 10px;">
              </v-text-field>
            </v-card-text>

            <div class="my-3">
              <no-ssr>
                <swiper 
                  class="swiper"
                  ref="swiper"
                  :options="swiperOption">
                  <swiper-slide 
                    v-for="(item, index) in process.category ? 5 : list.category" :key="index" 
                    class="py-3" 
                    :class="index === 0 ? 'pl-4 pr-3' : index < list.category.length - 1 ? 'pr-3' : 'pr-4'"
                    style="width: auto;">
                    <v-skeleton-loader
                      class="ma-auto"
                      width="150"
                      :loading="process.category"
                      type="text">
                      <div>
                        <v-card v-if="!process.category && list.category.length > 0"
                          @click="selected.category = item.cat_id; fetchNews();"
                          flat
                          :color="selected.category === item.cat_id ? '#ff64ad' : '#f0f0f0'"
                          class="d-flex align-center pa-1"
                          style="border-radius: 50px;">
                          <v-avatar size="20">
                            <v-img 
                              alt="kominfo" 
                              :src="item.cat_image"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#ff64ad">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-avatar>

                          <div 
                            class="caption text-capitalize ml-2 mr-1" 
                            :class="selected.category === item.cat_id ? 'white--text' : ''"
                            :style="selected.category === item.cat_id ? '' : 'opacity: 60%;'">
                            {{ item.cat_title }}
                          </div>
                        </v-card>
                      </div>
                    </v-skeleton-loader>
                  </swiper-slide>
                </swiper>
              </no-ssr>
            </div>

            <div
              v-for="(item, index) in process.news ? 5 : list.news"
              :key="index">
              <v-skeleton-loader
                class="ma-auto"
                :loading="process.news"
                type="card, article">
                <div> 
                  <v-card v-if="!process.news && list.news.length > 0"
                    :to="`/news/${item.id}`"
                    flat
                    style="border-radius: 0px; box-shadow: rgba(0, 0, 0, 0.01) 0px 3px 5px;">
                    <v-card-text class="d-flex align-center pa-4">
                      <div class="d-flex align-center">
                        <v-avatar 
                          size="40"
                          style="border: 2px solid #ff64ad;">
                          <v-img 
                            alt="kominfo" 
                            :src="item.post_extra.createdby.image"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#ff64ad">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>

                        <div class="body-2 text-capitalize font-weight-bold black--text ml-2">
                          {{ item.post_extra.createdby.name }}
                        </div>
                      </div>

                      <v-spacer />

                      <div>
                        <v-icon>mdi-dots-horizontal</v-icon>
                      </div>
                    </v-card-text>

                    <v-img
                      contain
                      min-height="200"
                      alt="kominfo" 
                      :src="item.post_image[0].url"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="#ff64ad">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                    <v-card-text class="pb-0">
                      <div class="d-flex align-center mb-3">
                        <div class="d-flex align-center">
                          <v-icon small>mdi-tag-multiple</v-icon>
                      
                          <span class="caption ml-1">
                            {{ item.post_cat_title }}
                          </span>
                        </div>

                        <v-spacer />

                        <div>
                          <v-icon size="20">mdi-share-variant-outline</v-icon>
                        </div>
                      </div>

                      <div class="body-1 font-weight-bold black--text mb-1">
                        {{ item.post_title }}
                      </div>

                      <div class="body-2 font-weight-light three-line line-text-first">
                        <span v-html="item.post_content.replace(/(<([^>]+)>)/ig, '')"></span>
                      </div>
                    </v-card-text>

                    <v-card-text class="d-flex align-center pt-3">
                      <div class="caption font-weight-light" style="opacity: 75%;">
                        {{ item.post_create_datetime | ago }}
                      </div>

                      <v-spacer />

                      <div class="caption" style="opacity: 75%;">
                        0 Komentar
                      </div>
                    </v-card-text>
                  </v-card>

                  <v-divider style="border: 4px solid #f9f9f9;" v-if="index < list.news.length - 1" />
                </div>
              </v-skeleton-loader>
            </div>

            <v-card-text v-if="pagination.news.total_data > 10 && limit < pagination.news.total_data"
              class="text-center">
              <v-btn
                small
                rounded
                outlined
                elevation="0"
                color="#ff64ad"
                class="color-default text-capitalize"
                @click="limit += 10">
                Lihat Lebih Banyak
              </v-btn>
            </v-card-text>

            <Empty v-if="!process.news && pagination.news.total_page < 1"
                margin="my-16"
              size="125"
              message="Berita" />
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        swiperOption: {
          slidesPerView: 'auto'
        },
        search: '',
        selected: {
          data: 0,
          category: ''
        },
        list: {
          category: [],
          news: []
        },
        limit: 10,
        pagination: {
          news: {}
        },
        process: {
          category: false,
          news: false
        },
        // SEO
        content: {
          url: '/news',
          title: 'Berita',
          description: 'Berita Sakura Dukcapil Klaten (Sistem Layanan Administrasi Kependudukan Dalam Jaringan)',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'limit': function() {
        this.fetchNews()
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      fetch () {
        this.fetchCategory()
        this.fetchNews()
      },
      async fetchCategory () {
        this.process.category = true

        let params = {
          sort: 'cat_pos',
          order_by: 'ASC',
          cat_type: 'news'
        }

        this.list.category = [{
          cat_id: '',
          cat_title: 'Semua Kategori',
          cat_image: require('@/assets/image/all.png')
        }]

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/category`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.category = false

            response.data.map(obj => {
              this.list.category.push({
                cat_id: obj.cat_id,
                cat_title: obj.cat_title,
                cat_image: obj.cat_image
              })
            })
          } 
        })
      },
      async fetchNews () {
        this.process.news = true

        let params = {
          limit: this.limit,
          post_type: 'news',
          post_status: 'publish',
          search: this.search,
          post_cat_id: this.selected.category
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/post`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.news = false
            
            this.list.news = response.data
            this.pagination.news = response.pagination
          } 
        })
      },
      onSlideChange () {
        this.selected.data = this.$refs.swiper.$swiper.activeIndex
      },
      onSlideChangeIndex (index) {
        this.$refs.swiper.$swiper.slideTo(index, 1000, false)
        this.selected.data = index
      }
    }
  }
</script>