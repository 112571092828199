<template>
  <v-bottom-navigation
    v-model="selected"
    background-color="#fff"
    grow
    dark
    :shift="false"
    fixed>
    <div
      v-for="(item, index) in menu"
      :key="index"
      class="mx-1"
      style="height: 56px; position: relative;">
      <v-divider 
        v-if="$route.path === item.href"
        style="border: 3px solid #ff64ad;
              border-radius: 0px 0px 50px 50px;
              position: absolute;
              width: 100%;"/>
      <v-btn
        :to="item.href">
        <v-img 
          :style="$route.path === item.href ? '' : 'opacity: 50%;'"
          contain
          width="25"
          height="25"
          alt="kominfo" 
          :src="item.icon"
          class="ma-auto">
          <template v-slot:placeholder>
            <v-row
              class="fill-height"
              align="center"
              justify="center">
              <v-progress-circular 
                size="25"
                indeterminate 
                color="#ff64ad">
              </v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-btn>
    </div>
  </v-bottom-navigation>
</template>

<script>
  export default {
    data () {
      return {
        selected: 0,
        menu: [
          {
            text: "Beranda",
            icon: require('@/assets/image/footer/beranda.png'),
            href: "/home"
          },
          {
            text: "Layanan",
            icon: require('@/assets/image/footer/layanan.png'),
            href: "/service"
          },
          {
            text: "Pengajuan",
            icon: require('@/assets/image/footer/ajuan.png'),
            href: "/submission"
          },
          {
            text: "Pengumuman",
            icon: require('@/assets/image/footer/pengumuman.png'),
            href: "/announcement"
          },
          {
            text: "Profil",
            icon: require('@/assets/image/footer/akun.png'),
            href: "/profile"
          }
        ]
      }
    },
    components: {
      
    },
    computed: {
      user() {
        return this.$store.state.member.auth.user
      },
      rps() {
        return this.$vuetify.breakpoint.name
      }
    },
    created () {
      
    }
  }
</script>