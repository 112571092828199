<template>
  <v-layout column>
    <div class="text-center">
      <section>
        <v-container
          class="d-flex align-center"
          style="min-height: 100vh;">
          <v-row 
            justify="center" 
            align="center">
            <v-col>
              <v-card
                flat
                color="transparent"
                class="pa-12">
                <v-row 
                  justify="center" 
                  align="center">
                  <v-col>
                    <div>
                      <v-img 
                        contain
                        width="200"
                        height="200"
                        alt="kominfo" 
                        :src="require('@/assets/image/500.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#ff64ad">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </div>

                    <div>
                      <div class="my-12">
                        <div class="title font-weight-bold mb-3" style="opacity: 70%;">
                          Internal server error
                        </div>

                        <div class="body-1" style="opacity: 60%;">
                          Tautan yang Anda kunjungi mungkin rusak atau tidak ada lagi.
                        </div>
                      </div>

                      <v-btn
                        large
                        block
                        rounded
                        color="#ff64ad"
                        class="text-capitalize white--text pl-1 pr-2"
                        style="box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;"
                        to="/home">
                        <v-avatar 
                          color="#ff64ad" 
                          size="25"
                          style="position: absolute; left: 2px;">
                          <v-icon small>mdi-arrow-left-thick</v-icon>
                        </v-avatar>

                        <span class="ml-6">
                          kembali ke beranda
                        </span>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </div>
  </v-layout>
</template>

<script> 
  import { createSEOMeta } from '@/utils/seo'
  export default {
    name: 'error-500',
    data () {
      return {
        // SEO
        content: {
          url: '',
          title: '500',
          description: 'Internal server error',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    props: {
      error: {
        type: Object,
        default: () => {},
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    computed: {
      token() {
        return this.$store.state.member.auth.token
      }
    }
  }
</script>